import { Button } from 'primereact/button';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { confirmDialog } from 'primereact/confirmdialog';
import useCancelOffer from '../../hooks/useCancelOffer';
import { Offer } from '../../api/_generated_';

type Props = {
  offer: Offer;
};

export default function CancelOfferButton({
  offer,
}: Props) {
  const { t } = useTranslation();

  const { isLoading, mutate: cancelOffer } = useCancelOffer();

  const onConfirm = () => {
    confirmDialog({
      header: t('offers.label-cancel-offer-confirmation'),
      message: t('offers.label-cancel-offer-confirmation-desc'),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      acceptClassName: 'p-button-danger p-button-text',
      rejectLabel: t('common.label-no'),
      accept: () => cancelOffer(offer.uuid),
    });
  };

  return (
    <Button
      data-testid="cancelOfferButton"
      className="p-overlay-badge"
      severity="secondary"
      size="small"
      tooltip={t('offers.cmd-cancel-offer')}
      tooltipOptions={{ position: 'bottom' }}
      onClick={onConfirm}
      disabled={isLoading}
    >
      <X size={20} />
    </Button>
  );
}
