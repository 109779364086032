import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { PurchasePrice, PurchasePriceValidationResult } from '../api/_generated_';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import i18n from '../i18n/config';

type Props = {
  onSuccess?(data: PurchasePrice | undefined): void;
};

export default function useCalculatePurchasePrices({
  onSuccess,
}: Props) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { toast } = useUIStore();

  async function calculatePrice(purchasePrice: PurchasePrice) {
    const res = await axios.post<PurchasePriceValidationResult>('/purchase-prices/calculate', purchasePrice);
    if (res.data.error) {
      const key = `purchase-prices-label-error-${res.data.error?.toLowerCase()}`;
      const message = i18n.exists(key) ? t(key) : t('purchase-prices.prices-message-prices-calculation-failed');

      toast.current?.show({
        severity: 'error',
        summary: message,
      });
    } else {
      toast.current?.show({
        severity: 'success',
        summary: t('purchase-prices.prices-message-prices-calculation-succeeded'),
      });
    }

    return res.data.purchasePrice;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('purchase-prices.prices-message-prices-calculation-failed'),
    });
  }

  return useMutation({
    mutationFn: calculatePrice,
    onSuccess,
    onError,
  });
}
