import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AxiosRequestConfig } from 'axios';
import { Product } from '../api/_generated_';
import { PRODUCT } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useProduct(id: string | undefined) {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  const requestConfig: AxiosRequestConfig = {
    params: {
      locale: i18n.language,
    },
  };

  async function fetchProduct() {
    const res = await axios.get<Product>(`/products/${id}`, requestConfig);
    return res.data;
  }

  return useQuery({
    queryKey: [PRODUCT, { id }],
    queryFn: fetchProduct,
  });
}
