import {
  AccountInfo,
  InteractionRequiredAuthError, InteractionStatus,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../msalConfig';

const useAccessToken = () => {
  const { instance, inProgress } = useMsal();

  const acquireAccessToken = async () => {
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account: instance.getActiveAccount() as AccountInfo,
    };

    try {
      const response = await instance.acquireTokenSilent(accessTokenRequest);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const response = await instance.acquireTokenPopup(accessTokenRequest);
        return response.accessToken;
      }
    }

    return null;
  };

  const acquireAccessTokenBackground = async () => {
    if (inProgress !== InteractionStatus.None) {
      return null;
    }

    return acquireAccessToken();
  };

  return {
    acquireAccessToken,
    acquireAccessTokenBackground,
  };
};

export default useAccessToken;
