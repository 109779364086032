import React from 'react';
import { useTranslation } from 'react-i18next';
import { composeClasses } from '../../utils/styles';

type Props = {
  period?: string;
  deadline?: string;
  alternativeHeader?: string;
  className?: string;
};

export default function PurchasePricesHeader({
  period,
  deadline,
  alternativeHeader,
  className,
}: Props) {
  const { t } = useTranslation();
  const classes = composeClasses('flex flex-wrap column-gap-8 row-gap-5', className);

  return (
    <div data-testid="purchase-price-header" className={classes}>
      <div>
        <span className="block font-bold">{alternativeHeader || t('purchase-prices.label-pricing-period')}</span>
        <span data-testid="pricing-period">{period || '-'}</span>
      </div>
      <div>
        <span className="block font-bold">{t('purchase-prices.label-report-deadline')}</span>
        <span data-testid="report-deadline">{deadline || '-'}</span>
      </div>
    </div>
  );
}
