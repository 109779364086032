import { useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import { Mail } from 'react-feather';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { SimpleProduct } from '../../api/_generated_';
import './ProductTable.scss';
import AppRoutes from '../../constants/routes';
import ThumbailPlaceholder from '../../assets/images/thumbnail_placeholder.jpg';
import ListFooter from '../ListFooter';

type Props = {
  items: SimpleProduct[];
  loading: boolean;
  pageState?: PaginatorPageChangeEvent;
  totalRecords?: number;
  onPageChange(event: PaginatorPageChangeEvent): void;
};

export default function ProductTable({
  items,
  loading,
  pageState,
  totalRecords,
  onPageChange,
}: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const onRowClick = useCallback((event: any) => navigate(`${AppRoutes.PRODUCTS}/${event.data.identifier}`), [navigate]);

  const thumbnailTemplate = (item: SimpleProduct) => (
    <img
      src={item.thumbnail ? item.thumbnail : ThumbailPlaceholder}
      alt={item.identifier}
      className="thumbnail"
      width="50"
    />
  );

  const statusTemplate = (item: SimpleProduct) => {
    const status = item.status?.toLowerCase();
    return (
      <Tag
        className={`p-tag-product-status-${status}`}
        value={t(`products.label-product-status-${status}`)}
      />
    );
  };

  const pendingChangesTemplate = (item: SimpleProduct) => {
    const { hasPendingChanges } = item;

    if (!hasPendingChanges) {
      return null;
    }

    return (
      <Tag value={t('common.label-yes')} />
    );
  };

  const messageCountTemplate = (item: SimpleProduct) => {
    const { unreadMessages } = item;

    return unreadMessages ? (
      <Badge value={unreadMessages} severity="danger" />
    ) : null;
  };

  const pendingSampleRequestsTemplate = (item: SimpleProduct) => {
    const { hasPendingSampleRequests } = item;
    return hasPendingSampleRequests
      ? <Tag value={t('common.label-yes')} />
      : null;
  };

  const footer = (
    <ListFooter
      pageState={pageState}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
    />
  );

  return (
    <DataTable
      value={items}
      onRowClick={onRowClick}
      className="mb-3 link-table"
      emptyMessage={t('products.label-no-products-found')}
      resizableColumns
      loading={loading}
      footer={footer}
    >
      <Column field="thumbnail" header={t('common.label-image')} body={thumbnailTemplate} style={{ width: '6rem' }} />
      <Column field="identifier" header={t('products.label-product-number')} />
      <Column field="status" header={t('offers.label-offer-status')} body={statusTemplate} />
      <Column field="name" header={t('common.label-name')} />
      <Column field="vintage" header={t('products.label-vintage')} />
      <Column field="hasPendingChanges" header={t('products.label-pending-changes')} body={pendingChangesTemplate} style={{ width: '16rem' }} />
      <Column header={t('offers.label-pending-sample-requests')} body={pendingSampleRequestsTemplate} style={{ width: '16rem' }} />
      <Column field="unreadMessages" header={<Mail size={20} />} headerTooltip={t('common.label-unread-messages')} headerTooltipOptions={{ position: 'left' }} body={messageCountTemplate} style={{ width: '3rem' }} />
    </DataTable>
  );
}
