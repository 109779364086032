import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AttributeGroup,
  AttributeValueAttributeTypeEnum,
  Product,
  SupplierRenewal,
} from '../../api/_generated_';
import { getLabel, getBareValue, getDownloadUrl } from '../../utils/attribute';
import DataRow from '../DataRow';
import RenewButton from '../RenewButton.tsx';
import { BASIC_DETAILS } from '../../constants/pim-attribute-groups';

type Props = {
  product: Product,
  renewals?: SupplierRenewal[],
};

export default function BasicProductInfoTab({ product, renewals }: Props) {
  const { t } = useTranslation();

  const hasRenewal = (attribute: string) => renewals?.some((r) => r.attribute === attribute);

  function renderColumns(group: AttributeGroup) {
    const attributes = group.values || {};
    return Object.keys(attributes).map((key) => (
      <DataRow
        key={`attribute-${key}`}
        label={getLabel(attributes[key])}
        value={getBareValue(attributes[key], t)}
        lockDuration={attributes[key].lockDuration}
        isImage={attributes[key].attributeType === AttributeValueAttributeTypeEnum.Image}
        isFile={attributes[key].attributeType === AttributeValueAttributeTypeEnum.File}
        downloadUrl={getDownloadUrl(
          String(attributes[key].data),
          attributes[key].code,
          product.identifier,
        )}
        className="col-12 md:col-6 mb-2"
        actions={hasRenewal(key) && (
          <RenewButton
            attribute={attributes[key]}
            productId={product.identifier}
          />
        )}
      />
    ));
  }

  return (
    <div>
      {product.details?.map((group) => (
        <div key={`tab-${group.code}`} className="px-1 md:px-3">
          <div className="attribute-group-header">
            <span className="text-lg">{group.label}</span>
          </div>
          <div className="grid">
            {group.code === BASIC_DETAILS && (
              <DataRow
                label="ID"
                value={product.identifier}
                className="col-12 md:col-6 mb-2"
              />
            )}
            {renderColumns(group)}
          </div>
        </div>
      ))}
    </div>
  );
}
