import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  lockDuration?: number,
};

export default function LockLabel({
  lockDuration,
}: Props) {
  const { t } = useTranslation();

  if (!lockDuration || lockDuration === 0) {
    return null;
  }

  return (
    <>
      <Tooltip target=".lock-label" content={t('common.attribute-lock-message')} />
      <small className="text-muted">
        {' ('}
        <i className="pi pi-lock lock-label" style={{ fontSize: '0.9rem' }} />
        {` ${lockDuration} ${lockDuration > 1 ? t('common.months') : t('common.month')}`}
        )
      </small>
    </>
  );
}
