import { useQuery } from '@tanstack/react-query';
import { FilterItem } from '../api/_generated_';
import { AVAILABLE_PRODUCT_GROUPS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useAvailableProductGroups() {
  const axios = useAxiosPrivate();

  async function fetchProductGroups() {
    const res = await axios.get<FilterItem[]>('/tender/available-product-groups');
    return res.data;
  }

  return useQuery({
    queryKey: [AVAILABLE_PRODUCT_GROUPS],
    queryFn: fetchProductGroups,
  });
}
