import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { SelectItem } from 'primereact/selectitem';
import { CreateDollyRequestDto, DollyRequest, DollyRequestProduct } from '../../api/_generated_';
import SelectField from '../StaticFields/SelectField';
import NumberField from '../StaticFields/NumberField';
import DataRow from '../DataRow';
import useCreateDollyRequest from '../../hooks/useCreateDollyRequest';
import AppRoutes from '../../constants/routes';

type Props = {
  product: DollyRequestProduct;
  dollyRequest?: DollyRequest;
  dollyTypes: SelectItem[];
  contacts: SelectItem[];
};

export default function DollyForm({
  product, dollyRequest, dollyTypes, contacts,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const defaultValues: CreateDollyRequestDto = dollyRequest ? {
    productId: dollyRequest.product?.identifier || product.id,
    type: dollyRequest.type?.value,
    saleUnitsPerPalette: dollyRequest.saleUnitsPerPalette,
    contactId: String(dollyRequest.contact?.id),
  } : {
    productId: product.id,
    type: '',
    saleUnitsPerPalette: undefined,
    contactId: '',
  };

  const {
    control,
    handleSubmit,
    reset,
  } = useForm<CreateDollyRequestDto>({
    defaultValues,
  });

  const {
    mutateAsync: createDollyRequest,
    isLoading,
  } = useCreateDollyRequest({
    dollyTypes,
  });

  const onSubmitAndReset = async (data: CreateDollyRequestDto) => {
    try {
      await createDollyRequest(data);
      reset();
    } catch (error) {
      console.error('Error creating dolly request', error);
    }
  };

  const onSubmit = async (data: CreateDollyRequestDto) => {
    await createDollyRequest(data);
    navigate(AppRoutes.DOLLY_REQUESTS);
  };

  const filteredDollyTypes = dollyTypes
    ?.filter((dollyType) => product.dollyOptionCodes.includes(dollyType.value)) || [];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-column gap-4">
        <DataRow
          label={t('products.label-product-id')}
          value={product.id}
        />
        <DataRow
          label={t('products.label-product-name')}
          value={product.name}
        />
      </div>
      <Divider />
      <SelectField
        rules={{ required: true }}
        label={t('settings.label-contact')}
        options={contacts}
        control={control}
        optionValue="value"
        optionLabel="label"
        name="contactId"
      />
      <SelectField
        rules={{ required: true }}
        label={t('products.label-dolly-type')}
        options={filteredDollyTypes}
        control={control}
        optionValue="value"
        optionLabel="label"
        name="type"
      />
      <NumberField
        rules={{ required: true }}
        label={t('products.label-sale-units-per-palette')}
        control={control}
        name="saleUnitsPerPalette"
      />
      <div className="flex gap-3 justify-content-end">
        <Button
          text
          label={t('common.cmd-back')}
          type="button"
          onClick={() => navigate(-1)}
          disabled={isLoading}
        />
        <Button
          severity="secondary"
          label={t('common.cmd-save-create-new')}
          type="button"
          onClick={handleSubmit(onSubmitAndReset)}
          disabled={isLoading}
        />
        <Button
          label={t('common.cmd-save')}
          type="submit"
          disabled={isLoading}
        />
      </div>
    </form>
  );
}
