import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import AppRoutes from '../constants/routes';
import useAxiosPrivate from './useAxiosPrivate';
import { DOLLY_REQUESTS } from '../constants/queryKeys';

type Props = {
  uuid?: string;
};

export default function useDeleteDollyRequest({ uuid }: Props) {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  async function onDeleteDollyRequest() {
    if (!uuid) throw new Error('uuid is required');
    const res = await axios.delete(`/dolly-requests/${uuid}`);
    return res.data;
  }

  return useMutation(onDeleteDollyRequest, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([DOLLY_REQUESTS, 'dollyRequest', { id: uuid }]);
      navigate(AppRoutes.DOLLY_REQUESTS);
    },
  });
}
