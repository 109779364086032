import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import Container from '../Container';
import ErrorMessage from '../ErrorMessage';
import { HttpRequestStatus } from '../types/types';

type Props = {
  status: HttpRequestStatus | HttpRequestStatus[];
  excludeContainers?: boolean;
  containerSize?: 'sm' | 'md' | 'lg';
  children?: any;
};

function getStatusElement(status: HttpRequestStatus | HttpRequestStatus[]) {
  const isLoading = Array.isArray(status)
    ? status.includes(HttpRequestStatus.LOADING)
    : status === HttpRequestStatus.LOADING;

  const isError = Array.isArray(status)
    ? status.includes(HttpRequestStatus.ERROR)
    : status === HttpRequestStatus.ERROR;

  if (isLoading) {
    return (
      <div className="text-center">
        <ProgressSpinner data-testid="loading-indicator" />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage />;
  }

  return null;
}
export default function NetworkContainer({
  status,
  children,
  excludeContainers,
  containerSize,
}: Props) {
  const statusElement = getStatusElement(status);

  if (statusElement != null) {
    return excludeContainers ? statusElement : (
      <Container size={containerSize}>
        {statusElement}
      </Container>
    );
  }

  return excludeContainers ? children : (
    <Container size={containerSize}>
      {children}
    </Container>
  );
}
