import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import NetworkContainer from '../../../components/NetworkContainer';
import { HttpRequestStatus } from '../../../components/types/types';
import useProduct from '../../../hooks/useProduct';

export default function Root() {
  const { id } = useParams();
  const { data, status } = useProduct(id);

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <Outlet context={data} />
    </NetworkContainer>
  );
}
