import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AttributeStructureItem } from '../api/_generated_';
import useAxiosPrivate from './useAxiosPrivate';

export default function useDistributorFormDynamicOptions() {
  const { i18n } = useTranslation();

  const axios = useAxiosPrivate();

  async function fetch() {
    const res = await axios.get<AttributeStructureItem[]>(`/distributor/dynamic-fields?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: ['distributorDynamicFields', i18n.language],
    queryFn: fetch,
    staleTime: 1000 * 60 * 15,
  });
}
