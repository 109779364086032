import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card';
import NetworkContainer from '../../../components/NetworkContainer';
import { HttpRequestStatus } from '../../../components/types/types';
import PermitNumbersForm from '../../../components/PermitNumbersForm';
import useSupplierPermitNumberSettings from '../../../hooks/useSupplierPermitNumbers';
import useSaveSupplierPermitNumbers from '../../../hooks/useSaveSupplierPermitNumbers';

export default function PermitNumbers() {
  const { t } = useTranslation();

  const { data, status } = useSupplierPermitNumberSettings();

  const { mutate, isLoading: isSaving } = useSaveSupplierPermitNumbers();

  return (
    <NetworkContainer status={status as HttpRequestStatus} excludeContainers>
      <Card>
        <h2 className="font-normal text-xxl mb-5">{t('settings.label-permit-numbers')}</h2>
        <PermitNumbersForm
          data={data}
          loading={isSaving}
          onSubmit={(formData) => mutate(formData)}
        />
      </Card>
    </NetworkContainer>
  );
}
