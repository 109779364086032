import React from 'react';
import { InputText, InputTextProps } from 'primereact/inputtext';
import { composeClasses } from '../../utils/styles';

type Props = InputTextProps & {
  loading?: boolean,
};

export default function SearchInput({
  loading,
  className,
  ...rest
}: Props) {
  const classes = composeClasses('w-full', className);

  return (
    <span className="p-input-icon-left w-full search-input">
      { loading
        ? <i className="pi pi-spin pi-spinner" />
        : <i className="pi pi-search" />}
      <InputText
        name="global"
        className={classes}
        {...rest}
      />
    </span>
  );
}
