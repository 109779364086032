import React from 'react';

type Props = {
  title?: string;
  to?: string;
  children?: any;
};

export default function LinkText({
  title,
  to,
  children,
}: Props) {
  return (
    <a href={to || '#'} target="_blank" title={title || ''} rel="noopener noreferrer">
      {children}
    </a>
  );
}
