import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import useMarkRenewal from '../../hooks/useMarkRenewal';
import { AttributeValue } from '../../api/_generated_';

type Props = {
  attribute: AttributeValue
  productId: string;
};

export default function RenewButton({
  attribute,
  productId,
}: Props) {
  const [, setShowConfirmDialog] = useState(false);

  const { mutate: markAsRenewed } = useMarkRenewal(productId, attribute.code);
  const { t } = useTranslation();

  const onConfirmRenew = () => {
    confirmDialog({
      header: t('products.label-attribute-renewal'),
      message: t('products.message-attribute-renewal', { name: attribute.attributeLabel }),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => markAsRenewed(),
      reject: () => setShowConfirmDialog(false),
    });
  };

  return (
    <Button
      type="button"
      icon="pi pi-exclamation-circle"
      severity="warning"
      text
      style={{
        verticalAlign: 'middle',
        display: 'inline-block',
        padding: '0',
      }}
      size="small"
      aria-label="Renew attribute"
      onClick={onConfirmRenew}
    />
  );
}
