import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { PermitNumberSettings } from '../../api/_generated_';
import BooleanField from '../StaticFields/BooleanField';
import TextField from '../StaticFields/TextField';

type Props = {
  data: PermitNumberSettings | undefined;
  loading?: boolean;
  onSubmit(data: PermitNumberSettings): void;
};

export default function PermitNumbersForm({
  data,
  loading,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    watch,
    handleSubmit,
  } = useForm<PermitNumberSettings>({
    defaultValues: { ...data },
  });

  const isAgent = watch('agent');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BooleanField
        name="agent"
        label={t('settings.label-agent')}
        control={control}
        checked={false}
      />
      {!isAgent && (
        <TextField
          name="valviraLicenceNumber"
          rules={{ required: t('validations.message-field-required') }}
          label={t('settings.label-valvira_licence_number')}
          control={control}
        />
      )}
      <TextField
        name="warehouseLicenceNumber"
        label={t('settings.label-warehouse_licence_number')}
        control={control}
      />
      <Button
        type="submit"
        label={t('common.cmd-save')}
        className="w-12rem"
        loading={loading}
      />
    </form>
  );
}
