import { useTranslation } from 'react-i18next';
import { Message } from 'primereact/message';
import { useMemo } from 'react';
import { AttributeValueAttributeTypeEnum, Offer, OfferFrame } from '../../api/_generated_';
import { getDownloadUrl, getOfferDisplayValue, getStructureItemLabel } from '../../utils/attribute';
import Card from '../Card';
import DataRow from '../DataRow';
import OfferDetailHeader from '../OfferDetailHeader';
import SampleRequests from '../SampleRequests';
import { PRODUCT_NAME } from '../../constants/pim-attributes';
import { tenderHasLockedAttributes } from '../../utils/helper';
import LockMessage from '../LockMessage';

type Props = {
  data: OfferFrame;
};

export default function OfferDetail({
  data,
}: Props) {
  const { t } = useTranslation();

  const { offer, tender } = data;

  const hasLockedAttributes = useMemo(() => tenderHasLockedAttributes(tender), [tender]);

  return (
    <div>
      <OfferDetailHeader
        offer={data.offer as Offer}
        isEditable={data.isEditingAllowed}
      />
      { offer?.hasUnsentChanges && (
        <div className="mb-3">
          <Message severity="warn" text={t('offers.label-unsent_changes_long')} className="w-full" />
        </div>
      )}
      {hasLockedAttributes && <LockMessage />}
      <SampleRequests
        items={offer?.sampleRequests}
        productName={String(offer?.attributes?.[PRODUCT_NAME])}
      />
      { tender?.sections?.map((section) => (
        <Card key={`summary-title-${section.code}`} className="mb-5">
          <div className="flex justify-content-between mb-5">
            <h4 className="mb-0 text-xl font-normal">{section.translation}</h4>
          </div>
          { section.attributes?.map((attribute) => (
            <DataRow
              key={`summary-row-${attribute.code}`}
              label={getStructureItemLabel(attribute)}
              value={getOfferDisplayValue(offer, attribute, t)}
              lockDuration={attribute.lockDuration}
              downloadUrl={getDownloadUrl(offer?.attributes?.[attribute.code] as unknown as string)}
              isImage={attribute.attributeType === AttributeValueAttributeTypeEnum.Image}
              isFile={attribute.attributeType === AttributeValueAttributeTypeEnum.File}
              className="mb-5"
            />
          ))}
        </Card>
      ))}
    </div>
  );
}
