import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SampleRequestResource, SampleRequestResourceEntityTypeEnum } from '../../api/_generated_';

type ExtendedSampleRequest = SampleRequestResource & {
  sampleTypes: string[];
};

type Props = {
  data?: SampleRequestResource[];
};

export default function DashboardSampleRequests({ data }: Props) {
  const { t } = useTranslation();

  const distinctData: ExtendedSampleRequest[] = useMemo(
    () => {
      if (!data) return [];

      return data.reduce((acc: ExtendedSampleRequest[], item) => {
        const existingItem = acc.find((accItem) => accItem.entityId === item.entityId);
        if (existingItem) {
          if (existingItem.sampleTypes.includes(item.sampleType)) {
            return acc;
          }

          existingItem.sampleTypes.push(item.sampleType);
        } else {
          acc.push({
            ...item,
            sampleTypes: [item.sampleType],
          });
        }

        return acc;
      }, []);
    },
    [data],
  );

  if (distinctData?.length === 0) {
    return <span>{t('summary.label-no-sample-requests')}</span>;
  }

  return (
    <div className="dashboard-list">
      {distinctData?.map((sampleRequest) => (
        <ListItem key={sampleRequest.uuid} item={sampleRequest} />
      ))}
    </div>
  );
}

type ListItemProps = {
  item: ExtendedSampleRequest;
};

function ListItem({ item }: ListItemProps) {
  const { t } = useTranslation();
  const link = item.entityType === SampleRequestResourceEntityTypeEnum.Offer
    ? `/offers/detail/${item.offerUuid}`
    : `/products/${item.entityId}`;

  let title = item.entityId;

  if (item.displayName) {
    title += ` - ${item.displayName}`;
  }

  return (
    <Link
      key={item.uuid}
      className="dashboard-list-item"
      to={link}
    >
      {title}
      <span className="secondary-text">
        <span className="mr-1">
          {item.sampleTypes.map((sampleType) => t(`offers.label-sample-type-${sampleType?.toLowerCase()}`)).join(', ')}
        </span>
      </span>
    </Link>
  );
}
