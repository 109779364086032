import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SelectItem } from 'primereact/selectitem';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import ViewHeader from '../../components/ViewHeader';
import { Contact } from '../../api/_generated_';
import useContacts from '../../hooks/useContacts';
import DollyForm from '../../components/DollyForm';
import useAttributeOptions from '../../hooks/useAttributeOptions';
import { DOLLY_OPTIONS } from '../../constants/pim-attributes';
import useDollyRequestProduct from '../../hooks/useDollyRequestProduct';

export default function OfferDolly() {
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('product') as string;

  const { t } = useTranslation();

  const { contacts } = useContacts();

  const {
    data,
    isSuccess,
    status,
  } = useDollyRequestProduct(productId);

  const {
    data: dollyTypes,
    isSuccess: isDollyTypesSuccess,
    status: statusDollyTypes,
  } = useAttributeOptions({
    attributeCode: DOLLY_OPTIONS,
  });

  const contactSelectItems: SelectItem[] = useMemo(() => contacts?.map((contact: Contact) => ({
    label: `${contact.firstName} ${contact.lastName}`,
    value: String(contact.id),
  })), [contacts]) || [];

  const statuses = [
    status, statusDollyTypes,
  ];

  return (
    <NetworkContainer containerSize="sm" status={statuses as HttpRequestStatus[]}>
      <ViewHeader
        title={t('products.title-offer-dolly')}
        description={t('products.title-offer-dolly-description')}
      />
      {(isSuccess && isDollyTypesSuccess) && (
        <DollyForm
          product={data}
          dollyTypes={dollyTypes as SelectItem[]}
          contacts={contactSelectItems}
        />
      )}
    </NetworkContainer>
  );
}
