// Product status options
export const PRODUCT_STATUS_ACTIVE = 'A';

export const PRODUCT_STATUS_DISCONTINUED = 'P';

export const PRODUCT_STATUS_WAITING_FOR_SELECTION = 'O';

export const OFFER_GROUP_ACCESSORIES = 'Oheistuotteet';

export const TAX_GROUP_ETHYL_OVER_2_8 = '45';
