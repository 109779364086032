import classNames from 'classnames';
import { useController } from 'react-hook-form';
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import FormErrorMessage from '../../FormErrorMessage';
import { StaticFormFieldProps } from '../../types/types';
import { OPTION_LABEL_KEY, OPTION_VALUE_KEY } from '../../../constants/dynamic-form-config';

type Props = StaticFormFieldProps & MultiSelectProps;

export default function MultiSelectField({
  name,
  control,
  required,
  rules,
  label,
  help,
  optionLabel,
  optionValue,
  ...rest
}: Props) {
  const { field, fieldState } = useController({
    name: name as string,
    control,
    rules,
  });

  return (
    <div className="field">
      { label && <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>{label}</label>}
      <MultiSelect
        className={classNames({ 'w-full': true, 'p-invalid': fieldState.error })}
        value={field.value || []}
        onChange={(e) => field.onChange(e.value)}
        ref={field.ref}
        data-testid={field.name}
        optionLabel={optionLabel || OPTION_LABEL_KEY}
        optionValue={optionValue || OPTION_VALUE_KEY}
        {...rest}
      />
      {help && <span className="form-help">{help}</span> }
      <FormErrorMessage label={fieldState.error?.message} />
    </div>
  );
}
