import { useTranslation } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import HistoryRowItem from '../HistoryItem';
import useProductHistory from '../../hooks/useProductHistory';
import { Product } from '../../api/_generated_';

type Props = {
  product: Product;
};

export default function ProductHistory({
  product,
}: Props) {
  const { t } = useTranslation();

  const { data, isLoading } = useProductHistory({
    productId: product.identifier,
    parent: product.parent,
  });

  if (isLoading) {
    return (
      <ProgressSpinner data-testid="loading-indicator" />
    );
  }

  if (!data?.length) {
    return (
      <span>{t('products.label-no-history')}</span>
    );
  }

  return (
    <>
      {data.map((item) => <HistoryRowItem item={item} key={item.id} />)}
    </>
  );
}
