import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from './useAxiosPrivate';
import { SUPPLIER_SETTINGS } from '../constants/queryKeys';
import { SupplierSettingsResponse } from '../api/_generated_';

export default function useSupplierSettings() {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  async function fetchSupplierSettings(): Promise<SupplierSettingsResponse> {
    const res = await axios.get(`/supplier-settings/general?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: [SUPPLIER_SETTINGS],
    queryFn: fetchSupplierSettings,
  });
}
