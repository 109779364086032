import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { DOLLY_REQUESTS } from '../constants/queryKeys';
import { Page } from '../api/_generated_';

type Props = {
  page: number
};

export default function useDollyRequests({
  page,
}: Props) {
  const axios = useAxiosPrivate();

  async function queryFn(): Promise<Page> {
    const res = await axios.get(`/dolly-requests?page=${page}&size=10`);
    return res.data;
  }

  return useQuery({
    queryKey: [DOLLY_REQUESTS, page],
    queryFn,
  });
}
