import React from 'react';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';
import { FilterItem, TenderSettings } from '../../api/_generated_';

type Props = {
  productGroups: FilterItem[];
  settings?: TenderSettings;
  onChange(group: string, key: string, value: any): void;
};

export default function TenderSettingsForm({
  productGroups,
  settings,
  onChange,
}: Props) {
  const { t } = useTranslation();

  const handleChange = (e: SelectButtonChangeEvent) => {
    const value = Array.isArray(e.value) ? e.value : [e.value];
    onChange('tenders', 'interestingProductGroups', value);
  };

  return (
    <>
      <span className="block mb-3 font-bold">{t('settings.label-interesting-product-groups')}</span>
      <SelectButton
        multiple
        options={productGroups}
        value={settings?.interestingProductGroups}
        onChange={handleChange}
        className="mb-3"
        name="interestingProductGroups"
      />
      <span className="form-help">{t('settings.label-interesting-product-groups-description')}</span>
    </>
  );
}
