import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ToastMessage } from 'primereact/toast';
import { PurchasePrice } from '../api/_generated_';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { CustomErrorResponseData } from '../components/types/types';

type Props = {
  onSuccess?(): void;
};

export default function useSavePurchasePrices({
  onSuccess,
}: Props) {
  const axios = useAxiosPrivate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  async function savePurchasePrices(prices: PurchasePrice[]) {
    const res = await axios.put('/purchase-prices', prices);
    return res.data;
  }

  function onError(error: AxiosError<CustomErrorResponseData>) {
    const isBadRequest = error.response?.status === 400;
    const errors = error?.response?.data?.errors;
    if (isBadRequest && errors) {
      const toastMessages = errors.map((e) => ({
        severity: 'error',
        summary: t(`purchase-prices.error.message-${e.errorCode.toLocaleLowerCase()}`, {
          entityId: e.entityId,
        }),
      })) as ToastMessage[];

      toast.current?.show(toastMessages);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: t('common.message-saving-failed'),
        detail: t('common.message-saving-failed-description'),
      });
    }
  }

  return useMutation({
    mutationFn: savePurchasePrices,
    onError,
    onSuccess,
  });
}
