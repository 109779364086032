import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HttpRequestStatus } from '../components/types/types';
import AppRoutes from '../constants/routes';
import Container from '../components/Container';
import ErrorMessage from '../components/ErrorMessage';
import useAlkoEduStatus from '../hooks/useAlkoEduStatus';
import { AlkoEduStatusStatusEnum } from '../api/_generated_';

export default function RequireAlkoEdu() {
  const location = useLocation();

  const { t } = useTranslation();

  const { data, status } = useAlkoEduStatus();

  if (status === HttpRequestStatus.LOADING) {
    return (
      <div className="text-center">
        <ProgressSpinner data-testid="loading-indicator" />
      </div>
    );
  }

  if (status === HttpRequestStatus.SUCCESS) {
    if (data.status === AlkoEduStatusStatusEnum.Approved) {
      return <Outlet />;
    }
    if (data.status === AlkoEduStatusStatusEnum.NotApproved) {
      return <Navigate to={AppRoutes.EDU_NOT_COMPLETED} state={{ from: location }} replace />;
    }
    return <Navigate to={AppRoutes.EDU_CONFIRM} state={{ from: location }} replace />;
  }

  return (
    <Container size="sm">
      <ErrorMessage
        summary={t('alko-edu.message-could-not-check-status')}
        detail={t('common.error.label-fetch-error-description')}
      />
    </Container>
  );
}
