import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { MessageRequest } from '../../api/_generated_';

type Props = {
  onSubmit(data: MessageRequest): void;
};

// TODO: Add support for uploading attachments if needed
export default function MessageForm({
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isSubmitSuccessful,
    },
  } = useForm({
    defaultValues: {
      body: '',
      attachment: undefined,
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  // TODO: Make form fixed at the bottom of the screen if Alko likes the "chat" layout
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="body"
        control={control}
        rules={{
          required: true,
          minLength: 3,
          maxLength: 1500,
        }}
        render={({ field, fieldState }) => (
          <>
            <small>
              {t('validations.label-character-length', {
                length: field.value?.length || 0,
              })}
            </small>
            <div className="p-inputgroup">
              <InputText
                {...field}
                placeholder={t('common.label-type-message')}
                className={classNames({
                  message: true,
                  'p-invalid': fieldState.error,
                })}
              />
              <Button type="submit" icon="pi pi-send" disabled={field.value?.length < 4} />
            </div>
            <small id={`${field.name}-help`} className="block p-text-secondary mt-1">
              {t('validations.label-max-character-length', {
                length: 1500,
              })}
            </small>
          </>
        )}
      />
    </form>
  );
}
