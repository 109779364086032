import React from 'react';
import classNames from 'classnames';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';
import { DynamicFormFieldProps } from '../../types/types';
import { getDynamicFieldTooltip } from '../../../utils/helper';
import { isAttributeDisabled } from '../../../utils/attribute';

type Props = DynamicFormFieldProps;

export default function PriceInput({
  attribute,
  field,
  fieldState,
}: Props) {
  const { i18n } = useTranslation();

  // TODO: Add dynamic currency
  const currency = 'EUR';

  return (
    <InputNumber
      onValueChange={field.onChange}
      value={field.value}
      mode="currency"
      currency={currency}
      minFractionDigits={2}
      locale={i18n.language}
      inputId={field.name}
      className={classNames({ 'w-full': true, 'p-invalid': fieldState.error })}
      disabled={isAttributeDisabled(attribute)}
      {...getDynamicFieldTooltip(attribute)}
    />
  );
}
