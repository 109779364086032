import { useQuery } from '@tanstack/react-query';
import { SupplierRenewal } from '../api/_generated_';
import { PRODUCT_RENEWALS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useProductRenewals(productId?: string) {
  const axios = useAxiosPrivate();

  async function fetchRenewals() {
    const res = await axios.get<SupplierRenewal[]>(`/supplier-renewal/renewals/${productId}`);
    return res.data;
  }

  return useQuery([PRODUCT_RENEWALS], fetchRenewals);
}
