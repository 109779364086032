/* eslint-disable jsx-a11y/click-events-have-key-events */
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Notification } from '../../api/_generated_';
import AppRoutes from '../../constants/routes';
import useMarkNotification from '../../hooks/useMarkNotification';
import './NotificationItem.scss';

type Props = {
  item: Notification;
};

function resolveRoute(item: Notification) {
  const { entityType, entityId, notificationType } = item;

  const lowEntityType = entityType?.toLowerCase();
  const lowNotificationType = notificationType?.toLowerCase();

  if (lowNotificationType === 'pricing_round_finished') {
    return `${AppRoutes.PURCHASE_PRICE_REPORT}`;
  }

  if (lowEntityType === 'offer') {
    return `${AppRoutes.OFFERS}/detail/${entityId}`;
  }

  if (lowEntityType === 'offer-message') {
    return `${AppRoutes.OFFERS}/messages/${entityId}`;
  }

  if (lowEntityType === 'product') {
    if (lowNotificationType === 'product_message') {
      return `${AppRoutes.PRODUCTS}/${entityId}/messages`;
    }

    return `${AppRoutes.PRODUCTS}/${entityId}`;
  }

  if (lowEntityType === 'tender') {
    return `${AppRoutes.TENDERS}/${entityId}`;
  }

  if (lowEntityType === 'settings') {
    return `${AppRoutes.SETTINGS}/general`;
  }

  if (lowEntityType === 'message') {
    return `${AppRoutes.MESSAGES}`;
  }

  if (lowNotificationType === 'supplier_doc_expired' || lowNotificationType === 'supplier_doc_expires_after_one_month') {
    return `${AppRoutes.SETTINGS}/general`;
  }

  return '/';
}

export default function NotificationItem({
  item,
}: Props) {
  const { mutate } = useMarkNotification();

  const navigate = useNavigate();

  const onClick = () => {
    if (item.unread) {
      mutate(item.id);
    }

    const route = resolveRoute(item);
    navigate(route);
  };

  return (
  // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <span role="link" key={item.id} className="notification-item" onClick={onClick}>
      { item.unread && <span className="badge" /> }
      <span className="notification-title">{item.message}</span>
      <span className="notification-timestamp">{dayjs(item.timestamp).format('LLL')}</span>
    </span>
  );
}
