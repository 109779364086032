import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { TenderMessage } from '../../api/_generated_';
import Card from '../Card';

type Props = {
  items?: TenderMessage[];
};

export default function TenderMessages({ items }: Props) {
  const { t } = useTranslation();

  if (!items?.length) {
    return null;
  }

  return (
    <Card className="mb-4">
      <span className="font-bold block mb-5">{t('tenders.label-tender-messages')}</span>
      {items.map((message) => (
        <div key={message.id} className="mb-2">
          <small className="block mb-2">{dayjs(message.createdAt).format('LLL')}</small>
          <span className="block">{message.body}</span>
        </div>
      ))}
    </Card>
  );
}
