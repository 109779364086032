import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Product, UpcomingValueChangeDto } from '../../api/_generated_';
import { formatDate } from '../../utils/helper';

type Props = {
  product: Product;
};

export default function UpcomingChanges({ product }: Props) {
  const { t } = useTranslation();

  if (!product.upcomingChanges?.length) {
    return null;
  }

  return (
    <DataTable
      className="mb-5"
      resizableColumns
      value={product.upcomingChanges}
      header={t('products.label-upcoming-changes') as string}
    >
      <Column
        field="attribute"
        header={t('products.label-change-targets')}
      />
      <Column
        field="value"
        header={t('common.label-new-value')}
      />
      <Column
        field="effective"
        header={t('products.label-effective-since')}
        body={(item: UpcomingValueChangeDto) => formatDate(item.effective as string)}
      />
      <Column
        field="created"
        header={t('common.label-created')}
        body={(item: UpcomingValueChangeDto) => formatDate(item.created as string)}
      />
    </DataTable>
  );
}
