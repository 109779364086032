import React from 'react';
import './ProfileSection.scss';
import { Avatar } from 'primereact/avatar';
import { Skeleton } from 'primereact/skeleton';
import useAuthStore from '../../stores/AuthStore';
import { getAvatarLabel } from '../../utils/helper';

export default function ProfileSection() {
  const currentUser = useAuthStore().user;

  return (
    <div data-testid="profile-section" className="profile-section">
      { currentUser
        ? <Avatar data-testid="image-avatar" shape="circle" label={getAvatarLabel(currentUser)} />
        : <Skeleton shape="circle" size="2rem" /> }
    </div>
  );
}
