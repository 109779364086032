import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from './useAxiosPrivate';
import { USER_MANUAL } from '../constants/queryKeys';

export default function useManual() {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  async function fetchUserManualUrl(): Promise<string> {
    const res = await axios.get(`/supplier-settings/user-manual?locale=${i18n.language}`);
    return res.data.userManualUrl;
  }

  return useQuery({
    queryKey: [USER_MANUAL],
    queryFn: fetchUserManualUrl,
  });
}
