import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Notification } from '../api/_generated_';
import { NOTIFICATIONS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useMarkNotification() {
  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  async function onMarkNotificationAsRead(notificationId: string | undefined | number) {
    const res = await axios.patch(`/notifications/${notificationId}/mark-as-read`);
    return res.data;
  }

  return useMutation({
    mutationFn: onMarkNotificationAsRead,
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: [NOTIFICATIONS] });

      const previousNotifications = queryClient.getQueryData<Notification[]>([NOTIFICATIONS]);

      if (previousNotifications) {
        queryClient.setQueryData<Notification[]>([NOTIFICATIONS], (old) => old?.map((item) => {
          if (item.id === id) {
            return { ...item, unread: false };
          }
          return item;
        }));
      }

      // Return a context object with the snapshotted value
      return { previousNotifications };
    },
    onSettled: () => {
      queryClient.invalidateQueries([NOTIFICATIONS]);
    },
  });
}
