export const TENDER_LIST = 'tenderList';
export const TENDER = 'tender';
export const TENDER_DETAIL = 'tenderDetail';
export const OFFER_LIST = 'offerList';
export const OFFER = 'offer';
export const OFFER_FRAME = 'offerFrame';
export const OFFER_MESSAGES = 'offerMessages';
export const PRODUCT_MESSAGES = 'productMessages';
export const PRODUCT_RENEWALS = 'productRenewals';
export const PRODUCT_LIST = 'productList';
export const PRODUCT = 'product';
export const PRODUCT_HISTORY = 'productHistory';
export const PRODUCT_CHANGE_TEMPLATE = 'productChangeTemplate';
export const PRODUCT_CHANGES = 'productChanges';
export const SETTINGS = 'settings';
export const USER_CONTACTS = 'userContacts';
export const AUTH_USER = 'authUser';
export const PRODUCTION_PLANTS = 'productionPlants';
export const NOTIFICATIONS = 'notifications';
export const AVAILABLE_PRODUCT_GROUPS = 'availableProductGroups';
export const PRODUCT_SAMPLE_CODE = 'productSampleCode';
export const OFFER_REFERENCE_BARCODE = 'offerReferenceBarcode';
export const PURCHASE_PRICES = 'purchasePrices';
export const SUPPLIER_SETTINGS = 'supplierSettings';
export const USER_MANUAL = 'userManual';
export const ALKO_EDU_STATUS = 'alkoEduStatus';
export const SUPPLIER_PERMIT_NUMBER_SETTINGS = 'supplierPermitNumberSettings';
export const SUPPLIERS = 'suppliers';
export const USER_AUTHORIZATIONS = 'offerAuthorizations';
export const DOLLIES = 'dollies';
export const ATTRIBUTE_OPTIONS = 'attributeOptions';
export const DISTRIBUTOR = 'distributor';
export const DISTRIBUTOR_CHANGES = 'distributorChanges';
export const DEPOSIT_OPTIONS = 'depositOptions';
export const DOLLY_REQUESTS = 'dollyRequests';
export const MESSAGES = 'messages';
export const UNREAD_MESSAGES_COUNT = 'unreadMessagesCount';
export const DASHBOARD_MESSAGES = 'dashboardMessages';
export const DASHBOARD_OFFERS = 'dashboardOffers';
export const DASHBOARD_SAMPLEREQUESTS = 'dashboardSampleRequests';
export const ATTRIBUTE_CONFIG = 'attributeConfig';
export const SELECTION_CHANGES = 'selectionChanges';
