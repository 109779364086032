import React from 'react';
import { StatusColor } from '../types/types';
import './StatusIndicator.scss';

type Props = {
  variant?: StatusColor
};

export default function StatusIndicator({ variant }: Props) {
  return (
    <div className={`status-indicator ${variant}`} />
  );
}
