import { PropsWithChildren, useContext, useMemo } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AttributeStructureItem } from '../../api/_generated_';
import { isPriceCalculationDisabled, isSelectAttribute } from '../../utils/attribute';
import DynamicField from './DynamicField';
import {
  ANALYSIS_CERTIFICATE,
  AUTHORIZATION,
  OFFER_GROUP,
  PRODUCT_BRAND_OR_OWNERSHIP,
  RETAIL_PRICE,
  SOKERI,
  UUTOS,
} from '../../constants/pim-attributes';
import { AttributeConfigContext } from '../../utils/AttributeConfigProvider';
import { useOfferContext } from '../../stores/OfferContext';

type Props = {
  control: Control;
  watch: UseFormWatch<any>;
  attribute: AttributeStructureItem;
  uploadUrl?: string;
  downloadUrl?: (() => string) | string;
};

export default function DynamicFieldContainer({
  control,
  watch,
  attribute,
  children,
  uploadUrl,
  downloadUrl,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  const { getFilteredOptions } = useContext(AttributeConfigContext);

  const { config } = useOfferContext();

  const formData = watch();

  const isRequired = useMemo(() => {
    if (isSelectAttribute(attribute.attributeType)
      && getFilteredOptions(attribute, watch).length === 0) {
      return false;
    }

    if (attribute.code === RETAIL_PRICE) {
      return !isPriceCalculationDisabled(watch(), config?.ethylMildLimit, config?.mildAlcoholLimit);
    }

    // Custom analysis section validations
    if (attribute.code === UUTOS) {
      if (formData[SOKERI] === null) {
        return t('validations.message-extract-is-required-if-sugar-is-empty');
      }
    } else if (attribute.code === SOKERI) {
      if (formData[OFFER_GROUP] === 'Vakevat_juomat') {
        return false;
      }
    } else if (attribute.code === ANALYSIS_CERTIFICATE) {
      return attribute.required ? t('validations.message-field-required') : false;
    } else if (attribute.code === AUTHORIZATION) {
      if (formData[PRODUCT_BRAND_OR_OWNERSHIP] === true) {
        return false;
      }
      return attribute.required ? t('validations.message-field-required') : false;
    }
    return attribute.required
      ? t('validations.message-field-required')
      : false;
  }, [attribute, t, watch, getFilteredOptions, config, formData]);

  return (
    <DynamicField
      key={`field-${attribute.code}`}
      control={control}
      watch={watch}
      required={isRequired}
      attribute={attribute}
      downloadUrl={downloadUrl}
      uploadUrl={uploadUrl}
    >
      {children}
    </DynamicField>
  );
}
