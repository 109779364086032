import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import AppRoutes from '../constants/routes';
import useAxiosPrivate from './useAxiosPrivate';
import { OFFER_LIST } from '../constants/queryKeys';

type Props = {
  uuid?: string;
};

export default function useDeleteOffer({ uuid }: Props) {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  async function onDeleteOffer() {
    if (!uuid) throw new Error('uuid is required');
    const res = await axios.delete(`/offers/${uuid}`);
    return res.data;
  }

  return useMutation(onDeleteOffer, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([OFFER_LIST]);
      navigate(AppRoutes.OFFERS);
    },
  });
}
