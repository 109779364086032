import React from 'react';
import Card from '../../../components/Card';
import NetworkContainer from '../../../components/NetworkContainer';
import NotificationsForm from '../../../components/NotificationsForm';
import useSettings from '../../../hooks/useSettings';

export default function Notifications() {
  const {
    settings, status, onChangeSetting,
  } = useSettings();

  return (
    <Card>
      <NetworkContainer status={status} excludeContainers>
        <NotificationsForm settings={settings?.notifications} onChange={onChangeSetting} />
      </NetworkContainer>
    </Card>
  );
}
