import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import {
  Calendar,
  Edit,
  Mail,
  RotateCcw,
  Trash,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Tag } from 'primereact/tag';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { confirmDialog } from 'primereact/confirmdialog';
import { Offer, OfferStatusEnum } from '../../api/_generated_';
import IconBlock from '../IconBlock';
import OfferTargetBlock from '../OfferTargetBlock';
import AppRoutes from '../../constants/routes';
import CancelOfferButton from '../CancelOfferButton';
import useDeleteOffer from '../../hooks/useDeleteOffer';
import useRevertOfferChanges from '../../hooks/useRevertOfferChanges';
import { DATE_FORMAT } from '../../i18n/config';

type Props = {
  offer: Offer;
  isEditable?: boolean;
};

export default function OfferDetailHeader({
  offer,
  isEditable,
}: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { mutate: deleteOfferMutation } = useDeleteOffer({
    uuid: offer?.uuid,
  });

  const { mutate: revertChangesMutation } = useRevertOfferChanges({
    onSuccess: () => {
      navigate(AppRoutes.OFFERS);
    },
  });

  const onCheckMessages = () => {
    navigate(`${AppRoutes.OFFERS}/messages/${offer.offerNumber}`);
  };

  const onEditOffer = () => {
    navigate(`${AppRoutes.OFFERS}/${offer.uuid}`);
  };

  const onConfirmDelete = () => {
    confirmDialog({
      header: t('common.title-confirm-deletion'),
      message: t('common.label-confirm-deletion-description'),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => deleteOfferMutation(),
    });
  };

  const onConfirmUndo = () => {
    confirmDialog({
      header: t('common.title-confirm-revert-changes'),
      message: t('common.label-confirm-reverting-description'),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => revertChangesMutation(offer.uuid),
    });
  };

  const title = t('offers.title-offer', {
    identifier: offer?.offerNumber,
  });

  const createdAt = dayjs(offer.updatedAt);
  const tag = offer?.status?.toLowerCase();

  const showCancelOfferButton = offer.status !== OfferStatusEnum.Cancelled
  && OfferStatusEnum.Draft !== offer.status
  && offer.status !== OfferStatusEnum.Purchased
  && offer.status !== OfferStatusEnum.Rejected;

  return (
    <div>
      <div className="flex flex-wrap gap-5 md:justify-content-between mb-5">
        <div className="flex flex-wrap align-items-center gap-3">
          <h1 className="mb-0 inline-block">{title}</h1>
          <Tag
            className={`p-tag-${tag}`}
            value={t(`offers.label-offer-status-${tag}`)}
          />
        </div>
        <div className="flex gap-3">
          <Button
            className="p-overlay-badge"
            severity="secondary"
            tooltip={t('common.label-messages')}
            tooltipOptions={{ position: 'bottom' }}
            onClick={onCheckMessages}
          >
            { offer.unreadMessages ? <Badge value={offer.unreadMessages} severity="danger" /> : null }
            <Mail size={20} />
          </Button>
          { isEditable && (
            <Button
              severity="secondary"
              tooltip={t('common.cmd-edit')}
              tooltipOptions={{ position: 'bottom' }}
              onClick={onEditOffer}
            >
              <Edit size={20} />
            </Button>
          )}
          {offer.hasUnsentChanges && (
          <Button
            severity="secondary"
            tooltip={t('common.cmd-revert-changes')}
            tooltipOptions={{ position: 'bottom' }}
            onClick={onConfirmUndo}
          >
            <RotateCcw size={20} />
          </Button>
          )}
          { showCancelOfferButton
            && <CancelOfferButton offer={offer} />}
          { offer?.status === OfferStatusEnum.Draft
            && (
              <Button
                severity="secondary"
                tooltip={t('common.cmd-delete')}
                tooltipOptions={{ position: 'bottom' }}
                onClick={onConfirmDelete}
              >
                <Trash size={20} />
              </Button>
            )}
        </div>
      </div>
      <div className="flex flex-wrap gap-4 md:gap-6 mb-5">
        <IconBlock label={createdAt.format(DATE_FORMAT)} icon={<Calendar />} />
        <OfferTargetBlock target={offer.target} />
      </div>
    </div>
  );
}
