import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Contact } from '../api/_generated_';
import { HttpRequestStatus } from '../components/types/types';
import { USER_CONTACTS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

export default function useContacts() {
  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const { toast } = useUIStore();

  const [activeContact, setActiveContact] = useState<Contact>();

  async function fetchContacts() {
    const res = await axios.get<Contact[]>('/contacts');
    return res.data;
  }

  async function deleteContact(id: number) {
    const res = await axios.delete(`/contacts/${id}`);
    return res.data;
  }

  async function saveContact(body: Contact): Promise<Contact> {
    if (body.id) {
      const res = await axios.put<any>(`/contacts/${body.id}`, body);
      return res.data;
    }

    const res = await axios.post<any>('/contacts', body);
    return res.data;
  }

  const queryClient = useQueryClient();

  const { status, data } = useQuery([USER_CONTACTS], fetchContacts);

  const onError = () => {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  };

  const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: saveContact,
    onError,
    onSuccess: (result) => {
      queryClient.setQueryData([USER_CONTACTS], (prev: any) => {
        const oldContact = prev.find((item: Contact) => item.id === result.id);

        if (oldContact) {
          return prev.map((item: any) => (item.id === result.id ? result : item));
        }

        return [...prev, result];
      });
      setActiveContact(undefined);
    },
  });

  const { mutate: onDeleteContact, isLoading: isDeleting } = useMutation({
    mutationFn: deleteContact,
    onError,
    onSuccess: (_, id) => {
      queryClient.setQueryData(
        [USER_CONTACTS],
        (prev: any) => prev.filter((item: Contact) => item.id !== id),
      );
      setActiveContact(undefined);
    },
  });

  const onSaveContact = (formData: Contact) => {
    if (JSON.stringify(formData) === JSON.stringify(activeContact)) {
      setActiveContact(undefined);
      return;
    }

    mutate(formData);
  };

  const setPlaceholderContact = () => {
    setActiveContact({
      id: undefined,
      lastName: '',
      firstName: '',
      email: '',
      phone: '',
    });
  };

  const onSetActiveContact = (contact: Contact) => {
    setActiveContact(contact);
  };

  const onCancelAddContact = () => {
    setActiveContact(undefined);
  };

  return {
    contacts: data,
    status: status as HttpRequestStatus,
    isSaving: isSaving || isDeleting,
    activeContact,
    onSaveContact,
    onSetActiveContact,
    onDeleteContact,
    setPlaceholderContact,
    onCancelAddContact,
  };
}
