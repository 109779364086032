import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import ViewHeader from '../../components/ViewHeader';
import useDashboard from '../../hooks/useDashboard';
import DashboardOffers from '../../components/DashboardOffers';
import DashboardSampleRequests from '../../components/DashboardSampleRequests/DashboardSampleRequests';
import DashboardMessages from '../../components/DashboardMessages';
import DashboardSection from '../../components/DashboardSection';
import './Home.scss';

function Home() {
  const { t } = useTranslation();

  const queries = useDashboard();

  return (
    <Container>
      <ViewHeader title={t('summary.title-summary')} />
      <div className="dashboard">
        <DashboardSection title={t('summary.label-unread-messages')} {...queries[0]} className="dashboard-left">
          <DashboardMessages {...queries[0]} />
        </DashboardSection>
        <div className="dashboard-right">
          <DashboardSection title={t('summary.label-pending-sample-requests')} {...queries[1]}>
            <DashboardSampleRequests {...queries[1]} />
          </DashboardSection>
          <DashboardSection title={t('summary.label-incomplete-offers')} {...queries[2]}>
            <DashboardOffers {...queries[2]} />
          </DashboardSection>
        </div>
      </div>
    </Container>
  );
}

export default Home;
