import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Container from '../../components/Container';
import ViewHeader from '../../components/ViewHeader';
import useCreateOffer from '../../hooks/useCreateOffer';
import useContacts from '../../hooks/useContacts';
import NetworkContainer from '../../components/NetworkContainer';
import { Selection } from '../../components/types/types';
import CreateChangeOfferForm from '../../components/CreateChangeOfferForm';
import { CHANGE_OFFER_TENDER_ID } from '../../constants/pim-attributes';

export default function CreateOffer() {
  const { t } = useTranslation();

  const { id: productId } = useParams();

  const { contacts, status, onSaveContact } = useContacts();

  const {
    onCreateOffer,
    isLoading,
  } = useCreateOffer(CHANGE_OFFER_TENDER_ID, Selection.ChangeOffer);

  return (
    <Container size="sm">
      <ViewHeader
        title={t('change-offers.label-advance-information')}
        description={t('change-offers.label-advance-information-description')}
      />
      <NetworkContainer status={status} excludeContainers>
        <CreateChangeOfferForm
          productId={productId}
          onSubmit={onCreateOffer}
          onAddContact={onSaveContact}
          contacts={contacts}
          isLoading={isLoading}
        />
      </NetworkContainer>
    </Container>
  );
}
