import dayjs, { Dayjs } from 'dayjs';
import { StatusColor } from '../components/types/types';

export const composeClasses = (classes: string, props?: string) => [props, classes].join(' ');

export function getIndicatorColor(date: Dayjs): StatusColor {
  if (!date) {
    return StatusColor.EXPIRED;
  }

  const today = dayjs();

  if (date.diff(today, 'days') < 0) {
    return StatusColor.EXPIRED;
  }

  if (date.diff(today, 'days') <= 2) {
    return StatusColor.EXPIRING_SOON;
  }

  if (date.diff(today, 'days') <= 7) {
    return StatusColor.EXPIRING;
  }

  return StatusColor.AVAILABLE;
}
