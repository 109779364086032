import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OfferFrame } from '../../../api/_generated_';
import AppRoutes from '../../../constants/routes';
import { isGeneralSelectionOffer, hasPhotoServiceSelected } from '../../../utils/helper';

type Props = {
  data: OfferFrame;
};

export default function InstructionsBySelection({ data }: Props) {
  const { t } = useTranslation();

  if ((isGeneralSelectionOffer(data?.offer) || hasPhotoServiceSelected(data?.offer))
    && data?.tender?.autoSampleRequestEnabled
  ) {
    return (
      <div>
        <p className="line-height-4 mb-4">{t('offers.label-general-selection-instructions')}</p>
        <Link to={`${AppRoutes.OFFERS}/detail/${data?.offer?.uuid}`} className="p-btn p-btn-primary">{t('offers.cmd-move-to-offer')}</Link>
      </div>
    );
  }

  return (
    <div>
      <p className="line-height-4 mb-4">{t('offers.label-sale-to-order-selection-instructions')}</p>
      <Link to={`${AppRoutes.OFFERS}/detail/${data?.offer?.uuid}`} className="p-btn p-btn-primary">{t('offers.cmd-move-to-offer')}</Link>
    </div>
  );
}
