import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { MESSAGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

export default function useDeleteMessage() {
  const { t } = useTranslation();

  const { toast } = useUIStore();

  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  async function deleteMessage(messageId: number) {
    const res = await axios.delete(`/messages/${messageId}`);
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  return useMutation({
    mutationFn: deleteMessage,
    onSuccess: () => {
      queryClient.invalidateQueries([MESSAGES]);
    },
    onError,
  });
}
