import React, { createContext, useCallback, useMemo } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ATTRIBUTE_CONFIG } from '../constants/queryKeys';
import { PRODUCT_GROUP } from '../constants/pim-attributes';
import { AttributeStructureItem, AttributeStructureOption } from '../api/_generated_';
import { isSelectAttribute } from './attribute';
import NetworkContainer from '../components/NetworkContainer';
import { AttributeFilter, FilterConfig, HttpRequestStatus } from '../components/types/types';
import dependencyConfig from './attribute-dependency-config.json';

function filterOptions(
  filter: AttributeFilter,
  options: AttributeStructureOption[],
  watch: UseFormWatch<any>,
): AttributeStructureOption[] {
  const value: string = watch(filter?.attributeCode || '', null);
  if (!value) {
    return [];
  }
  const allowedValues = filter.allowedValues[value];
  if (allowedValues) {
    if (allowedValues.filter) {
      return filterOptions(allowedValues.filter, options, watch);
    }
    return options.filter((o) => o.code && allowedValues.values?.includes(o.code));
  }
  return options;
}

export interface AttributeConfigInterface {
  isAttributeVisible: (
    attributeCode: string,
    values: { [key: string]: any }
  ) => boolean;
  getMaxSelections: (attributeCode: string) => number;
  getFilteredOptions: (
    attribute: AttributeStructureItem,
    watch: UseFormWatch<any>
  ) => AttributeStructureOption[];
}

export const AttributeConfigContext = createContext<AttributeConfigInterface>(
  {} as AttributeConfigInterface,
);

export function isAttributeVisibleByProductGroup(
  filterConfig: FilterConfig,
  attributeCode: string,
  productGroup: string | null,
) {
  if (productGroup === null) {
    return true;
  }

  const groups = filterConfig[attributeCode]?.productGroups;
  return !groups?.length || groups.includes(productGroup);
}

function isAttributeVisibleByOtherAttributeValue(
  attributeCode: string,
  values: { [key: string]: any } = {},
): boolean {
  const config = (dependencyConfig as any)[attributeCode];
  const visibleDeps = config?.dependsOn as Array<{ attributeCode: string; values: any[] }>;

  if (!visibleDeps) {
    return true;
  }

  return visibleDeps.every((dep) => {
    const value = values[dep.attributeCode];
    return dep.values.includes(value === undefined ? null : value);
  });
}

export function AttributeConfigProvider({ children }: any) {
  const axios = useAxiosPrivate();

  async function fetchConfig() {
    const res = await axios.get<FilterConfig>('/attributes/config');
    return res.data;
  }

  const {
    data: filterConfig,
    status,
  } = useQuery<FilterConfig>([ATTRIBUTE_CONFIG], fetchConfig);

  const isAttributeVisible = useCallback((
    attributeCode: string,
    values: { [key: string]: any } = {},
  ): boolean => {
    const byGroup = filterConfig && isAttributeVisibleByProductGroup(
      filterConfig,
      attributeCode,
      values[PRODUCT_GROUP],
    );
    const byDependencies = filterConfig && isAttributeVisibleByOtherAttributeValue(
      attributeCode,
      values,
    );
    return !!byGroup && !!byDependencies;
  }, [filterConfig]);

  const getMaxSelections = useCallback((attributeCode: string): number => {
    const config = filterConfig as any;
    return config[attributeCode]?.maxSelections;
  }, [filterConfig]);

  const getFilteredOptions = useCallback((
    attribute: AttributeStructureItem,
    watch: UseFormWatch<any>,
  ): AttributeStructureOption[] => {
    if (!isSelectAttribute(attribute.attributeType)) {
      return [];
    }

    if (attribute.value) {
      return attribute.options?.filter((o) => {
        if (Array.isArray(attribute.value)) {
          return attribute.value.includes(o.code);
        }
        return o.code === String(attribute.value);
      });
    }

    if (attribute.parentCode) {
      const parentValue = watch(attribute.parentCode);
      return parentValue ? attribute.options.filter((o) => o.parentCode === parentValue) : [];
    }

    const productGroup = watch(PRODUCT_GROUP);

    const options = attribute.options.filter((o) => !o.productGroups
      || o.productGroups.includes(productGroup));

    const config = filterConfig ? filterConfig[attribute.code] : null;

    if (config?.filter) {
      return filterOptions(config?.filter, options, watch);
    }

    return options;
  }, [filterConfig]);

  const contextValue = useMemo(() => ({
    isAttributeVisible,
    getMaxSelections,
    getFilteredOptions,
  }), [isAttributeVisible, getMaxSelections, getFilteredOptions]);

  return (
    <AttributeConfigContext.Provider value={contextValue}>
      <NetworkContainer excludeContainers status={status as HttpRequestStatus}>
        {children}
      </NetworkContainer>
    </AttributeConfigContext.Provider>
  );
}
