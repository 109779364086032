import React, { useCallback } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../api/_generated_';

type Props = {
  contacts?: Contact[];
  onAddContact(): void;
  onEditContact(contact: Contact): void;
};

export default function ContactList({
  contacts,
  onAddContact,
  onEditContact,
}: Props) {
  const { t } = useTranslation();

  const onRowClick = useCallback((event: any) => {
    onEditContact(event.data);
  }, [onEditContact]);

  const nameTemplate = (item: Contact) => `${item.lastName} ${item.firstName}`;

  return (
    <div>
      <div className="mb-5">
        <Button
          label={t('settings.cmd-add-contact')}
          icon="pi pi-plus"
          onClick={onAddContact}
          outlined
        />
      </div>
      <DataTable value={contacts} onRowClick={onRowClick} stripedRows responsiveLayout="stack" breakpoint="991px" className="bg-white border-round-2xl mb-3 link-table">
        <Column header={t('settings.label-contact')} body={nameTemplate} sortable sortField="lastName" />
        <Column field="email" header={t('common.label-email')} sortable />
        <Column field="phone" header={t('common.label-phone')} sortable />
      </DataTable>
    </div>
  );
}
