import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
import useOfferMessages from '../../hooks/useOfferMessages';
import { HttpRequestStatus } from '../../components/types/types';
import MessageForm from '../../components/MessageForm';
import MessageForum from '../../components/MessageForum/MessageForum';
import NetworkContainer from '../../components/NetworkContainer';

export default function OfferMessages() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    messages,
    status,
    offerIdentifier,
    sendMessage,
    deleteMessage,
  } = useOfferMessages();

  return (
    <NetworkContainer status={status as HttpRequestStatus} excludeContainers>
      <div className="flex align-items-center bg-white gap-3 p-2">
        <Button
          icon="pi pi-arrow-left"
          onClick={() => navigate(-1)}
          text
          rounded
        />
        <span>{t('common.cmd-back')}</span>
      </div>
      <Container size="sm">
        <h2 className="text-2xl">
          {t('offers.title-messages-related', {
            id: offerIdentifier,
          })}
        </h2>
        <MessageForm onSubmit={sendMessage} />
        <MessageForum items={messages} onDelete={deleteMessage} />
      </Container>
    </NetworkContainer>
  );
}
