import { Button } from 'primereact/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppRoutes from '../../constants/routes';
import ViewHeader from '../../components/ViewHeader';
import Container from '../../components/Container';
import useSupplierPermitNumbers from '../../hooks/useSupplierPermitNumbers';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';

export default function ChooseOfferType() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: permitNumbers, status } = useSupplierPermitNumbers();

  return (
    <Container size="sm">
      <ViewHeader
        title={t('offers.cmd-choose-offer-type')}
        description={t('offers.label-choose-offer-type-description')}
      />
      <NetworkContainer
        status={status as HttpRequestStatus}
        excludeContainers
      >
        <div className="flex flex-column gap-2 mb-3">
          <Button
            type="button"
            severity="secondary"
            size="large"
            onClick={() => navigate(`${AppRoutes.TENDERS}`)}
            label={t('offers.type.label-general-selection')}
            className="text-left"
            icon="pi pi-arrow-right"
            iconPos="right"
          />
          <Button
            type="button"
            severity="secondary"
            size="large"
            disabled={permitNumbers?.agent && !permitNumbers.warehouseLicenceNumber}
            onClick={() => navigate(`${AppRoutes.OFFER_NEW}?selection=2`)}
            label={t('offers.type.label-sale-to-order-selection')}
            className="text-left"
            icon="pi pi-arrow-right"
            iconPos="right"
          />
          <Button
            type="button"
            severity="secondary"
            size="large"
            onClick={() => navigate(`${AppRoutes.OFFER_NEW}?selection=3`)}
            label={t('offers.type.label-special-order')}
            className="text-left font-normal"
            icon="pi pi-arrow-right"
            iconPos="right"
          />
          <Button
            type="button"
            severity="secondary"
            size="large"
            disabled
            label={t('offers.type.label-m-offer')}
            className="text-left font-normal"
            icon="pi pi-arrow-right"
            iconPos="right"
          />
        </div>
      </NetworkContainer>
    </Container>
  );
}
