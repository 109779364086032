import { create } from 'zustand';
import { User } from '../api/_generated_';

interface AuthState {
  accesstoken: string | null | undefined;
  user: User | null;
  setUser: (user: User | null) => void;
  setToken: (token: string | null | undefined) => void;
  clear: () => void;
}

const useAuthStore = create<AuthState>()((set) => ({
  accesstoken: null,
  user: null,
  setToken: (token) => {
    set(() => ({ accesstoken: token }));
  },
  setUser: (currentUser: User | null) => {
    set(() => ({ user: currentUser }));
  },
  clear: () => {
    set(() => ({ accesstoken: null, user: null }));
  },
}));

export default useAuthStore;
