import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { SUPPLIER_PERMIT_NUMBER_SETTINGS } from '../constants/queryKeys';
import { PermitNumberSettings } from '../api/_generated_';

export default function useSupplierPermitNumbers() {
  const axios = useAxiosPrivate();

  async function fetchSupplierPermitNumbers(): Promise<PermitNumberSettings> {
    const res = await axios.get('/supplier-settings/permit-numbers');
    return res.data;
  }

  return useQuery({
    queryKey: [SUPPLIER_PERMIT_NUMBER_SETTINGS],
    queryFn: fetchSupplierPermitNumbers,
  });
}
