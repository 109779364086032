import {
  createContext, PropsWithChildren, useContext, useMemo,
} from 'react';
import { Tender } from '../api/_generated_';

type OfferContextInterface = {
  config?: {
    mildAlcoholLimit?: number;
    ethylMildLimit?: number;
  };
  tenderDetail?: Tender;
};

const OfferContext = createContext<OfferContextInterface>({});

export function OfferContextProvider({
  children,
  config,
  tenderDetail,
}: PropsWithChildren<OfferContextInterface>) {
  const memoizedConfig = useMemo(() => ({
    config,
    tenderDetail,
  }), [config, tenderDetail]);

  return (
    <OfferContext.Provider value={memoizedConfig}>
      {children}
    </OfferContext.Provider>
  );
}

export const useOfferContext = () => {
  const context = useContext(OfferContext);

  if (!context) {
    throw new Error('useOfferContext must be used within a MyProvider');
  }

  return context;
};
