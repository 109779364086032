import { useQuery } from '@tanstack/react-query';
import { HistoryItem } from '../api/_generated_';
import { PRODUCT_HISTORY } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

type Props = {
  productId?: string;
  parent?: string;
};

export default function useProductHistory({
  productId,
  parent,
}: Props) {
  const axios = useAxiosPrivate();

  async function fetchHistory() {
    const res = await axios.get<HistoryItem[]>(`/products/${productId}/history`, {
      params: {
        parent,
      },
    });
    return res.data;
  }

  return useQuery({
    queryKey: [PRODUCT_HISTORY, productId],
    queryFn: fetchHistory,
    staleTime: 1000 * 60 * 5,
  });
}
