/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { LogOut } from 'react-feather';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import AppRoutes from '../../constants/routes';
import './Drawer.scss';
import Logo from '../Logo';
import useLayoutStore from '../../stores/LayoutStore';
import { DrawerState } from '../types/types';
import Navigation from '../Navigation';
import useADLogin from '../../hooks/useADLogin';
import LanguageSelector from '../LanguageSelector';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function Drawer() {
  const { t } = useTranslation();
  const drawerState = useLayoutStore((state) => state.drawerState);
  const setDrawerState = useLayoutStore((state) => state.setDrawerState);

  const { logout } = useADLogin();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();

  const signOut = () => {
    axios.post('/user/logout').then(() => {
      logout().then(() => {
        navigate(AppRoutes.LOGIN);
      });
    });
  };

  const isMenuCollapsed = drawerState === DrawerState.COLLAPSED;

  return (
    <>
      { drawerState === DrawerState.OPEN
        && <div className="p-component-overlay block lg:hidden" onClick={() => setDrawerState(DrawerState.CLOSED)} />}

      <div className={classNames({
        drawer: true,
        open: drawerState === DrawerState.OPEN,
        collapsed: isMenuCollapsed,
      })}
      >
        <div className="logo-container">
          <Logo size={50} />
          <span className={classNames({
            hidden: isMenuCollapsed,
          })}
          >
            {t('common.title-for-partners')}
          </span>
        </div>
        <nav className="navigation" role="navigation">
          <NavLink to={AppRoutes.OFFER_TYPE} className="block">
            <Button
              label={!isMenuCollapsed ? t('offers.cmd-create-offer') : ''}
              icon="pi pi-plus"
              className="w-full"
              raised
            />
          </NavLink>
          <Navigation collapsed={isMenuCollapsed} />
          <ul>
            <li>
              <span aria-hidden role="button" onClick={signOut}>
                <LogOut />
                <span className={classNames({
                  hidden: isMenuCollapsed,
                })}
                >
                  {t('auth.cmd-sign-out')}
                </span>
              </span>
            </li>
          </ul>

        </nav>
        <div className="drawer-footer">
          <LanguageSelector />
        </div>
      </div>
    </>
  );
}
