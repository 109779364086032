import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { CreateOfferInput } from '../api/_generated_';
import AppRoutes from '../constants/routes';
import useAxiosPrivate from './useAxiosPrivate';
import { getToastError } from '../utils/error';
import useUIStore from '../stores/UIStore';
import { Selection } from '../components/types/types';

export default function useCreateOffer(tenderId: string, selection: Selection) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  const axios = useAxiosPrivate();

  const onError = (e: AxiosError) => {
    const message = getToastError(e, t);
    toast.current?.show(message);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (input: CreateOfferInput) => axios.post('/offers', input),
    onSuccess: (result) => {
      navigate(`${AppRoutes.OFFERS}/${result.data.uuid}`, { replace: true });
    },
    onError,
  });

  const onCreateOffer = (data: any) => {
    mutate({
      ...data,
      tenderId,
      selection,
    });
  };

  return {
    onCreateOffer,
    isLoading,
  };
}
