import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ProductionPlant } from '../api/_generated_';
import { PRODUCTION_PLANTS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

type Props = UseQueryOptions<ProductionPlant> & {
  id: string | undefined;
};

export default function useProductionPlant({ id, ...rest }: Props) {
  const axios = useAxiosPrivate();

  async function fetchProductionPlant() {
    const res = await axios.get<ProductionPlant>(`/production-plants/${id}`);
    return res.data;
  }

  return useQuery({
    queryKey: [PRODUCTION_PLANTS, { id }],
    queryFn: fetchProductionPlant,
    enabled: !!id,
    ...rest,
  });
}
