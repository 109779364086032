import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ChangeEvent, useRef, useState,
} from 'react';
import { Menu } from 'primereact/menu';
import { SelectButtonChangeEvent } from 'primereact/selectbutton';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import NetworkContainer from '../../components/NetworkContainer';
import PurchasePricesHeader from '../../components/PurchasePricesHeader';
import {
  BeverageStrength, HttpRequestStatus, PurchasePriceFilterProps,
} from '../../components/types/types';
import ViewHeader from '../../components/ViewHeader';
import AppRoutes from '../../constants/routes';
import usePurchasePrices from '../../hooks/usePurchasePrices';
import PurchasePriceTable from '../../components/PurchasePriceTable';
import PurchasePriceFilters from '../../components/PurchasePriceFilters';
import useDebounce from '../../hooks/useDebounce';
import { FilterItem } from '../../api/_generated_';
import { DATE_FORMAT } from '../../i18n/config';

export default function PurchasePriceReports() {
  const reportMenu = useRef<Menu>(null);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const [beverageStrength, setBeverageStrength] = useState<BeverageStrength | undefined>();

  const [pageState, setPageState] = useState<PaginatorPageChangeEvent>({
    first: 0,
    rows: 10,
    page: 0,
    pageCount: 0,
  });

  const debouncedSearch = useDebounce(search, 500);

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target?.value);
  };

  const handleChangeBeverageStrength = (e: SelectButtonChangeEvent) => {
    setBeverageStrength(e.value);
  };

  const filters: PurchasePriceFilterProps = {
    search,
    beverageStrength,
  };

  const { data, status } = usePurchasePrices({
    beverageStrength,
    search: debouncedSearch,
    pageState,
  });

  const depositOptions: FilterItem[] = [
    { label: '', value: null as any, sortOrder: 0 },
    ...data?.depositOptions || [],
  ];

  const deadline = dayjs(data?.deadline);

  const isDeadlineExpired = (!data?.deadline || deadline.isBefore(dayjs(), 'day'));

  const items = [
    {
      label: t('purchase-prices.label-report-mild'),
      command: () => {
        navigate(`${AppRoutes.PURCHASE_PRICE_REPORT_NEW}?type=mild`);
      },
    },
    {
      label: t('purchase-prices.label-report-strong'),
      command: () => {
        navigate(`${AppRoutes.PURCHASE_PRICE_REPORT_NEW}?type=strong`);
      },
    },
  ];

  return (
    <NetworkContainer status={status as HttpRequestStatus} containerSize="lg">
      <ViewHeader
        title={t('purchase-prices.title-purchase-price-reporting')}
        description={isDeadlineExpired
          ? t('purchase-prices.message-deadline-expired')
          : t('purchase-prices.label-purchase-price-reporting-desc')}
      >
        {!isDeadlineExpired && (
          <>
            <Menu model={items} popup ref={reportMenu} id="popup_menu" />
            <Button
              icon="pi pi-bars"
              label={t('purchase-prices.cmd-report-purchase-prices')}
              size="small"
              onClick={(event) => reportMenu?.current?.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
            />
          </>
        )}
      </ViewHeader>
      { !isDeadlineExpired && (
        <PurchasePricesHeader
          period={data?.period}
          deadline={deadline.format(DATE_FORMAT)}
          className="mb-6"
        />
      )}
      <PurchasePriceFilters
        filters={filters}
        onChangeSearch={handleChangeSearch}
        onChangeBeverageStrength={handleChangeBeverageStrength}
      />
      <PurchasePriceTable
        items={data?.items}
        mode={filters.beverageStrength}
        totalRecords={data?.totalRecords || 0}
        pageState={pageState}
        depositOptions={depositOptions}
        onPage={(e) => setPageState(e)}
      />
    </NetworkContainer>
  );
}
