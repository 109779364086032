import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosRequestConfig } from 'axios';
import { ProductChangeResponse } from '../api/_generated_';
import { PRODUCT_CHANGE_TEMPLATE } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useProductChangeTemplate() {
  const { id } = useParams();

  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  const requestConfig: AxiosRequestConfig = {
    params: {
      locale: i18n.language,
    },
  };

  async function fetchProductChangeTemplate() {
    const res = await axios.get<ProductChangeResponse>(`/product-changes/${id}`, requestConfig);
    return res.data;
  }

  return useQuery({
    queryKey: [PRODUCT_CHANGE_TEMPLATE, { id }],
    queryFn: fetchProductChangeTemplate,
  });
}
