import { Configuration } from '@azure/msal-browser'; // Only needed if you need to support the redirect flow in Firefox incognito
import { getEnv } from './env';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

const env = getEnv();

export const msalConfig: Configuration = {
  auth: {
    clientId: env.SSO_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${env.SSO_TENANT_ID}`,
    redirectUri: '/login',
    postLogoutRedirectUri: '/login',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {},
};

const configuredScopes = env.SSO_SCOPES?.split(';');
const scopes = configuredScopes || [''];

export const loginRequest = {
  scopes,
};
