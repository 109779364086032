/* eslint-disable no-promise-executor-return */
import { ReactNode, useState } from 'react';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { getToastError } from '../utils/error';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

type DownloadStatus = {
  progress: number | undefined;
  isDownloading: boolean;
};

type Props = {
  callback(data: File): void;
  url?: string;
};

export default function useFileDownload({
  callback,
  url,
}: Props) {
  const axios = useAxiosPrivate();

  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>({
    progress: undefined,
    isDownloading: false,
  });

  const { toast } = useUIStore();

  const onDownloadError = (e: AxiosError) => {
    const toastError = getToastError(e, t);
    toast?.current?.show(toastError);
  };

  const onDownload = async () => {
    const onDownloadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.round((100 * loaded) / total);
      setDownloadStatus({
        isDownloading: true,
        progress: percentage,
      });
    };

    if (!url) {
      console.error('Missing download url');
      return;
    }

    try {
      setDownloadStatus({
        isDownloading: true,
        progress: 0,
      });

      const response = await axios.get(url, {
        responseType: 'blob',
        onDownloadProgress,
      });

      callback(response.data);
    } catch (e: any) {
      onDownloadError(e);
    } finally {
      // Wait for animation to complete
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setDownloadStatus({
        progress: undefined,
        isDownloading: false,
      });
    }
  };

  const onValidationFail = () => {
    toast?.current?.show({
      severity: 'error',
      summary: t('validations.title-validate-input-file') as ReactNode,
      detail: t('validations.message-validate-input-file') as ReactNode,
    });
  };

  return {
    downloadStatus,
    onDownload,
    onValidationFail,
  };
}
