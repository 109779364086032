import { useTranslation } from 'react-i18next';
import { PurchasePrice } from '../api/_generated_';
import { BeverageStrength } from '../components/types/types';

type Props = {
  mode?: BeverageStrength;
};

export default function usePurchasePriceColumns(props?: Props) {
  const { t } = useTranslation();

  function filterColumns(mode: BeverageStrength | undefined, field: string) {
    const defaultColumns = ['productId', 'productName', 'currentPurchasePrice', 'depositInfoCode', 'futurePrice'];

    const strongColumns = [
      ...defaultColumns,
      ...['currentRetailPrice', 'purchasePrice', 'retailPrice', 'calculatePrice'],
    ];

    const mildColumns = [
      ...defaultColumns,
      ...['purchasePrice', 'calculatePrice'],
    ];

    if (BeverageStrength.MILD === mode) {
      return mildColumns.includes(field);
    }

    if (BeverageStrength.STRONG === mode) {
      return strongColumns.includes(field);
    }

    return true;
  }

  function toExcelColumn(item: PurchasePrice) {
    if (props?.mode === BeverageStrength.STRONG) {
      return {
        ID: item.productId,
        [t('common.label-name')]: item.productName,
        [t('purchase-prices.label-current-purchase-price')]: item.currentPurchasePrice,
        [t('purchase-prices.label-current-retail-price')]: item.currentRetailPrice,
        [t('purchase-prices.label-purchase-price')]: item.purchasePrice,
        [t('purchase-prices.label-retail-price')]: item.retailPrice,
        [t('purchase-prices.label-deposit-info')]: item.depositInfoCode,
      };
    }

    return {
      ID: item.productId,
      [t('common.label-name')]: item.productName,
      [t('purchase-prices.label-current-purchase-price')]: item.currentPurchasePrice,
      [t('purchase-prices.label-current-retail-price')]: item.currentRetailPrice,
      [t('purchase-prices.label-purchase-price')]: item.purchasePrice,
      [t('purchase-prices.label-deposit-info')]: item.depositInfoCode,
    };
  }

  return {
    filterColumns,
    toExcelColumn,
  };
}
