import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ProductChangeResponse } from '../api/_generated_';
import { PRODUCT_CHANGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useProductChange() {
  const { id, proposalId } = useParams();

  const axios = useAxiosPrivate();

  async function fetchProductChange() {
    const res = await axios.get<ProductChangeResponse>(`/product-changes/${id}/${proposalId}`);
    return res.data;
  }

  return useQuery({
    queryKey: [PRODUCT_CHANGES, { id }],
    queryFn: fetchProductChange,
  });
}
