import { UseQueryResult } from '@tanstack/react-query';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Offer } from '../../api/_generated_';
import DashboardSkeletons from '../DashboardSkeletons';
import { PRODUCT_NAME } from '../../constants/pim-attributes';

type Props = UseQueryResult<Offer[]>;

export default function DashboardOffers({ isLoading, isError, data }: Props) {
  const { t } = useTranslation();

  if (isLoading) {
    return <DashboardSkeletons />;
  }

  if (isError) {
    return (
      <Message severity="error" text={t('common.error.label-fetch-error')} className="w-full" />
    );
  }

  if (data?.length === 0) {
    return <span>{t('summary.label-open-offers')}</span>;
  }

  return (
    <div className="dashboard-list">
      {data?.map((offer) => (
        <ListItem key={offer.uuid} item={offer} />
      ))}
    </div>
  );
}

type ListItemProps = {
  item: Offer;
};

function ListItem({ item }: ListItemProps) {
  const displayValue = item.attributes?.[PRODUCT_NAME] as unknown as string
    || item.offerNumber || item.uuid;

  return (
    <Link
      key={item.uuid}
      className="dashboard-list-item"
      to={`/offers/detail/${item.uuid}`}
    >
      {displayValue}
    </Link>
  );
}
