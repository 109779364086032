import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { ALKO_EDU_STATUS } from '../constants/queryKeys';

export default function useAlkoEduConfirm() {
  const axios = useAxiosPrivate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  const queryClient = useQueryClient();

  async function confirmAlkoEdu(): Promise<void> {
    const res = await axios.put('/supplier-settings/alko-edu-confirm');
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  function onSuccess() {
    toast.current?.show({
      severity: 'success',
      summary: t('settings.message-settings-saved'),
    });
  }

  return useMutation({
    mutationFn: confirmAlkoEdu,
    onError,
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries([ALKO_EDU_STATUS]);
    },
  });
}
