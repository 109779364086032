import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { Printer } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';
import ViewHeader from '../../components/ViewHeader';
import useTender from '../../hooks/useTender';
import TenderDetail from '../../components/TenderDetail';
import TenderPrint from '../../components/TenderPrint';
import NetworkContainer from '../../components/NetworkContainer';
import { TENDER_EXPIRES_AT } from '../../constants/pim-attributes';

const today = new Date();

export default function Tender() {
  const { t } = useTranslation();

  const {
    tender, status, onOffer,
  } = useTender();

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  if (!tender) return null;

  const title = t('tenders.title-tender', {
    identifier: tender?.identifier,
  });

  const deadlineAttr = { ...tender?.values?.[TENDER_EXPIRES_AT] };
  const deadline = dayjs(String(deadlineAttr.data));

  // Check if deadline is in the past and not today
  const tenderExpired = deadline.isBefore(today, 'day') && !deadline.isSame(today, 'day');

  return (
    <NetworkContainer status={status} containerSize="sm">
      <div className="hidden">
        <TenderPrint tender={tender} ref={printRef} />
      </div>
      <ViewHeader title={title}>
        <Button severity="secondary" size="small" onClick={handlePrint}>
          <Printer size={20} />
        </Button>
        { !tenderExpired && <Button label={t('offers.cmd-create-offer')} size="small" onClick={onOffer} /> }
      </ViewHeader>
      <TenderDetail
        deadline={deadline}
        tender={tender}
      />
      <div className="flex justify-content-between">
        <div className="flex gap-3" />
        { !tenderExpired && <Button label={t('offers.cmd-create-offer')} onClick={onOffer} /> }
      </div>
    </NetworkContainer>
  );
}
