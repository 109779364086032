import './HistoryItem.scss';
import { useTranslation } from 'react-i18next';
import { Panel } from 'primereact/panel';
import { HistoryItem as HistoryItemType } from '../../api/_generated_';
import { formatDate } from '../../utils/helper';
import { getValue } from '../../utils/attribute';

type Props = {
  item: HistoryItemType;
};

export default function HistoryRowItem({
  item,
}: Props) {
  const { t } = useTranslation();

  return (
    <div
      data-testid="history-item"
      className="history-item"
    >
      <div>
        <span className="font-medium">{`${t('common.label-date')} `}</span>
        <span data-testid="timestamp">{formatDate(item.timestamp)}</span>
      </div>
      <Panel
        header={t('products.label-change-targets-x', { count: item.attributes?.length })}
        toggleable
      >
        {item.attributes?.map((change) => (
          <div key={change.attribute + change.locale} className="change-row">
            <span className="block" key={change.attribute + change.locale}>
              {change.attributeLabel}
              {change.locale && ` (${change.locale})`}
            </span>
            <div className="text-muted">
              <span>
                {`${t('common.label-old-value')}: `}
              </span>
              <span className="old-value">{getValue(change.attributeType, change.oldValue, t)}</span>
            </div>
            <div className="text-muted">
              <span>
                {`${t('common.label-new-value')}: `}
              </span>
              <span className="new-value">{getValue(change.attributeType, change.newValue, t)}</span>
            </div>
          </div>
        ))}
      </Panel>
    </div>
  );
}
