import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { OFFER_FRAME, OFFER_LIST } from '../constants/queryKeys';

type Props = {
  onSuccess?(): void;
};

export default function useRevertOfferChanges({ onSuccess }: Props = {}) {
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();

  async function onRevertChanges(offerUuid?: string) {
    const res = await axios.post(`/offers/${offerUuid}/revert-changes`);
    return res.data;
  }

  return useMutation(onRevertChanges, {
    onSuccess: async (_, offerUuid) => {
      await queryClient.invalidateQueries([OFFER_LIST]);
      queryClient.invalidateQueries([OFFER_FRAME, offerUuid]);
      onSuccess?.();
    },
  });
}
