export const TENDER_EXPIRES_AT = 'HakuPaattyy';

export const TENDER_OTHER_REQUIREMENTS = 'TuoKuvaus';

export const TENDER_CUSTOMER_TYPE = 'ValAsiakastyyppi';

export const TENDER_HIGHLIGHT_ATTRIBUTES = [
  TENDER_EXPIRES_AT,
  TENDER_OTHER_REQUIREMENTS,
  TENDER_CUSTOMER_TYPE,
];

export const DEFAULT_TENDER_ID = 'tarjouspohja_avoin';

export const CHANGE_OFFER_TENDER_ID = 'tarjouspohja_muutostarjous';

export const PRODUCTION_PLANTS_ATTR_CODE = 'TuoTuotantolaitokset';

export const AUTHORIZATION_ATTR_CODE = 'TarValtuutus';

export const PRODUCT_NAME = 'TuoNimi';

export const PURCHASE_PRICE = 'HinOstohinta';

export const O_PRICE_TIVA = 'TuoOHintaTiva';

export const ALCOHOL_CONTENT = 'TuoAlkoPitEtik';

export const PRICING_GROUP = 'HinHinnryhma';

export const SALES_UNITS_PER_12_MONTHS = 'HinMyyntiyks';

export const PACKAGING_TYPE_SPECIFIER = 'TuoPaallysteMateriaali';

export const SALES_UNIT_SIZE = 'TuoPulloKoko';

export const PACKAGE_TAX = 'TuoLisavero';

export const CUSTOMS_FEE = 'HinTulliLtr';

export const SMALL_SPECIAL_BATCH = 'TuoMyymalaLuokka';

export const SMALL_PRODUCER_MITIGATION = 'HinPienHuoj';

export const OFFER_GROUP = 'TarRyhma';

export const PRODUCT_GROUP = 'Tuoryhma';

export const PRODUCT_SUB_GROUP = 'TuoAlaRyhma';

export const CLOUSE_TYPE = 'TuoKorkki';

export const CLOSURE_SUB_TYPE = 'TuoKorkkiAlat';

export const COUNTRY_OF_ORIGIN = 'TuoAlkupMaa';

export const COUNTRY_OF_DELIVERY = 'TuoTuontimaa';

export const ALCOHOL_TAX_GROUP = 'TuoVeroryhma';

export const PRODUCTION_AREA = 'TuoTuotAlue';

export const PRODUCTION_SUB_AREA = 'TuoAlaTuotAlue';

export const DOLLY_OPTIONS = 'TuoDollyVaihtoehdot';

export const PRODUCT_EOL_OPTIONS = 'TuoPoistamissyy';

export const DOLLY_SUBMIT_DEADLINE = 'TuoDollymuutosJattoPvm';

export const SEASONAL_DOLLY_AVAILABLE_SINCE = 'TuoDollyTilattavissaAlkuPvm';

export const SEASONAL_DOLLY_AVAILABLE_UNTIL = 'TuoDollyTilattavissaLoppuPvm';

export const ALL_YEAR_DOLLY_AVAILABLE_SINCE = 'TuoYmpDollyTilattavissaAlkuPvm';

export const DOLLY_ATTRIBUTES = [
  DOLLY_OPTIONS,
  DOLLY_SUBMIT_DEADLINE,
  SEASONAL_DOLLY_AVAILABLE_SINCE,
  SEASONAL_DOLLY_AVAILABLE_UNTIL,
  ALL_YEAR_DOLLY_AVAILABLE_SINCE,
];

export const PHOTOGRAPHY_SERVICE = 'TarValokuvataan';

export const BSCI_ACCEPTANCE = 'BSCIHyvaksynta';

export const OFFER_TARGET = 'TarKohde';

export const UUTOS = 'uutos';

export const SOKERI = 'sokeri';

export const ANALYSIS_CERTIFICATE = 'TarAnalyysiTod';

export const OLD_PRODUCT_NUMBER = 'TuoEdTuotenumeroTiva';

export const AUTHORIZATION = 'TarValtakirjaFile';

export const OFFER_TYPE = 'TarTyyppi';

export const EAN_CODE = 'TuoEan';

export const VINTAGE = 'vuosikerta';

export const PRODUCT_BRAND_OR_OWNERSHIP = 'TarTuotemerkkiValmisteTaiOmistusoikeus';

export const RETAIL_PRICE = 'TuoVMH';

export const SELECTION_DATE = 'TuoValikoimaanPvm';
