import React from 'react';

type Props = {
  label?: string;
};

export default function FormErrorMessage({ label }: Props) {
  if (!label) {
    return null;
  }

  return (
    <small className="p-error">{label}</small>
  );
}
