/* eslint-disable no-promise-executor-return */
import { ReactNode, useState } from 'react';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { FileUploadHandlerEvent } from 'primereact/fileupload';
import { getToastError } from '../utils/error';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

type UploadStatus = {
  progress: number | undefined;
  isUploading: boolean;
};

type Props = {
  callback(data: any): void;
  url?: string;
};

export default function useFileUpload({
  callback,
  url,
}: Props) {
  const { toast } = useUIStore();

  const axios = useAxiosPrivate();

  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({
    progress: undefined,
    isUploading: false,
  });

  const onUploadError = (e: AxiosError) => {
    const toastError = getToastError(e, t);
    toast?.current?.show(toastError);
  };

  const onUpload = async (event: FileUploadHandlerEvent) => {
    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.round((100 * loaded) / total);
      setUploadStatus({
        isUploading: true,
        progress: percentage,
      });
    };

    const formData = new FormData();
    formData.append('file', event.files[0]);

    if (!url) {
      return;
    }

    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });

      callback(response.data);
    } catch (e: any) {
      onUploadError(e);
    } finally {
      event.options.clear();

      // Wait for animation to complete
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setUploadStatus({
        progress: undefined,
        isUploading: false,
      });
    }
  };

  const onValidationFail = () => {
    toast?.current?.show({
      severity: 'error',
      summary: t('validations.title-validate-input-file') as ReactNode,
      detail: t('validations.message-validate-input-file') as ReactNode,
    });
  };

  return {
    toast,
    uploadStatus,
    onUpload,
    onValidationFail,
  };
}
