import { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import {
  Navigate,
  useLocation,
} from 'react-router-dom';
import Container from '../../components/Container';
import ViewHeader from '../../components/ViewHeader';
import useAlkoEduConfirm from '../../hooks/useAlkoEduConfirm';
import useAlkoEduStatus from '../../hooks/useAlkoEduStatus';
import { AlkoEduStatusStatusEnum } from '../../api/_generated_';

export default function AgentEduConfirm() {
  const location = useLocation();

  const route = location.state?.from || '/';

  const { t } = useTranslation();

  const { data } = useAlkoEduStatus();

  const [confirm, setConfirm] = useState<boolean>(false);

  const { mutate, isLoading: isSaving } = useAlkoEduConfirm();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    mutate();
  };

  if (data?.status === AlkoEduStatusStatusEnum.Approved) {
    return (
      <Navigate to={route} replace />
    );
  }

  return (
    <Container size="sm">
      <ViewHeader title={t('alko-edu.title')} />
      <form onSubmit={handleSubmit}>
        <div className="field flex align-items-center gap-3 mb-6">
          <Checkbox
            inputId="confirmAlkoEdu"
            name="confirmAlkoEdu"
            checked={confirm}
            onChange={(e) => setConfirm(!!e.checked)}
          />
          <label htmlFor="confirmAlkoEdu" className="mb-0">
            {t('alko-edu.agent-confirm-description')}
          </label>
        </div>
        <Button
          type="submit"
          label={t('common.cmd-save-and-continue')}
          icon="pi pi-arrow-right"
          iconPos="right"
          disabled={!confirm}
          loading={isSaving}
        />
      </form>
    </Container>
  );
}
