import { Route, Routes } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import AppRoutes from '../../constants/routes';
import useApp from '../../hooks/useApp';
import CreateOffer from '../../pages/CreateOffer';
import FillOffer from '../../pages/FillOffer';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import Offers from '../../pages/Offers';
import Tender from '../../pages/Tender';
import Tenders from '../../pages/Tenders';
import RequireAuth from '../../utils/RequireAuth';
import {
  GeneralSettings, SettingsRoot, Contacts, Notifications, TenderSettings, PermitNumbers,
} from '../../pages/Settings';
import Products from '../../pages/Products';
import Offer from '../../pages/Offer';
import OfferMessages from '../../pages/OfferMessages';
import CreateProductChange from '../../pages/CreateProductChange';
import PurchasePriceReports from '../../pages/PurchasePriceReports';
import ReportPurchasePrices from '../../pages/ReportPurchasePrices';
import useUIStore from '../../stores/UIStore';
import EditProductChange from '../../pages/EditProductChange';
import ProductChangePreview from '../../pages/ProductChangePreview';
import CreateDistributorChange from '../../pages/CreateDistributorChange';
import DistributorChanges from '../../pages/DistributorChanges';
import OfferDolly from '../../pages/OfferDolly';
import DollyRequests from '../../pages/DollyRequests';
import DollyRequest from '../../pages/DollyRequest';
import AfterOfferingInstructions from '../../pages/AfterOfferingInstructions';
import RequireBsci from '../../utils/RequireBsci';
import CodeOfConduct from '../../pages/CodeOfConduct';
import { ProductDetails, ProductMessages, ProductRoot } from '../../pages/Product';
import ProductionPlants from '../../pages/ProductionPlants';
import CreateProductionPlant from '../../pages/CreateProductionPlant';
import UpdateProductionPlant from '../../pages/UpdateProductionPlant';
import ChooseOfferType from '../../pages/ChooseOfferType';
import Messages from '../../pages/Messages';
import CreateChangeOffer from '../../pages/CreateChangeOffer';
import AgentEduConfirm from '../../pages/AgentEduConfirm';
import RequireAlkoEdu from '../../utils/RequireAlkoEdu';
import EduNotCompleted from '../../pages/EduNotCompleted';

function App() {
  const { initializingApp } = useApp();

  const { toast } = useUIStore();

  if (initializingApp) {
    return (
      <ProgressBar
        mode="indeterminate"
        style={{ height: '3px', borderRadius: 0 }}
      />
    );
  }

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <Routes>
        <Route path="/login" element={<Login />} />

        {/* protected routes */}
        <Route element={<RequireAuth />}>
          <Route element={<RequireBsci />}>
            <Route element={<RequireAlkoEdu />}>
              <Route path={AppRoutes.OFFER_TYPE} element={<ChooseOfferType />} />
              <Route path={AppRoutes.OFFER_NEW} element={<CreateOffer />} />
              <Route path={`${AppRoutes.PRODUCT_CHANGE_OFFER}/:id`} element={<CreateChangeOffer />} />
            </Route>
            <Route path={AppRoutes.OFFERS} element={<Offers />} />
            <Route path={`${AppRoutes.OFFERS}/:id`} element={<FillOffer />} />
            <Route path={`${AppRoutes.OFFERS}/detail/:id`} element={<Offer />} />
            <Route path={AppRoutes.PRODUCTS} element={<Products />} />
            <Route path={`${AppRoutes.PRODUCTS}/:id`} element={<ProductRoot />}>
              <Route path="" element={<ProductDetails />} />
              <Route path="messages" element={<ProductMessages />} />
            </Route>
            <Route path={`${AppRoutes.PRODUCT_CHANGE}/:id`} element={<CreateProductChange />} />
            <Route path={`${AppRoutes.PRODUCT_CHANGE}/:id/:proposalId`} element={<EditProductChange />} />
          </Route>
          <Route path={AppRoutes.HOME} element={<Home />} />
          <Route path={AppRoutes.TENDERS} element={<Tenders />} />
          <Route path={`${AppRoutes.TENDERS}/:id`} element={<Tender />} />
          <Route path={`${AppRoutes.OFFERS}/messages/:id`} element={<OfferMessages />} />
          <Route path={`${AppRoutes.PRODUCT_CHANGE}/:id/:proposalId/preview`} element={<ProductChangePreview />} />
          <Route path={AppRoutes.DISTRIBUTOR_CHANGES} element={<DistributorChanges />} />
          <Route path={AppRoutes.DISTRIBUTOR_CHANGES_NEW} element={<CreateDistributorChange />} />
          <Route path={`${AppRoutes.PURCHASE_PRICE_REPORT}`} element={<PurchasePriceReports />} />
          <Route path={`${AppRoutes.PURCHASE_PRICE_REPORT_NEW}`} element={<ReportPurchasePrices />} />
          <Route path={`${AppRoutes.NEW_DOLLY}`} element={<OfferDolly />} />
          <Route path={`${AppRoutes.DOLLY_REQUESTS}`} element={<DollyRequests />} />
          <Route path={`${AppRoutes.DOLLY_REQUESTS}/:id`} element={<DollyRequest />} />
          <Route path={AppRoutes.PRODUCTION_PLANTS} element={<ProductionPlants />} />
          <Route path={`${AppRoutes.PRODUCTION_PLANTS_NEW}`} element={<CreateProductionPlant />} />
          <Route path={`${AppRoutes.PRODUCTION_PLANTS}/:id`} element={<UpdateProductionPlant />} />
          <Route path={AppRoutes.SETTINGS} element={<SettingsRoot />}>
            <Route path="" element={<GeneralSettings />} />
            <Route path="general" element={<GeneralSettings />} />
            <Route path="permit-numbers" element={<PermitNumbers />} />
            <Route path="tender-settings" element={<TenderSettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="contacts" element={<Contacts />} />
          </Route>
          <Route path={`${AppRoutes.AFTER_OFFERING_INSTRUCTIONS}/:id`} element={<AfterOfferingInstructions />} />
          <Route path={AppRoutes.ACCEPT_CODE_OF_CONDUCT} element={<CodeOfConduct />} />
          <Route path={AppRoutes.EDU_NOT_COMPLETED} element={<EduNotCompleted />} />
          <Route path={AppRoutes.EDU_CONFIRM} element={<AgentEduConfirm />} />
          <Route path={AppRoutes.MESSAGES} element={<Messages />} />
          <Route path="*" element={<span>No route found</span>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
