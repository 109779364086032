import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ProductMessage } from '../api/_generated_';
import { PRODUCT_MESSAGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

type Props = {
  productId?: string;
};

export default function useDeleteProductMessage({
  productId,
}: Props) {
  const { t } = useTranslation();

  const { toast } = useUIStore();

  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  async function deleteMessage(messageId: number) {
    const res = await axios.delete<ProductMessage>(`/product-messages/${messageId}`);
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  return useMutation({
    mutationFn: deleteMessage,
    onSuccess: (_, id) => {
      queryClient.setQueryData(
        [PRODUCT_MESSAGES, productId],
        (prev: any) => prev.filter((item: ProductMessage) => item.id !== id),
      );
    },
    onError,
  });
}
