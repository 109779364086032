import { useCallback, useMemo } from 'react';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { ProductionPlant, ProductionPlantFieldOptions } from '../../api/_generated_';
import AppRoutes from '../../constants/routes';

type Props = {
  items: ProductionPlant[];
  data: ProductionPlantFieldOptions;
};

export default function ProductionPlantTable({ items, data }: Props) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onRowClick = useCallback((event: DataTableRowClickEvent) => {
    navigate(`${AppRoutes.PRODUCTION_PLANTS}/${event.data.id}`);
  }, [navigate]);

  const tableData = useMemo(
    () => items.map((item) => {
      const supplyChainType = data.supplyChains?.find(
        (option) => option.code === item.supplyChainType,
      )?.translation || item.supplyChainType;

      return {
        ...item,
        supplyChainType,
      };
    }),
    [items, data],
  );

  return (
    <DataTable
      value={tableData}
      onRowClick={onRowClick}
      breakpoint="991px"
      className="mb-3 link-table"
    >
      <Column field="id" header="ID" />
      <Column field="supplyChainType" header={t('common.label-supply-chain-type')} />
      <Column field="name" header={t('common.label-name')} sortable />
    </DataTable>
  );
}
