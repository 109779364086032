import { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import {
  Navigate, useLocation,
} from 'react-router-dom';
import { BSCI_ACCEPTANCE } from '../../constants/pim-attributes';
import Container from '../../components/Container';
import ViewHeader from '../../components/ViewHeader';
import useSaveSupplierSettings from '../../hooks/useSaveSupplierSettings';
import { getEnv } from '../../env';
import LinkText from '../../components/LinkText';
import useSupplierSettings from '../../hooks/useSupplierSettings';

const env = getEnv();

export default function CodeOfConduct() {
  const location = useLocation();

  const route = location.state?.from || '/';

  const { data } = useSupplierSettings();

  const { mutate, isLoading: isSaving } = useSaveSupplierSettings();

  const { t } = useTranslation();

  const [bsciAcceptance, setBsciAcceptance] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    mutate({
      [BSCI_ACCEPTANCE]: bsciAcceptance,
    });
  };

  if (data?.values?.[BSCI_ACCEPTANCE]) {
    return (
      <Navigate to={route} replace />
    );
  }

  return (
    <Container size="sm">
      <ViewHeader title="BSCI Code of Conduct" />
      <form onSubmit={handleSubmit}>
        <p className="mb-5">
          <Trans
            i18nKey="code-of-conduct.label-proceed-description"
            components={{
              link1: <LinkText to={env.CODE_OF_CONDUCT_LINK} title="BSCI Code of Conduct -ehdot" />,
            }}
          />
        </p>
        <div className="field flex align-items-center gap-3 mb-6">
          <Checkbox
            inputId="acceptBsciCodeOfConduct"
            name="acceptBsciCodeOfConduct"
            checked={bsciAcceptance}
            onChange={(e) => setBsciAcceptance(!!e.checked)}
          />
          <label htmlFor="acceptBsciCodeOfConduct" className="mb-0">
            {t('code-of-conduct.cmd-accept-terms')}
          </label>
        </div>
        <Button
          type="submit"
          label={t('common.cmd-save-and-continue')}
          icon="pi pi-arrow-right"
          iconPos="right"
          disabled={!bsciAcceptance}
          loading={isSaving}
        />
      </form>
    </Container>
  );
}
