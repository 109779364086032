import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { SUPPLIERS } from '../constants/queryKeys';
import { SupplierDto } from '../api/_generated_';

export default function useSuppliers() {
  const axios = useAxiosPrivate();

  async function fetchSuppliers(): Promise<SupplierDto[]> {
    const res = await axios.get('/suppliers');
    return res.data;
  }

  return useQuery({
    queryKey: [SUPPLIERS],
    queryFn: fetchSuppliers,
  });
}
