import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Tag as TagIcon } from 'react-feather';
import { Button } from 'primereact/button';
import { useMemo } from 'react';
import { Divider } from 'primereact/divider';
import { SampleRequestResource, SampleRequestResourceEntityTypeEnum, SampleRequestResourceSampleTypeEnum } from '../../api/_generated_';
import { formatDate } from '../../utils/helper';
import usePrinting from '../../hooks/usePrinting';
import BarcodePrint from '../BarcodePrint';
import useBarcodes, { ExtendedSampleRequest } from '../../hooks/useBarcodes';
import SampleReceptionInfo from '../SampleReceptionInfo';

type Props = {
  productName: string;
  items?: SampleRequestResource[];
};

export default function SampleRequests({ productName, items }: Props) {
  const { t } = useTranslation();

  const { printRef, handlePrint, isPrinting } = usePrinting();

  const barcodesToPrint = useMemo(() => items?.filter((item) => !item.isReceived), [items]);

  const barcodeQueries = useBarcodes(barcodesToPrint);

  const barcodesLoading = barcodeQueries?.some((query) => query.isLoading);

  const barcodesData = useMemo(() => {
    if (barcodesLoading) {
      return [];
    }

    const queryData = barcodeQueries?.map((query) => query.data);
    const data = queryData?.reduce((acc: ExtendedSampleRequest[], item: any) => {
      if (!item
        || SampleRequestResourceSampleTypeEnum.PalpaRegistrationSample === item.sampleType
      ) {
        return acc;
      }

      for (let i = 0; i < item.requestedSampleQuantity; i += 1) {
        acc.push({
          ...item,
          printId: `${item.sampleIdentifier}-${i + 1}`,
        });
      }
      return acc;
    }, []);

    return data;
  }, [barcodeQueries, barcodesLoading]);

  const statusTemplate = (item: SampleRequestResource) => {
    if (item.isReceived) {
      return (
        <Tag
          className="p-tag-sample_received"
          value={t('offers.label-sample-received')}
        />
      );
    }

    return (
      <Tag className="p-tag-sample_requested" value={t('offers.label-waiting-for-sample')} />
    );
  };

  const tableHeader = (
    <div className="flex align-items-center justify-content-between">
      <div className="flex gap-3">
        <TagIcon />
        <span className="font-bold">
          {t('offers.label-sample-requests')}
        </span>
      </div>
      <div className="actions">
        {!!barcodesData?.length && (
        <Button
          label={t('products.cmd-print-sample-barcodes')}
          size="small"
          text
          icon="pi pi-print"
          loading={isPrinting || barcodesLoading}
          onClick={handlePrint}
          disabled={!items?.length}
        />
        )}
      </div>
    </div>
  );

  const dateTemplate = (item: string | undefined) => (item ? formatDate(item) : '-');

  const booleanTemplate = (item: boolean) => (item ? <Tag value={t('common.label-yes')} /> : '');

  const sampleTypeTemplate = (sampleType: SampleRequestResourceEntityTypeEnum) => t(`offers.label-sample-type-${sampleType?.toLowerCase()}`);

  return (
    <>
      <div className="hidden">
        <div ref={printRef}>
          {barcodesData?.map((item) => (
            <BarcodePrint
              key={item?.printId}
              src={item?.src}
              label={productName}
              code={item?.sampleIdentifier}
            />
          ))}
          <Divider />
          <SampleReceptionInfo />
        </div>
      </div>
      <DataTable
        dataKey="uuid"
        value={items}
        header={tableHeader}
        className="mb-5"
        resizableColumns
        emptyMessage={t('common.label-no-results')}
      >
        <Column field="isReceived" header={t('common.label-status')} body={statusTemplate} />
        <Column field="sampleType" header={t('common.label-sample-type')} body={(item: SampleRequestResource) => sampleTypeTemplate(item.sampleType as SampleRequestResourceEntityTypeEnum)} />
        <Column field="createdAt" header={t('offers.label-sample-requested')} body={(item: SampleRequestResource) => dateTemplate(item.createdAt)} />
        <Column field="requestedSampleQuantity" header={t('offers.label-requested-sample-quantity')} />
        <Column field="deliverDeadline" header={t('offers.label-deliver-deadline')} body={(item: SampleRequestResource) => dateTemplate(item.deliverDeadline)} />
        <Column field="receivedAt" header={t('offers.label-sample-received')} body={(item: SampleRequestResource) => dateTemplate(item.receivedAt)} />
        <Column field="receivedSampleQuantity" header={t('offers.label-received-sample-quantity')} />
        <Column field="includesLayoutSample" header={t('offers.label-received-layout-sample')} body={(item: SampleRequestResource) => booleanTemplate(item.includesLayoutSample)} />
      </DataTable>
    </>

  );
}
