import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import { useNavigate } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { Product } from '../../api/_generated_';
import ProductDetailHeader from '../ProductDetailHeader';
import PendingProductChanges from '../PendingProductChanges';
import './ProductDetail.scss';
import BasicProductInfoTab from '../BasicProductInfoTab';
import AppRoutes from '../../constants/routes';
import DolliesProducts from '../DolliesProducts';
import Dollies from '../Dollies';
import { DOLLIES } from '../../constants/pim-families';
import SampleRequests from '../SampleRequests';
import UpcomingChanges from '../UpcomingChanges/UpcomingChanges';
import useProductRenewals from '../../hooks/useProductRenewals';
import RejecetedProductChanges from '../RejecetedProductChanges';
import LockMessage from '../LockMessage';
import useCreateSelectionChange from '../../hooks/useCreateSelectionChange';
import SelectionChanges from '../SelectionChanges';

type Props = {
  product?: Product;
};

function hasLockedAttributes(product: Product) {
  return product.details.map((group) => Object.values(group.values || {}))
    .flat().some((a) => a.lockDuration > 0);
}

export default function ProductDetail({ product }: Props) {
  const { t } = useTranslation();
  const renewals = useProductRenewals(product?.identifier);

  const { mutate: selectionChangeMutation } = useCreateSelectionChange();

  const navigate = useNavigate();

  if (!product) {
    return <div />;
  }

  const isNotDolly = product.family !== DOLLIES;

  const handleRequestSelectionChange = () => {
    confirmDialog({
      header: t('products.label-confirm-selection-change'),
      message: t('products.label-confirm-selection-change-desc', {
        sum: '350,00',
      }),
      acceptLabel: t('common.cmd-confirm'),
      rejectLabel: t('common.cmd-cancel'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => selectionChangeMutation(product.identifier),
    });
  };

  return (
    <>
      {renewals.isSuccess && renewals.data?.length > 0 && (
        <Message
          style={{
            marginBottom: '1rem',
          }}
          severity="warn"
          className="w-full"
          text={t('products.message-pending-renewals')}
        />
      )}

      {hasLockedAttributes(product) && <LockMessage />}

      <ProductDetailHeader product={product} />
      <div className="flex flex-wrap gap-5 mb-6">
        <Button
          label={t('products.cmd-do-product-change')}
          disabled={!product.changesAllowed}
          onClick={() => navigate(`${AppRoutes.PRODUCT_CHANGE}/${product.identifier}`)}
        />
        <Button
          label={t('products.cmd-do-product-change-offer')}
          disabled={!product.changesAllowed}
          onClick={() => navigate(`${AppRoutes.PRODUCT_CHANGE_OFFER}/${product.identifier}`)}
        />
        {isNotDolly && (
          <Button
            label={t('products.title-offer-dolly')}
            severity="secondary"
            disabled={!product.dollyDeliveryAllowed}
            onClick={() => navigate({
              pathname: AppRoutes.NEW_DOLLY,
              search: `?product=${product.identifier}`,
            })}
          />
        )}
        {product.generalSelectionChangeAllowed && (
          <Button
            label={t('products.cmd-do-selection-change')}
            severity="secondary"
            onClick={handleRequestSelectionChange}
          />
        )}
      </div>
      <SelectionChanges />
      <SampleRequests
        items={product?.sampleRequests}
        productName={product?.name}
      />
      {product.family === DOLLIES && (
        <DolliesProducts mainProductId={product.dolliesMainProductId} />
      )}
      <UpcomingChanges product={product} />
      <PendingProductChanges product={product} />
      <RejecetedProductChanges product={product} />
      <TabView>
        <TabPanel header={t('products.title-product-information')}>
          <BasicProductInfoTab product={product} renewals={renewals.data} />
        </TabPanel>
        {product.family !== DOLLIES
          && (
            <TabPanel header={t('products.title-dollies')}>
              <Dollies mainProduct={product} />
            </TabPanel>
          )}
      </TabView>
    </>
  );
}
