import i18n from 'i18next';
import dayjs from 'dayjs';
import { initReactI18next } from 'react-i18next';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { addLocale, updateLocaleOption } from 'primereact/api';
import translationsEn from './locales/en/translations.json';
import translationsFi from './locales/fi/translations.json';

import 'dayjs/locale/fi';
import { LANG_CACHE_KEY } from '../constants/storage';

const localeStorageLang = localStorage.getItem(LANG_CACHE_KEY);

const dayJsLocale = localeStorageLang?.split('-')?.[0] || 'fi';

export const DATE_FORMAT = 'DD.MM.YYYY';

export const DATE_FORMAT_PRIME = 'dd.mm.yy';

dayjs.locale(dayJsLocale);
dayjs.extend(localizedFormat);

addLocale('fi', {
  firstDayOfWeek: 1,
  dayNames: ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'],
  dayNamesShort: ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
  dayNamesMin: ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
  monthNames: ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu'],
  monthNamesShort: ['Tammi', 'Helmi', 'Maalis', 'Huhti', 'Touko', 'Kesä', 'Heinä', 'Elo', 'Syys', 'Loka', 'Marras', 'Joulu'],
  today: 'Tänään',
  clear: 'Tyhjennä',
});

updateLocaleOption('firstDayOfWeek', 1, 'en');

i18n.use(initReactI18next).init({
  fallbackLng: 'fi-FI',
  lng: localeStorageLang || 'fi-FI',
  resources: {
    'en-US': {
      translations: translationsEn,
    },
    'fi-FI': {
      translations: translationsFi,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['fi-FI', 'en-US'];

export default i18n;
