import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  Edit,
  Trash2,
} from 'react-feather';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { Product, RejectedProposal } from '../../api/_generated_';
import { ProductChange } from '../types/types';
import { getValue } from '../../utils/attribute';
import { DATE_FORMAT } from '../../i18n/config';

type Props = {
  product?: Product,
};

export default function RejecetedProductChanges({ product }: Props) {
  const { t } = useTranslation();

  const [activeChange, setActiveChange] = useState<RejectedProposal | null>();

  if (!product?.rejectedProposals?.length) {
    return null;
  }

  const onRowClick = (item: DataTableRowClickEvent) => {
    setActiveChange(item.data as RejectedProposal);
  };

  const formatDate = (date?: string) => {
    if (!date) {
      return 'N/A';
    }

    return dayjs(date).format(DATE_FORMAT);
  };

  const changesTemplate = (item: RejectedProposal) => t('common.label-pieces', {
    count: item.changes?.length,
  });

  const editableTemplate = (item: ProductChange) => {
    const { editable } = item;

    if (editable) {
      return <Edit size={16} color="#B02017" />;
    }

    return null;
  };

  const tableHeader = (
    <div className="flex gap-3">
      <Trash2 size={21} />
      <span className="font-bold">
        {t('products.title-rejected-changes', {
          count: product?.rejectedProposals?.length,
        })}
      </span>
    </div>
  );

  return (
    <>
      <Dialog
        header={t('products.label-attributes')}
        visible={!!activeChange}
        onHide={() => setActiveChange(null)}
        style={{ width: '30vw' }}
        blockScroll
      >
        { activeChange?.changes?.map((change) => (
          <div key={change.attribute} className="mb-2">
            <span className="font-bold">{change.attributeLabel}</span>
            <span className="block">{getValue(change.attributeType, change.newValue, t)}</span>
          </div>
        ))}
      </Dialog>
      <DataTable
        value={product?.rejectedProposals}
        header={tableHeader}
        onRowClick={onRowClick}
        className="link-table mb-5"
        resizableColumns
      >
        <Column header="ID" field="requestId" />
        <Column header={t('products.label-change-targets')} body={changesTemplate} />
        <Column field="processed" header={t('common.label-rejected')} body={(item: RejectedProposal) => formatDate(item.processed)} />
        <Column field="created" header={t('common.label-created')} body={(item: RejectedProposal) => formatDate(item.created)} />
        <Column body={editableTemplate} className="text-center" />
      </DataTable>
    </>
  );
}
