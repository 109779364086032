import { ToggleButton } from 'primereact/togglebutton';
import { useTranslation } from 'react-i18next';
import { DynamicFormFieldProps } from '../../types/types';
import { getDynamicFieldTooltip } from '../../../utils/helper';
import { isAttributeDisabled } from '../../../utils/attribute';

type Props = DynamicFormFieldProps;

export default function ToggleInput({
  attribute,
  field,
}: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <ToggleButton
        checked={field.value}
        disabled={isAttributeDisabled(attribute)}
        onChange={field.onChange}
        onLabel={t('common.label-yes')}
        offLabel={t('common.label-no')}
        onIcon="pi pi-check"
        offIcon="pi pi-times"
        style={{ width: '10em' }}
        {...getDynamicFieldTooltip(attribute)}
      />
    </div>
  );
}
