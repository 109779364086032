import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PRODUCTION_PLANTS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

type Props = {
  onSuccess?(): void;
};

export default function useDeleteProductionPlant({ onSuccess }: Props = {}) {
  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const { toast } = useUIStore();

  const queryClient = useQueryClient();

  async function deleteProductionPlant(id: string): Promise<void> {
    const res = await axios.delete(`/production-plants/${id}`);
    return res.data;
  }

  const onError = () => {
    toast.current?.show({
      severity: 'error',
      summary: t('common.error.message-deletion-failed'),
    });
  };

  return useMutation({
    mutationFn: deleteProductionPlant,
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries([PRODUCTION_PLANTS]);
      onSuccess?.();
    },
  });
}
