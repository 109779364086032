import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card';
import ContactForm from '../../../components/ContactForm';
import ContactList from '../../../components/ContactList';
import useContacts from '../../../hooks/useContacts';
import NetworkContainer from '../../../components/NetworkContainer';
import { HttpRequestStatus } from '../../../components/types/types';

export default function Contacts() {
  const {
    contacts,
    status,
    activeContact,
    isSaving,
    onSaveContact,
    onDeleteContact,
    setPlaceholderContact,
    onSetActiveContact,
    onCancelAddContact,
  } = useContacts();

  const { t } = useTranslation();

  return (
    <Card>
      <h2 className="font-normal text-xxl mb-5">{t('settings.label-contacts')}</h2>
      <p className="mb-5 line-height-4 text-muted">{t('settings.label-contacts-description')}</p>
      <NetworkContainer status={status as HttpRequestStatus} excludeContainers>
        { activeContact
          ? (
            <ContactForm
              isSaving={isSaving}
              contact={activeContact}
              onDelete={onDeleteContact}
              onSubmit={onSaveContact}
              onCancel={onCancelAddContact}
            />
          )
          : (
            <ContactList
              contacts={contacts}
              onAddContact={setPlaceholderContact}
              onEditContact={onSetActiveContact}
            />
          )}
      </NetworkContainer>
    </Card>
  );
}
