import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ProductionPlant } from '../api/_generated_';
import { PRODUCTION_PLANTS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useProductionPlants(props: UseQueryOptions<ProductionPlant[]> = {}) {
  const axios = useAxiosPrivate();

  async function fetchProductionPlants() {
    const res = await axios.get<ProductionPlant[]>('/production-plants');
    return res.data;
  }

  return useQuery({
    queryKey: [PRODUCTION_PLANTS],
    queryFn: fetchProductionPlants,
    ...props,
  });
}
