import './BarcodePrint.scss';

type Props = {
  label?: string;
  additionalInfo?: string;
  src?: string;
  code?: string;
};

export default function BarcodePrint({
  label, additionalInfo, src, code,
}: Props) {
  if (!src) {
    return null;
  }

  return (
    <div className="barcode-print border-2 border-dashed p-3 mb-3">
      { label && (
        <div className="white-space-nowrap overflow-hidden mb-1" style={{ width: '165px' }}>
          <span className="block text-xs">{label}</span>
        </div>
      )}
      <div className="mb-1">
        <img src={src} alt={code} className="block" data-testid="barcode" />
      </div>
      <div className="flex align-items-center justify-content-between">
        <span className="block text-xs">{code}</span>
        { additionalInfo && <span className="block text-xs">{additionalInfo}</span> }
      </div>
    </div>
  );
}
