const AppRoutes = {
  HOME: '/',
  LOGIN: '/login',
  TENDERS: '/tenders',
  OFFERS: '/offers',
  OFFER_NEW: '/offers/new',
  OFFER_TYPE: '/offers/type',
  PRODUCTS: '/products',
  DISTRIBUTOR_CHANGES: '/distributor-changes',
  DISTRIBUTOR_CHANGES_NEW: '/distributor-changes/new',
  PURCHASE_PRICE_REPORT: '/purchase-prices',
  PURCHASE_PRICE_REPORT_NEW: '/purchase-prices/new',
  PRODUCT_CHANGE: '/product-change',
  PRODUCT_CHANGE_OFFER: '/product-change-offer',
  SETTINGS: '/settings',
  NEW_DOLLY: '/dollies/new',
  DOLLY_REQUESTS: '/dolly-requests',
  AFTER_OFFERING_INSTRUCTIONS: '/after-offering-instructions',
  ACCEPT_CODE_OF_CONDUCT: '/accept-code-of-conduct',
  EDU_NOT_COMPLETED: '/edu-not-completed',
  EDU_CONFIRM: '/edu-confirm',
  PRODUCTION_PLANTS: '/production-plants',
  PRODUCTION_PLANTS_NEW: '/production-plants/new',
  MESSAGES: '/messages',
};

export default AppRoutes;
