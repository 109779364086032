import React from 'react';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';
import { DynamicFormFieldProps } from '../../types/types';
import { getDynamicFieldTooltip, getLangByI18nLocale } from '../../../utils/helper';
import { isAttributeDisabled } from '../../../utils/attribute';
import { DATE_FORMAT_PRIME } from '../../../i18n/config';

type Props = DynamicFormFieldProps;

export default function DateInput({
  attribute,
  field,
  fieldState,
}: Props) {
  const { i18n } = useTranslation();
  return (
    <Calendar
      className={classNames({ 'w-full': true, 'p-error': fieldState.error })}
      value={field.value}
      onChange={(e) => field.onChange(e.value)}
      disabled={isAttributeDisabled(attribute)}
      locale={getLangByI18nLocale(i18n.language)}
      dateFormat={DATE_FORMAT_PRIME}
      showIcon
      {...getDynamicFieldTooltip(attribute)}
    />
  );
}
