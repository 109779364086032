import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { MessageRequest, ProductMessage } from '../api/_generated_';
import { PRODUCT_MESSAGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

type Props = {
  productId?: string;
};

export default function useSendProductMessage({
  productId,
}: Props) {
  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  const { toast } = useUIStore();

  async function saveMessage(data: MessageRequest) {
    const res = await axios.post<ProductMessage>(`/product-messages/${productId}`, data);
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  return useMutation({
    mutationFn: saveMessage,
    onSuccess: (result) => {
      queryClient.setQueryData([PRODUCT_MESSAGES, productId], (prev: any) => [result, ...prev]);
    },
    onError,
  });
}
