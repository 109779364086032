import React, { ReactNode } from 'react';
import { composeClasses } from '../../utils/styles';
import './Container.scss';

type Props = {
  size?: 'sm' | 'md' | 'lg';
  children?: ReactNode | ReactNode[];
  className?: string;
};

export default function Container({
  size,
  children,
  className,
  ...rest
}: Props) {
  let sizeClass: string;
  switch (size) {
    case 'sm':
      sizeClass = 'container-sm';
      break;
    case 'lg':
      sizeClass = 'container-lg';
      break;
    default:
      sizeClass = 'container';
  }

  const classNames = composeClasses(sizeClass, className);

  return (
    <div data-testid="container" className={classNames} {...rest}>{children}</div>
  );
}
