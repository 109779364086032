import React, { useRef } from 'react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { OverlayPanel } from 'primereact/overlaypanel';
import useNotifications from '../../hooks/useNotifications';
import NotificationList from '../NotificationList';

export default function NotificationsMenu() {
  const { t } = useTranslation();

  const panel = useRef<OverlayPanel>(null);

  const { notifications, hasNotifications, status } = useNotifications();

  const severity = hasNotifications
    ? 'danger'
    : 'neutral' as any;

  const showPanel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!panel || !panel.current) return;
    panel.current.toggle(e);
  };

  return (
    <Button
      data-testid="notificationMenuButton"
      className="p-button-plain"
      text
      size="small"
      label={t('common.label-notifications')}
      type="button"
      onClick={showPanel}
      aria-haspopup
      aria-controls="overlay_panel"
    >
      <Badge severity={severity} size="normal" />
      <OverlayPanel
        ref={panel}
        id="notifications_panel"
        style={{ width: '320px' }}
        className="text-sm max-h-29rem overflow overflow-auto"
      >
        <NotificationList
          items={notifications}
          status={status}
        />
      </OverlayPanel>
    </Button>
  );
}
