import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UserSettings } from '../api/_generated_';
import { HttpRequestStatus } from '../components/types/types';
import { SETTINGS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

export default function useSettings() {
  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const { toast } = useUIStore();

  async function fetchSettings() {
    const res = await axios.get<UserSettings>('/user-settings');
    return res.data;
  }

  async function saveSettings(settings: UserSettings): Promise<UserSettings> {
    const res = await axios.put<UserSettings>('/user-settings', settings);
    return res.data;
  }

  const queryClient = useQueryClient();

  const { status, data } = useQuery({
    queryKey: [SETTINGS],
    queryFn: fetchSettings,
  });

  const { mutate } = useMutation({
    mutationFn: saveSettings,
    onSuccess: (result) => {
      queryClient.setQueryData([SETTINGS], result);
    },
    onError: () => {
      toast.current?.show({
        severity: 'error',
        summary: t('common.message-saving-failed'),
        detail: t('common.message-saving-failed-description'),
      });
    },
  });

  const onChangeSetting = (group: string, key: string, value: any) => {
    if (!data) {
      return;
    }

    const section = group as keyof UserSettings;

    mutate({
      ...data,
      [group]: {
        ...data[section],
        [key]: value,
      },
    });
  };

  return {
    settings: data,
    status: status as HttpRequestStatus,
    onChangeSetting,
  };
}
