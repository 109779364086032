import { useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Mail } from 'react-feather';
import { TenderListItem } from '../../api/_generated_';
import AppRoutes from '../../constants/routes';
import { HttpRequestStatus } from '../types/types';
import './TenderList.scss';
import NetworkContainer from '../NetworkContainer';
import StatusIndicator from '../StatusIndicator';
import { getIndicatorColor } from '../../utils/styles';
import { DATE_FORMAT } from '../../i18n/config';

type Props = {
  items: TenderListItem[];
  status: HttpRequestStatus;
  filters?: any;
};

export default function TenderList({
  items,
  status,
  filters,
}: Props) {
  const { t } = useTranslation();

  const offeringEndDateTemplate = (item: TenderListItem) => {
    const date = dayjs(item.offeringEndDate);

    return (
      <span className="flex gap-3 align-items-center">
        <StatusIndicator variant={getIndicatorColor(date)} />
        {date?.format(DATE_FORMAT)}
      </span>
    );
  };

  const navigate = useNavigate();
  const onRowClick = useCallback((event: any) => navigate(`${AppRoutes.TENDERS}/${event.data.id}`), [navigate]);

  const messageCountTemplate = (item: TenderListItem) => {
    const { hasMessages } = item;

    return hasMessages ? (
      <Mail size={18} />
    ) : null;
  };

  return (
    <NetworkContainer status={status} excludeContainers>
      <DataTable
        dataKey="id"
        value={items}
        filters={filters}
        onRowClick={onRowClick}
        breakpoint="991px"
        className="mb-3 link-table"
      >
        <Column field="id" header={t('tenders.label-search-identifier')} sortable />
        <Column field="subProductGroup" header={t('products.label-sub-product-group')} sortable />
        <Column field="countries" header={t('common.label-countries')} sortable />
        <Column field="offeringEndDate" dataType="date" header={t('tenders.label-deadline')} body={offeringEndDateTemplate} sortable />
        <Column field="unreadMessages" body={messageCountTemplate} style={{ width: '1rem' }} />
        {/* Hidden fields */}
        <Column field="offerGroup" header="Ryhmä" hidden />
      </DataTable>
    </NetworkContainer>
  );
}
