import { useEffect } from 'react';
import { DrawerState } from '../components/types/types';
import useLayoutStore from '../stores/LayoutStore';

const useDrawer = () => {
  const drawerState = useLayoutStore((state) => state.drawerState);
  const setDrawerState = useLayoutStore((state) => state.setDrawerState);

  useEffect(() => {
    let delay: NodeJS.Timeout;

    function resolveDrawerState(state: DrawerState) {
      if (window.innerWidth > 1024) {
        return state !== DrawerState.CLOSED
          ? state
          : DrawerState.OPEN;
      }

      return DrawerState.CLOSED;
    }

    function handleResize(): void {
      clearTimeout(delay);
      delay = setTimeout(() => {
        setDrawerState(resolveDrawerState(drawerState));
      }, 500);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDrawerState]);

  return {
    drawerState,
  };
};

export default useDrawer;
