import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { SelectionChangeDto } from '../api/_generated_';
import { SELECTION_CHANGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useSelectionChanges() {
  const { id: productId } = useParams();

  const axios = useAxiosPrivate();

  async function request() {
    const res = await axios.get<SelectionChangeDto[]>(`/selection-changes/${productId}`);
    return res.data;
  }

  return useQuery({
    queryKey: [SELECTION_CHANGES, productId],
    queryFn: request,
  });
}
