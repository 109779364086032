import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Product, ProductEOLRequest } from '../api/_generated_';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { PRODUCT } from '../constants/queryKeys';

export default function useProductEndOfLife() {
  const { id } = useParams();

  const axios = useAxiosPrivate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  const queryClient = useQueryClient();

  async function terminateProduct(body: ProductEOLRequest) {
    const res = await axios.put<Product>(`/products/${id}/end-product`, body);
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  return useMutation({
    mutationFn: terminateProduct,
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries([PRODUCT, { id }]);
    },
  });
}
