import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Tag } from 'primereact/tag';
import { Skeleton } from 'primereact/skeleton';
import { SelectionChangeDto } from '../../api/_generated_';
import { formatDateTime } from '../../utils/helper';
import useSelectionChanges from '../../hooks/useSelectionChanges';

export default function SelectionChanges() {
  const { t } = useTranslation();

  const { data, isLoading } = useSelectionChanges();

  const statusTemplate = (item: SelectionChangeDto) => {
    const tag = item.status?.toLowerCase();
    return (
      <Tag
        className={`p-tag-${tag}`}
        value={t(`products.label-selection-change-status-${tag}`)}
      />
    );
  };

  const dateTemplate = (item: SelectionChangeDto) => formatDateTime(item.createdAt);

  if (isLoading) {
    return <Skeleton height="3rem" className="mb-5" width="100%" />;
  }

  if (!data || data.length === 0) {
    return <div />;
  }

  return (
    <DataTable
      className="mb-5"
      resizableColumns
      value={data}
      header={t('products.label-selection-changes') as string}
    >
      <Column
        field="id"
        header="ID"
      />
      <Column
        field="status"
        header={t('common.label-status')}
        body={statusTemplate}
      />
      <Column
        field="created"
        header={t('common.label-created')}
        body={dateTemplate}
      />
    </DataTable>
  );
}
