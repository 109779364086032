import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompareItem } from '../types/types';
import CompareListItem from '../CompareListItem';
import { AttributeStructureGroup, AttributeStructureItemAttributeTypeEnum } from '../../api/_generated_';
import { getDisplayValue } from '../../utils/attribute';

type Props = {
  sections: AttributeStructureGroup[] | undefined;
  changes?: { [key: string]: object; };
  current?: { [key: string]: object; };
};

export default function DifferenceList({
  sections,
  changes = {},
  current = {},
}: Props) {
  const { t } = useTranslation();

  const comparison: CompareItem[] = useMemo(() => sections?.flatMap((section) => section.attributes
    .filter((attribute) => changes[attribute.code])
    .map((attribute) => {
      const key = attribute.code;
      const attributeTranslation = attribute.translation || t(`custom-attributes.${key}`);

      return {
        code: key,
        label: attributeTranslation,
        isFile: attribute.attributeType === AttributeStructureItemAttributeTypeEnum.File
        || attribute.attributeType === AttributeStructureItemAttributeTypeEnum.Image,
        downloadUrl: `/files/${changes[key]}`,
        oldValue: getDisplayValue(current, attribute, t),
        newValue: getDisplayValue(changes, attribute, t),
      };
    })) || [], [changes, current, sections, t]);

  return (
    <div className="mb-5">
      { Object.keys(comparison).length ? comparison.map((item) => (
        <CompareListItem
          key={item.code}
          item={item}
          className="mb-3"
        />
      )) : <span>{t('products.label-no-edited-information')}</span>}
    </div>
  );
}
