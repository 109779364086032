import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card';
import useSupplierSettings from '../../../hooks/useSupplierSettings';
import NetworkContainer from '../../../components/NetworkContainer';
import { HttpRequestStatus } from '../../../components/types/types';
import GeneralSettingsForm from '../../../components/GeneralSettingsForm';
import useSaveSupplierSettings from '../../../hooks/useSaveSupplierSettings';

export default function GeneralSettings() {
  const { t } = useTranslation();

  const { data, status } = useSupplierSettings();

  const { mutate, isLoading: isSaving } = useSaveSupplierSettings();

  return (
    <NetworkContainer status={status as HttpRequestStatus} excludeContainers>
      <Card>
        <h2 className="font-normal text-xxl mb-5">{t('settings.label-general')}</h2>
        <GeneralSettingsForm
          data={data}
          loading={isSaving}
          onSubmit={(formData) => mutate(formData)}
        />
      </Card>
    </NetworkContainer>
  );
}
