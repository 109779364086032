import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dolly } from '../api/_generated_';
import { DOLLIES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useDollies() {
  const { id } = useParams();

  const { i18n } = useTranslation();

  const axios = useAxiosPrivate();

  async function fetchDollies() {
    const res = await axios.get<Dolly[]>(`/products/${id}/dollies?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: [DOLLIES, id],
    queryFn: fetchDollies,
    staleTime: Infinity,
  });
}
