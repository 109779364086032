import React from 'react';
import { Box, ShoppingCart, Truck } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { OfferTargetEnum } from '../../api/_generated_';
import IconBlock from '../IconBlock';

type Props = {
  target?: OfferTargetEnum;
};

export default function OfferTargetBlock({
  target,
}: Props) {
  const { t } = useTranslation();

  if (target === OfferTargetEnum.CentralWarehouse) {
    return (
      <IconBlock
        icon={<Box />}
        label={t('offers.label-to-central-warehouse')}
      />
    );
  }

  if (target === OfferTargetEnum.ImportService) {
    return (
      <IconBlock
        icon={<Truck />}
        label={t('offers.label-to-import-service')}
      />
    );
  }

  if (target === OfferTargetEnum.Store) {
    return (
      <IconBlock
        icon={<ShoppingCart />}
        label={t('offers.label-to-store')}
      />
    );
  }

  return null;
}
