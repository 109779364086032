import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import ViewHeader from '../../components/ViewHeader';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import useProductChangeTemplate from '../../hooks/useProductChangeTemplate';
import ProductChangeEditor from '../../components/ProductChangeEditor';
import useCreateProductChange from '../../hooks/useCreateProductChange';
import AppRoutes from '../../constants/routes';
import { ProductChangeRequest } from '../../api/_generated_';
import { PRODUCT_CHANGE_TEMPLATE } from '../../constants/queryKeys';

export default function CreateProductChange() {
  const { id } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data, status } = useProductChangeTemplate();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateProductChange({
    onSuccess: async () => {
      await queryClient.invalidateQueries([PRODUCT_CHANGE_TEMPLATE]);
      navigate(`${AppRoutes.PRODUCTS}/${id}`);
    },
  });

  const handleSendForApproval = async (changes: ProductChangeRequest) => {
    if (!changes) return;

    mutate(changes);
  };

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <ViewHeader
        title={t('products.label-create-product-changes')}
        description={t('products.label-create-product-changes-description')}
      />
      <p className="line-height-4 mb-4">{t('products.label-product-change-instructions')}</p>
      <ProductChangeEditor
        productId={id}
        data={data}
        onSubmit={handleSendForApproval}
        isLoading={isLoading}
      />
    </NetworkContainer>
  );
}
