import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayPanel } from 'primereact/overlaypanel';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { AlertCircle } from 'react-feather';
import useSupplier from '../../hooks/useSupplier';
import SupplierList from '../SupplierList';
import { SupplierDto } from '../../api/_generated_';

export default function SupplierButton() {
  const { t } = useTranslation();

  const panel = useRef<OverlayPanel>(null);

  const { supplier: selectedSupplier, setSupplier: setSelectedSupplier } = useSupplier();

  const showPanel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!panel || !panel.current) return;
    panel.current.show(e, null);
  };

  const handleChange = (supplier: SupplierDto | null) => {
    if (supplier?.code === selectedSupplier?.externalId) {
      panel?.current?.hide();
      return;
    }

    confirmDialog({
      header: t('supplier.change_title'),
      message: t('supplier.change_message'),
      icon: 'pi pi-exclamation-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => {
        setSelectedSupplier(supplier?.code || '');
      },
    });
  };

  function getIcon() {
    if (selectedSupplier?.selfOperated) {
      return null;
    }
    return (
      <AlertCircle size={20} style={{ marginRight: '0.3rem' }} color="red" />
    );
  }

  return (
    <Button
      onClick={showPanel}
      className="p-button-plain"
      icon={getIcon()}
      text
      size="small"
      label={selectedSupplier?.name || t('supplier.empty')}
    >
      <OverlayPanel
        ref={panel}
        style={{ width: '320px' }}
        className="text-sm max-h-29rem overflow overflow-auto"
      >
        <SupplierList onChange={handleChange} />
      </OverlayPanel>
    </Button>
  );
}
