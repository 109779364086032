/* eslint-disable jsx-a11y/label-has-associated-control */
import { Plus } from 'react-feather';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CTACard from '../CTACard';
import useAuthorizations from '../../hooks/useAuthorizations';
import '../CTACard/CTACard.scss';
import AuthorizationForm from '../AuthorizationForm';
import { DynamicFormFieldProps } from '../types/types';
import AuthorizationCard from '../AuthorizationCard';
import './AuhtorizationDetails.scss';

type Props = DynamicFormFieldProps;

export default function AuhtorizationDetails({
  field,
}: Props) {
  const { t } = useTranslation();
  const [displayForm, setDisplayForm] = useState(false);

  const {
    authorizations,
    activeAuthorization,
    onSetActiveAuthorization,
    onSaveAuthorization,
    onCancelAddAutorization,
    setPlaceholderAuthorization,
  } = useAuthorizations();

  const handleAddAuthorization = (data: any) => {
    setDisplayForm(false);
    onSaveAuthorization(data);
    setPlaceholderAuthorization();
  };

  const handleCancelAddAutorization = () => {
    setDisplayForm(false);
    onCancelAddAutorization();
  };

  return (
    <div>
      <Dialog
        header={activeAuthorization?.id ? t('settings.title-authorization') : t('settings.title-new-authorization')}
        visible={displayForm}
        modal
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        style={{ width: '50vw' }}
        onHide={handleCancelAddAutorization}
      >
        <AuthorizationForm
          authorization={activeAuthorization}
          isSaving={false}
          onCancel={handleCancelAddAutorization}
          onSubmit={handleAddAuthorization}
          onDelete={() => { }}
        />
      </Dialog>
      <div className="authorization-details" id="authorizations">
        <div>
          <CTACard
            icon={<Plus size={30} />}
            label={t('settings.cmd-add-authorization')}
            active={false}
            onClick={() => { setDisplayForm(true); }}
            error={false}
            className="add-authorization-button"
          />
        </div>
        {authorizations?.map((authorization) => (
          <AuthorizationCard
            key={authorization.id}
            active={authorization.id === field.value}
            authorization={authorization}
            onClick={() => {
              field.onChange(authorization.id);
            }}
            onClickDetails={() => {
              onSetActiveAuthorization(authorization);
              setDisplayForm(true);
            }}
          />
        ))}
      </div>
    </div>
  );
}
