import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Offer, TenderFrame } from '../api/_generated_';
import { OFFER_FRAME } from '../constants/queryKeys';

type Props = {
  tender?: TenderFrame;
  offer?: Offer | undefined;
};

export default function useOfferNavigation({
  tender,
  offer,
}: Props) {
  const queryClient = useQueryClient();

  const stages = useMemo(() => tender?.sections?.map((section) => section.code) || [], [tender]);

  const currentStage = useMemo(() => {
    const stage = tender?.sections?.find((section) => section.code === offer?.stage);
    const attributes = stage?.attributes;
    return {
      ...stage,
      attributes,
    };
  }, [offer, tender]);

  const stageIndex = stages.findIndex((item) => item === currentStage?.code);

  const onGoBack = useCallback(
    () => {
      const prevStage = stageIndex === -1
        ? stages[stages.length - 1]
        : stages[stageIndex - 1];

      queryClient.setQueryData([OFFER_FRAME, offer?.uuid], (prev: any) => ({
        ...prev,
        offer: {
          ...prev.offer,
          stage: prevStage,
        },
      }));
    },
    [offer, stages, queryClient, stageIndex],
  );

  return {
    stages,
    currentStage,
    stageIndex,
    onGoBack,
  };
}
