import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Tender } from '../api/_generated_';
import { HttpRequestStatus, Selection } from '../components/types/types';
import { TENDER } from '../constants/queryKeys';
import AppRoutes from '../constants/routes';
import useAxiosPrivate from './useAxiosPrivate';

export default function useTender() {
  const { id: tenderId } = useParams();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  async function fetchTender() {
    const result = await axios.get<Tender>(`/tender/${tenderId}?locale=${i18n.language}`);
    return result.data;
  }

  const { data, status } = useQuery({
    queryKey: [TENDER, tenderId],
    queryFn: fetchTender,
  });

  const onOffer = () => {
    navigate({
      pathname: AppRoutes.OFFER_NEW,
      search: `?tender=${tenderId}&selection=${Selection.GeneralSelection}`,
    });
  };

  return {
    tender: data,
    status: status as HttpRequestStatus,
    onOffer,
  };
}
