import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../msalConfig';

const useADLogin = () => {
  const { instance, inProgress } = useMsal();

  async function login() {
    await instance.loginRedirect(loginRequest);
  }

  const logout = async () => {
    try {
      await instance.logoutRedirect();
    } catch (ex) {
      await instance.logoutPopup();
    }
  };

  return {
    login,
    logout,
    inProgress,
  };
};

export default useADLogin;
