import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import useRequestDistributorChange from '../../hooks/useDistributorChange';
import useDistributorDeadline from '../../hooks/useDistributorDeadline';
import Container from '../../components/Container';
import ViewHeader from '../../components/ViewHeader';
import useDepositOptions from '../../hooks/useDepositOptions';
import AppRoutes from '../../constants/routes';
import { DISTRIBUTOR_CHANGES } from '../../constants/queryKeys';
import useDistributorFormDynamicOptions from '../../hooks/useDistributorFormDynamicOptions';
import { DistributorChangeForm, Form } from '../../components/DistributorChangeForm';
import { DATE_FORMAT } from '../../i18n/config';

export default function CreateDistributorChange() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { status: deadlineStatus, data } = useDistributorDeadline();

  const { mutate, isLoading } = useRequestDistributorChange({
    onSuccess: async () => {
      await queryClient.invalidateQueries([DISTRIBUTOR_CHANGES]);
      navigate(AppRoutes.DISTRIBUTOR_CHANGES);
    },
  });

  const {
    data: depositOptions,
    status: depositOptionsStatus,
  } = useDepositOptions();

  const {
    data: dynamicFields,
    status: dynamicFieldsStatus,
  } = useDistributorFormDynamicOptions();

  const deadline = dayjs(data?.deadline);
  const deadlinePassed = (!data?.deadline || deadline.isBefore(dayjs(), 'day'));

  const statuses = [
    deadlineStatus,
    depositOptionsStatus,
    dynamicFieldsStatus,
  ] as HttpRequestStatus[];

  const onSubmit = (formData: Form) => {
    if (!formData.product?.identifier) {
      return;
    }

    const dataCopy = {
      ...formData,
      effective: formData.effective
        ? dayjs(formData.effective).format('YYYY-MM-DD')
        : '',
      productNumber: formData.product.identifier,
    };

    mutate(dataCopy);
  };

  return (
    <Container size="sm">
      <ViewHeader
        title={t('products.title-change-distributor')}
        description={t('products.message-distributor-information')}
      />
      <NetworkContainer excludeContainers status={statuses}>
        { deadlinePassed ? (
          <div className="deadline-passed-error">
            <p>{t('products.message-error-deadline-passed')}</p>
          </div>
        ) : (
          <>
            <Header
              period={data?.period}
              deadline={deadline}
            />
            <DistributorChangeForm
              depositOptions={depositOptions || []}
              isLoading={isLoading}
              dynamicFields={dynamicFields || []}
              onSubmit={onSubmit}
            />
          </>
        )}
      </NetworkContainer>
    </Container>
  );
}

type HeaderProps = {
  period?: string;
  deadline?: Dayjs;
};

function Header({ period, deadline }: HeaderProps) {
  const { t } = useTranslation();
  return (
    <div data-testid="purchase-price-header" className="flex flex-wrap column-gap-8 row-gap-5 mb-6">
      <div>
        <span className="block font-bold">{t('products.title-alternative-purchase-price-header')}</span>
        <span data-testid="pricing-period">{period || '-'}</span>
      </div>
      <div>
        <span className="block font-bold">{t('purchase-prices.label-report-deadline')}</span>
        <span data-testid="report-deadline">{deadline?.format(DATE_FORMAT) || '-'}</span>
      </div>
      <div>
        <span className="block font-bold">{t('purchase-prices.label-changes-effective-since')}</span>
        <span data-testid="report-deadline">{deadline?.add(1, 'day').format(DATE_FORMAT) || '-'}</span>
      </div>
    </div>
  );
}
