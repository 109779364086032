import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import AppRoutes from '../constants/routes';
import useAxiosPrivate from './useAxiosPrivate';
import { OFFER_LIST } from '../constants/queryKeys';

export default function useCancelOffer() {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function onCancelOffer(offerId?: string) {
    const res = await axios.post(`/offers/${offerId}/cancel`);
    return res.data;
  }

  return useMutation(onCancelOffer, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([OFFER_LIST]);
      navigate(AppRoutes.OFFERS);
    },
  });
}
