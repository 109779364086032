import { DataTableFilterMeta } from 'primereact/datatable';
import {
  Control, ControllerFieldState, ControllerRenderProps, RegisterOptions, UseFormWatch,
} from 'react-hook-form';
import { AttributeStructureItem } from '../../api/_generated_';

/* eslint-disable import/prefer-default-export */
export enum DrawerState {
  OPEN = 'OPEN',
  COLLAPSED = 'COLLAPSED',
  CLOSED = 'CLOSED',
}

export interface ResultPage {
  pageSize: number;
  first?: number;
  last?: number;
  totalRecords: number;
  currentPage: number;
  totalPages: number;
}

export interface TableState {
  first: number;
  rows: number;
  page: number;
  sortField?: string | null;
  sortOrder?: number | null;
  filters?: DataTableFilterMeta;
}

export interface Sort {
  field: string;
  order: 1 | 0 | -1 | null | undefined;
}

export type DynamicFormFieldProps = {
  attribute: AttributeStructureItem;
  error?: string;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  watch?: UseFormWatch<any>;
};

export type CompareItem = {
  code: string;
  label: string;
  isFile: boolean;
  downloadUrl: string;
  oldValue: string | null | undefined;
  newValue: string | null | undefined;
};

export type ProductChange = {
  requestId: string | undefined;
  created: string | undefined;
  effective: string | undefined;
  supplier: string | undefined;
  user: string | undefined;
  editable: boolean;
  changes: ProductChangeRow[];
};

export type ProductChangeRow = {
  code: string | undefined;
  label: string | undefined;
  newValue: string | undefined | object;
  oldValue: string | undefined | object;
};

export type StaticFormFieldProps = {
  label?: string;
  rules?: Omit<RegisterOptions<any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  control: Control<any, any>;
  watch?: UseFormWatch<any>;
  help?: string;
  callback?: (value: any) => void;
};

export type PurchasePriceFilterProps = {
  beverageStrength?: BeverageStrength;
  search?: string;
};

export enum StatusColor {
  EXPIRED = 'muted',
  EXPIRING_SOON = 'error',
  EXPIRING = 'warning',
  AVAILABLE = 'success',
}

export enum HttpRequestStatus {
  IDLE = 'idle',
  ERROR = 'error',
  LOADING = 'loading',
  SUCCESS = 'success',
}

export type ApiError = {
  error?: string;
  message?: string;
  path?: string;
  status?: number;
  timestamp?: number;
};

export enum BeverageStrength {
  STRONG = 'STRONG',
  MILD = 'MILD',
}

export type ValidationError = {
  message: string;
};

export enum Selection {
  GeneralSelection = '1',
  SaleToOrderSelection = '2',
  SpecialOrder = '3',
  Accessory = '4',
  ChangeOffer = '6',
}

export type AllowedValues = {
  filter?: AttributeFilter
  values?: string[]
};

export type AttributeFilter = {
  attributeCode?: string,
  allowedValues: { [key: string]: AllowedValues; };
};

export type AttributeFilterConfig = {
  productGroups?: string[];
  filter?: AttributeFilter;
  maxSelections?: number;
};

export type FilterConfig = { [key: string]: AttributeFilterConfig; };

export type CustomErrorResponseData = {
  errors?: CustomApiError[];
};

export type CustomApiError = {
  errorCode: string;
  entityId: string;
};
