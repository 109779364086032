import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { TENDER_LIST } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import { TenderListFrame } from '../api/_generated_';

const MULTISELECT_MATCHER = 'multi-select-matcher';

const INITIAL_FILTERS = {
  global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  offerGroup: { value: '', matchMode: FilterMatchMode.EQUALS },
  productGroup: { value: '', matchMode: MULTISELECT_MATCHER },
  subProductGroup: { value: '', matchMode: FilterMatchMode.EQUALS },
  countries: { value: '', matchMode: MULTISELECT_MATCHER },
  offeringEndDate: {
    operator: FilterOperator.AND,
    constraints: [
      { value: null, matchMode: FilterMatchMode.DATE_AFTER },
      { value: null, matchMode: FilterMatchMode.DATE_BEFORE },
    ],
  },
};

FilterService.register(MULTISELECT_MATCHER, (value, filter) => {
  if (filter.length === 0) {
    return true;
  }
  const values = value ? value.split(',').map((v: string) => v.trim()) : [];
  return values.filter((v: string) => filter.includes(v)).length > 0;
});

export default function useTenders() {
  const axios = useAxiosPrivate();
  const { i18n } = useTranslation();

  const [filters, setFilters] = useState<any>(_.cloneDeep(INITIAL_FILTERS));

  async function getTenders(): Promise<TenderListFrame> {
    const res = await axios.get<TenderListFrame>(`/tender/list-frame?locale=${i18n.language}`);
    const { data } = res;

    data.tenders = data.tenders?.map(((item: any) => ({
      ...item,
      offeringEndDate: new Date(item.offeringEndDate),
    })));

    return data;
  }

  const {
    data, status,
  } = useQuery([TENDER_LIST], getTenders);

  const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const filtersCopy = { ...filters };
    filtersCopy[name].value = value;

    setFilters(filtersCopy);
  };

  const handleClearFilter = () => {
    setFilters(JSON.parse(JSON.stringify(INITIAL_FILTERS)));
  };

  const onChangeDeadlineFilter = (e: any) => {
    const { value, name } = e.target;
    const filtersCopy = { ...filters };

    const index = name === 'deadlineAtleast' ? 0 : 1;
    filtersCopy.offeringEndDate.constraints[index].value = value;

    setFilters(filtersCopy);
  };

  return {
    status,
    data,
    filters,
    deadlineStart: filters.offeringEndDate.constraints[0].value,
    deadlineEnd: filters.offeringEndDate.constraints[1].value,
    onChangeDeadlineFilter,
    onChangeFilter,
    handleClearFilter,
  };
}
