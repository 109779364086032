import { useState } from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { Sidebar } from 'primereact/sidebar';
import { Dialog } from 'primereact/dialog';
import { Mail } from 'react-feather';
import IconBlock from '../IconBlock';
import { ReactComponent as DrinkIcon } from '../../assets/images/drink.svg';
import { Product, ProductEOLRequest } from '../../api/_generated_';
import ThumbailPlaceholder from '../../assets/images/thumbnail_placeholder.jpg';
import './ProductDetailHeader.scss';
import AppRoutes from '../../constants/routes';
import ProductHistory from '../ProductHistory';
import useProductEndOfLife from '../../hooks/useProductEndOfLife';
import ProductEOLForm from '../ProductEOLForm';
import { formatDate } from '../../utils/helper';

type Props = {
  product: Product;
};

export default function ProductDetailHeader({
  product,
}: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { mutate: terminateProduct } = useProductEndOfLife();

  const [showEOLDialog, setShowEOLDialog] = useState(false);

  const [isHistoryVisible, setIsHistoryVisible] = useState(false);

  const onCheckMessages = () => {
    navigate(`${AppRoutes.PRODUCTS}/${product.identifier}/messages`);
  };

  const setProductToEOL = (request: ProductEOLRequest) => {
    terminateProduct(request);
    setShowEOLDialog(false);
  };

  return (
    <>
      <Sidebar
        position="right"
        visible={isHistoryVisible}
        onHide={() => setIsHistoryVisible(false)}
        className="w-full lg:w-30rem"
      >
        <ProductHistory product={product} />
      </Sidebar>
      <div className="flex flex-wrap gap-5 md:justify-content-between align-items-start mb-5">
        <div className="flex flex-wrap gap-5">
          <img
            src={product.thumbnail ? product.thumbnail : ThumbailPlaceholder}
            alt={product.identifier}
            width="120px"
            className="product-image"
          />
          <div>
            <h1 className="mb-4 text-2xl">{`${product.identifier} - ${product.name}`}</h1>
            <div className="flex flex-wrap gap-4 md:gap-6 mb-4">
              <div className="flex flex-row align-items-center gap-3">
                {product.groupName && <IconBlock label={product.groupName} icon={<DrinkIcon />} />}
              </div>
            </div>
            <span className="text-muted">
              {`${t('common.label-last-modified')} ${formatDate(product.lastModifiedAt)}`}
            </span>
          </div>
        </div>
        <div className="flex gap-3">
          <Button
            className="p-overlay-badge"
            severity="secondary"
            tooltip={t('common.label-messages')}
            tooltipOptions={{ position: 'bottom' }}
            onClick={onCheckMessages}
          >
            {product.unreadMessages ? <Badge value={product.unreadMessages} severity="danger" /> : null}
            <Mail size={20} />
          </Button>
          <Button
            icon="pi pi-history"
            iconPos="left"
            severity="secondary"
            tooltip={t('common.label-history')}
            tooltipOptions={{ position: 'bottom' }}
            onClick={() => setIsHistoryVisible(true)}
          />
          <Button
            icon="pi pi-trash"
            iconPos="left"
            severity="secondary"
            tooltip={t('products.cmd-end-product-life')}
            tooltipOptions={{ position: 'bottom' }}
            disabled={!product.changesAllowed}
            onClick={() => setShowEOLDialog(true)}
          />
        </div>
      </div>
      <Dialog
        header={t('products.cmd-end-product-life')}
        visible={showEOLDialog}
        onHide={() => setShowEOLDialog(false)}
        style={{ width: '30vw' }}
        blockScroll
      >
        <ProductEOLForm
          onSubmit={(data: ProductEOLRequest) => setProductToEOL(data)}
          onClose={() => setShowEOLDialog(false)}
        />
      </Dialog>
    </>
  );
}
