import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

export default function usePrinting() {
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);

  const { t } = useTranslation();

  const promiseResolveRef = useRef<any>(null);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: t('offers.label-sample-requests'),
    onBeforeGetContent: () => new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setIsPrinting(true);
    }),
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  return {
    printRef,
    isPrinting,
    handlePrint,
  };
}
