/* eslint-disable max-len */
export default function RecipientAddress() {
  return (
    <div className="line-height-2 text-xs">
      <p>Kiitämme tarjouksestanne ja pyydämme toimittamaan meille näytteet osoitteeseemme:</p>

      <p>
        <strong>ALKO OY/Posti Group</strong>
        <br />
        Tikkurilantie 148
        <br />
        Portti 1, ovet D 18-21
        <br />
        01530 VANTAA
      </p>

      <p>
        toimituslausekkeella
        {' '}
        <strong>DDP Alkon keskusvarasto</strong>
        {' '}
        verot maksettuina. Näytteet voi tuoda Alkon keskusvarastoon työpäivinä klo 9-15 välillä. Kaikki näytteet (myös mahdollinen ulkoasunäyte) tulee toimittaa samassa lähetyksessä.
      </p>

      <p>
        Mikäli näytelähetykseen tarvitaan Alkon sähköpostiosoite, käytättehän osoitetta
        {' '}
        <a href="mailto:sample.delivery@alko.fi">sample.delivery@alko.fi</a>
        .
      </p>

      <p>
        Kiinnittäkää yllä olevat tarjousviivakoodit näytteiden taakse. Ainoastaan näytteet, joissa on Alkon tarjousnumero, hyväksytään arviointiin.
      </p>

      <p>
        Näytteiden vastaanotto kommunikoidaan kumppaniverkossa näytteen statuksella kolmen työpäivän sisällä näytteen toimituksesta. Jos ette saa vahvistusta, voitte olla yhteydessä laadunvalvontaan osoitteessa
        {' '}
        <a href="mailto:laatupalaute@alko.fi">laatupalaute@alko.fi</a>
        .
      </p>

      <p>***************************************************</p>

      <p>We thank you for your offer and ask you to send samples delivered to our address:</p>

      <p>
        <strong>ALKO INC./Posti Group</strong>
        <br />
        Tikkurilantie 148
        <br />
        Gate 1, doors D 18-21
        <br />
        FI-01530 VANTAA, FINLAND
      </p>

      <p>
        on
        {' '}
        <strong>DDP Alko central warehouse Vantaa</strong>
        {' '}
        delivery terms. The time for bringing the samples to Alko's central warehouse is between 9.00 in the morning and 15.00 in the evening on working days. All the samples (including possible packaging mock-up sample) must be delivered in the same shipment.
      </p>

      <p>
        If you require an e-mail address in order to ship your samples, please use the address
        {' '}
        <a href="mailto:sample.delivery@alko.fi">sample.delivery@alko.fi</a>
        .
      </p>

      <p>
        Please attach the offer bar codes provided in this document to the reverse side of the samples. Only samples marked with Alko’s reference number (attached bar codes) can be registered for evaluation.
      </p>

      <p>
        Status regarding sample arrival is communicated in Partner Network sample status. In case sample status is not updated within three working days from the sample delivery, please contact the quality control by email at
        {' '}
        <a href="mailto:laatupalaute@alko.fi">laatupalaute@alko.fi</a>
        .
      </p>
    </div>
  );
}
