import { AxiosError } from 'axios';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Offer, SaveOfferRequest, Tender, ValidationErrors,
} from '../api/_generated_';
import { CHANGE_OFFER_TENDER_ID, DEFAULT_TENDER_ID } from '../constants/pim-attributes';
import { OFFER_FRAME, TENDER_DETAIL } from '../constants/queryKeys';
import AppRoutes from '../constants/routes';
import { getToastError } from '../utils/error';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import useOfferFrame from './useOfferFrame';
import { AttributeConfigContext } from '../utils/AttributeConfigProvider';

export default function useFillOffer(onValidationError?: (data: ValidationErrors) => void) {
  const { t } = useTranslation();
  const { id: uuid } = useParams();
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const axios = useAxiosPrivate();

  const { toast } = useUIStore();

  const { isAttributeVisible } = useContext(AttributeConfigContext);

  const queryClient = useQueryClient();

  async function fetchTenderDetail({ queryKey }: any) {
    const tenderId = queryKey[1];
    const res = await axios.get<Tender>(`/tender/${tenderId}?locale=${i18n.language}`);
    return res.data;
  }

  async function saveOffer(body: SaveOfferRequest) {
    const res = await axios.put<Offer>(`/offers/${uuid}`, body);
    return res.data;
  }

  async function sendOffer() {
    await axios.put<Offer>(`/offers/${uuid}/send`);
  }

  async function deleteOffer() {
    const res = await axios.delete(`/offers/${uuid}`);
    return res.data;
  }

  const onError = (e: AxiosError<any, any>) => {
    if (e.code === 'ERR_BAD_REQUEST' && e.response?.data?.errors && onValidationError) {
      onValidationError(e.response?.data);
    } else {
      const message = getToastError(e, t);
      toast.current?.show(message);
    }
  };

  const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: saveOffer,
    onSuccess: (result) => {
      queryClient.setQueryData([OFFER_FRAME, uuid], (oldData: any) => {
        const newData = {
          ...oldData,
          offer: result,
        };
        return newData;
      });
    },
    onError,
  });

  const { mutate: sendOfferMutation, isLoading: isSendingOffer } = useMutation({
    mutationFn: sendOffer,
    onSuccess: () => navigate(`${AppRoutes.AFTER_OFFERING_INSTRUCTIONS}/${uuid}`),
    onError,
  });

  const { data: offerResult, status } = useOfferFrame();

  const tenderId = useMemo(() => offerResult?.tender?.identifier, [offerResult]);

  const isOpenOffer = (!tenderId
    || tenderId === DEFAULT_TENDER_ID
    || tenderId === CHANGE_OFFER_TENDER_ID);

  const { data: tenderDetail, status: tenderStatus } = useQuery(
    [TENDER_DETAIL, tenderId],
    fetchTenderDetail,
    {
      enabled: !isOpenOffer,
    },
  );

  const { mutate: onDeleteOffer, isLoading: isDeleting } = useMutation({
    mutationFn: deleteOffer,
    onSuccess: () => navigate(AppRoutes.OFFERS),
  });

  const onSubmit = (formData: { [key: string]: any }) => {
    const productionPlants = [...formData.productionPlants];

    const filteredData = Object.keys(formData).reduce((acc, key) => {
      if (isAttributeVisible(key, formData)) {
        acc[key] = formData[key];
      }
      return acc;
    }, {} as { [key: string]: any });

    delete filteredData.productionPlants;

    const body: SaveOfferRequest = {
      data: filteredData,
      productionPlantIds: productionPlants,
      stage: offerResult?.offer?.stage,
    };

    mutate(body);
  };

  const onSendOffer = () => {
    sendOfferMutation();
  };

  return {
    offer: offerResult?.offer,
    tender: offerResult?.tender,
    isEditingAllowed: offerResult?.isEditingAllowed,
    priceCalculationSection: offerResult?.priceCalculationSection,
    ethylMildLimit: offerResult?.ethylMildLimit,
    mildAlcoholLimit: offerResult?.mildAlcoholLimit,
    tenderDetail,
    showSideBar: !isOpenOffer,
    tenderStatus,
    isSaving: isSaving || isSendingOffer || isDeleting,
    status,
    onSubmit,
    onSendOffer,
    onDeleteOffer,
  };
}
