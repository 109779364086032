import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Trash } from 'react-feather';
import useDollyRequest from '../../hooks/useDollyRequest';
import { HttpRequestStatus } from '../../components/types/types';
import NetworkContainer from '../../components/NetworkContainer';
import ViewHeader from '../../components/ViewHeader';
import DataRow from '../../components/DataRow';
import { canDeleteDollyRequest } from '../../utils/helper';
import useDeleteDollyRequest from '../../hooks/useDeleteDollyRequest';
import { DATE_FORMAT } from '../../i18n/config';

export default function DollyRequest() {
  const { t } = useTranslation();

  const { id } = useParams();

  const { data, status, isSuccess } = useDollyRequest(id);

  const { mutate: deleteDollyRequest } = useDeleteDollyRequest({
    uuid: id,
  });

  const onConfirmDelete = () => {
    confirmDialog({
      header: t('common.title-confirm-deletion'),
      message: t('common.label-confirm-deletion-description'),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => deleteDollyRequest(),
    });
  };

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <ViewHeader
        title={t('products.label-dolly-request-details')}
      >
        {canDeleteDollyRequest(data) && (
          <Button
            severity="secondary"
            tooltip={t('common.cmd-delete')}
            tooltipOptions={{ position: 'bottom' }}
            onClick={onConfirmDelete}
            size="small"
          >
            <Trash size={20} />
          </Button>
        )}
      </ViewHeader>
      { isSuccess && (
        <div className="flex flex-column gap-4">
          <DataRow
            label={t('common.label-status')}
            value={t(`products.label-dolly-req-status-${data.status?.toLowerCase()}`)}
          />
          <DataRow
            label={t('products.label-product-id')}
            value={data.product?.identifier}
          />
          <DataRow
            label={t('products.label-product-name')}
            value={data.product?.name}
          />
          <DataRow
            label={t('products.label-dolly-type')}
            value={data.type?.label || data.type?.value}
          />
          <DataRow
            label={t('products.label-sale-units-per-palette')}
            value={String(data.saleUnitsPerPalette)}
          />
          <DataRow
            label={t('settings.label-contact')}
            value={`${data.contact?.firstName} ${data.contact?.lastName}`}
          />
          <DataRow
            label={t('common.label-created')}
            value={dayjs(data.createdAt).format(DATE_FORMAT)}
          />
        </div>
      )}

    </NetworkContainer>
  );
}
