import { useState } from 'react';
import {
  Controller, ControllerRenderProps, SubmitHandler, useForm,
} from 'react-hook-form';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import {
  AttributeStructureItem, DistributorChangeRequest, FilterItem, SearchProduct,
} from '../../api/_generated_';
import { getAttributeValue } from '../../utils/attribute';
import NumberField from '../StaticFields/NumberField';
import SelectField from '../StaticFields/SelectField';
import CalendarField from '../StaticFields/CalendarField';
import CheckField from '../StaticFields/CheckField';
import UploadField from '../StaticFields/UploadField';
import DynamicField from '../DynamicField';
import ProductionPlants from '../DynamicField/ProductionPlants';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export type Form = DistributorChangeRequest & {
  product: SearchProduct | null;
  userAgreement: boolean;
};

type Props = {
  depositOptions: FilterItem[];
  isLoading: boolean;
  dynamicFields: AttributeStructureItem[];
  onSubmit: SubmitHandler<Form>;
};

export function DistributorChangeForm({
  depositOptions, isLoading, dynamicFields, onSubmit,
}: Props) {
  const { t, i18n } = useTranslation();

  const axios = useAxiosPrivate();

  const [products, setProducts] = useState<SearchProduct[]>([]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = useForm<Form>({
    defaultValues: {
      product: null,
      depositCode: '',
      purchasePrice: undefined,
      effective: '',
      authorization: '',
      authorizationCancellation: '',
      movesToImports: false,
      userAgreement: false,
      productionPlantIds: [],
      dynamicValues: dynamicFields.reduce((prev, item) => ({
        ...prev,
        [item.code]: getAttributeValue(item, undefined),
      }), {}),
    },
  });

  const searchProducts = async (sku: string): Promise<SearchProduct[]> => {
    const res = await axios.get(`/products/search?locale=${i18n.language}&sku=${sku}&ignoreOwn=true`);
    return res.data;
  };

  const onCompleteSearch = async (result: AutoCompleteCompleteEvent) => {
    try {
      const searchData = await searchProducts(result.query);
      const mappedData = searchData.map((item) => ({
        ...item,
        name: `${item.identifier} - ${item.name}`,
      }));
      setProducts(mappedData);
    } catch (error) {
      setProducts([]);
    }
  };

  const onChangeProduct = async (e: AutoCompleteChangeEvent, field: ControllerRenderProps<Form, 'product'>) => {
    const item = e.value;

    field.onChange(item);
    setValue('depositCode', (item?.depositCode || getValues('depositCode')));
  };

  const itemTemplate = (item: SearchProduct) => (
    <span>
      {item.identifier}
      {' '}
      -
      {' '}
      {item.name}
    </span>
  );

  const watchAgreement = watch('userAgreement', false);

  const watchMovesToImports = watch('movesToImports', false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="product"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <div className="field">
            <label htmlFor="productSearch" className={classNames({ 'p-error': fieldState.error })}>
              {t('products.label-product-number')}
            </label>
            <AutoComplete
              id={field.name}
              inputId={field.name}
              value={field.value}
              inputRef={field.ref}
              suggestions={products}
              minLength={4}
              itemTemplate={itemTemplate}
              showEmptyMessage
              field="name"
              emptyMessage={t('products.label-no-products-found')}
              completeMethod={onCompleteSearch}
              onChange={(e) => onChangeProduct(e, field)}
              style={{ width: '100%' }}
            />
            <small>
              {t('products.label-search-products-help')}
            </small>
          </div>
        )}
      />
      <NumberField
        name="purchasePrice"
        rules={{ required: true }}
        label={t('purchase-prices.label-purchase-price')}
        control={control}
        mode="currency"
        currency="EUR"
      />
      <SelectField
        name="depositCode"
        rules={{ required: true }}
        options={depositOptions}
        label={t('products.label-deposit-code')}
        control={control}
        optionLabel="label"
        optionValue="value"
      />
      <CalendarField
        name="effective"
        rules={{ required: false }}
        label={t('products.label-distributor-change-effective-date')}
        control={control}
        minDate={new Date()}
        showButtonBar
        showIcon
      />
      <CheckField
        name="movesToImports"
        label={t('products.label-moves-to-imports')}
        rules={{ required: false }}
        control={control}
      />
      {watchMovesToImports && (
      <>
        { dynamicFields.map((attribute) => (
          <DynamicField
            key={attribute.code}
            name={`dynamicValues.${attribute.code}`}
            control={control as any}
            watch={watch}
            attribute={attribute}
          />
        ))}
      </>
      )}

      <Divider />

      <ProductionPlants
        name="productionPlantIds"
        control={control}
      />

      <div className="mb-5">
        <UploadField
          name="authorization"
          rules={{ required: true }}
          label={t('settings.label-authorization-file')}
          control={control}
          uploadUrl="/files"
          maxFileSize="5"
        />
      </div>
      <div className="mb-5">
        <UploadField
          name="authorizationCancellation"
          rules={{ required: true }}
          label={t('settings.label-authorization-cancellation-file')}
          control={control}
          uploadUrl="/files"
          maxFileSize="5"
        />
      </div>

      <div className="mb-5">
        <CheckField
          name="userAgreement"
          label={t('products.label-confirm-distributor-change')}
          rules={{ required: true }}
          control={control}
        />
      </div>

      <div className="dialog-actions w-full flex flex-row justify-content-end">
        <Button
          disabled={!watchAgreement}
          data-testid="submit-button"
          style={{ display: 'block', width: '100%' }}
          loading={isLoading}
        >
          {t('products.cmd-submit-request')}
        </Button>
      </div>
    </form>
  );
}
