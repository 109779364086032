import React from 'react';
import classNames from 'classnames';
import useDrawer from '../../hooks/useDrawer';
import useLayoutStore from '../../stores/LayoutStore';
import Header from '../Header';
import { DrawerState } from '../types/types';
import './Main.scss';

type Props = {
  children?: any;
};

export default function Main({ children }: Props) {
  const drawerState = useLayoutStore((state) => state.drawerState);

  useDrawer();

  return (
    <main
      data-testid="main"
      className={classNames({
        main: true,
        'drawer-open': drawerState === DrawerState.OPEN,
        'drawer-collapsed': drawerState === DrawerState.COLLAPSED,
      })}
    >
      <Header />
      <div className="pt-8">
        {children}
      </div>
    </main>
  );
}
