import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import { StaticFormFieldProps } from '../../types/types';
import FormErrorMessage from '../../FormErrorMessage';

type Props = StaticFormFieldProps & InputSwitchProps;

export default function BooleanField({
  name,
  control,
  required,
  rules,
  label,
  help,
  ...rest
}: Props) {
  const { field, fieldState } = useController({
    name: name as string,
    control,
    rules,
  });

  return (
    <div className="field">
      { label && <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>{label}</label>}
      <div>
        <InputSwitch
          {...rest}
          inputId={field.name}
          className={classNames({ 'p-error': fieldState.error })}
          checked={Boolean(field.value)}
          onChange={(e) => field.onChange(e.value)}
        />
      </div>
      {help && <span className="form-help">{help}</span> }
      <FormErrorMessage label={fieldState.error?.message} />
    </div>
  );
}
