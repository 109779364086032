import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DollyRequest } from '../api/_generated_';
import useAxiosPrivate from './useAxiosPrivate';

export default function useDollyRequest(id: string | undefined) {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  async function queryFn() {
    const res = await axios.get<DollyRequest>(`/dolly-requests/${id}?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: ['dollyRequest', { id }],
    queryFn,
  });
}
