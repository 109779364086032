import React, { ReactNode } from 'react';
import { composeClasses } from '../../utils/styles';

type Props = {
  icon: ReactNode;
  label: string;
  overrideStyles?: boolean;
  className?: string;
};

export default function IconBlock({
  icon,
  label,
  overrideStyles,
  className,
}: Props) {
  const classes = overrideStyles
    ? className
    : composeClasses('flex flex-row align-items-center gap-3', className);

  return (
    <div className={classes}>
      {icon}
      {label}
    </div>
  );
}
