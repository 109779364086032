import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppRoutes from '../../constants/routes';

type Props = {
  mainProductId: string;
};

export default function DolliesProducts({
  mainProductId,
}: Props) {
  const { t } = useTranslation();

  if (!mainProductId) {
    return <div />;
  }

  return (
    <div className="mb-6">
      <h3 className="text-base uppercase font-medium mb-3">{t('products.label-dollies-products')}</h3>
      <Link
        to={`${AppRoutes.PRODUCTS}/${mainProductId}`}
        data-testid="dollies-product"
        className="flex justify-content-between align-items-center flex-wrap bg-white py-3 px-3 mb-3 border-round-lg border-surface"
      >
        <span>{mainProductId}</span>
        <i className="pi pi-chevron-right" />
      </Link>
    </div>
  );
}
