import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import ViewHeader from '../../components/ViewHeader';
import AppRoutes from '../../constants/routes';
import ProductionPlantTable from '../../components/ProductionPlantTable';
import useProductionPlants from '../../hooks/useProductionPlants';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import useProductionPlantFieldOptions from '../../hooks/useProductionPlantFieldOptions';

export default function ProductionPlants() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data, status, isSuccess } = useProductionPlants();

  const {
    data: optionsData,
    status: optionsStatus,
    isSuccess: isOptionsSuccess,
  } = useProductionPlantFieldOptions();

  const statusList = [status, optionsStatus];

  return (
    <NetworkContainer status={statusList as HttpRequestStatus[]}>
      <ViewHeader
        title={t('production-plants.title')}
        description={t('production-plants.label-description')}
      >
        <Button
          icon={<Plus />}
          size="small"
          onClick={() => navigate(AppRoutes.PRODUCTION_PLANTS_NEW)}
        >
          {t('production-plants.cmd-add-supply-chain')}
        </Button>
      </ViewHeader>
      { (isSuccess && isOptionsSuccess) && (
        <ProductionPlantTable items={data} data={optionsData} />
      )}
    </NetworkContainer>
  );
}
