import { useQueries } from '@tanstack/react-query';
import { OFFER_REFERENCE_BARCODE } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import { SampleRequestResource } from '../api/_generated_';

export type ExtendedSampleRequest = SampleRequestResource & {
  src: string;
  printId?: string;
};

export default function useBarcodes(sampleRequests: SampleRequestResource[] = []) {
  const axios = useAxiosPrivate();

  async function fetchBarcodeBase64(
    request: SampleRequestResource,
  ): Promise<ExtendedSampleRequest> {
    const res = await axios.get<string>(`/barcodes/${request.sampleIdentifier}`);
    return {
      ...request,
      src: `data:image/png;base64,${res.data}`,
    };
  }

  return useQueries({
    queries: sampleRequests.map((item) => ({
      queryKey: [OFFER_REFERENCE_BARCODE, item.uuid],
      queryFn: () => fetchBarcodeBase64(item),
      enabled: !!item.sampleIdentifier,
      staleTime: Infinity,
    })),
  });
}
