import React from 'react';
import { Calendar, CalendarProps } from 'primereact/calendar';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import i18n, { DATE_FORMAT_PRIME } from '../../../i18n/config';
import { getLangByI18nLocale } from '../../../utils/helper';
import FormErrorMessage from '../../FormErrorMessage';
import { StaticFormFieldProps } from '../../types/types';

type Props = StaticFormFieldProps & CalendarProps;

export default function CalendarField({
  name,
  control,
  required,
  rules,
  label,
  help,
  ...rest
}: Props) {
  const { field, fieldState } = useController({
    name: name as string,
    control,
    rules,
  });

  return (
    <div className="field">
      { label && <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>{label}</label>}
      <Calendar
        data-testid={`testid-${name}`}
        inputId={name}
        name={name}
        locale={getLangByI18nLocale(i18n.language)}
        dateFormat={DATE_FORMAT_PRIME}
        value={field.value}
        onChange={(e) => field.onChange(e.value)}
        style={{ width: '100%' }}
        {...rest}
      />
      {help && <span className="form-help">{help}</span> }
      <FormErrorMessage label={fieldState.error?.message} />
    </div>

  );
}
