import React from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { SelectItem } from 'primereact/selectitem';
import { Tag } from 'primereact/tag';
import ViewHeader from '../../components/ViewHeader';
import useOffers from '../../hooks/useOffers';
import OfferList from '../../components/OfferList/OfferList';
import AppRoutes from '../../constants/routes';
import SearchInput from '../../components/SearchInput';
import { OfferStatusEnum } from '../../api/_generated_';
import { HttpRequestStatus } from '../../components/types/types';
import NetworkContainer from '../../components/NetworkContainer';

export default function Offers() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isFetching,
    sort,
    keyword,
    status,
    queryStatus,
    pageState,
    result,
    onChangeStatus,
    onChangeKeyword,
    onChangePage,
    handleClearFilter,
    onSort,
  } = useOffers();

  const dropdownItemTemplate = (option: SelectItem) => {
    const tag = option.value.toLowerCase();
    return (
      <Tag
        className={`p-tag-${tag}`}
        value={t(`offers.label-offer-status-${tag}`)}
      />
    );
  };

  const statuses = Object.values(OfferStatusEnum).map((value) => ({
    label: t(`offers.label-offer-status-${value.toLocaleLowerCase()}`), value,
  }));

  return (
    <NetworkContainer status={queryStatus as HttpRequestStatus}>
      <ViewHeader
        title={t('offers.title-offers')}
        description={t('offers.label-offers-description')}
      >
        <Button
          label={t('offers.cmd-create-offer')}
          size="small"
          onClick={() => navigate(AppRoutes.OFFER_TYPE)}
        />
      </ViewHeader>
      <div className="field flex gap-3">
        <SearchInput
          placeholder={t('offers.label-search-offers')}
          onChange={onChangeKeyword}
          value={keyword}
          loading={isFetching}
        />
        <Dropdown
          data-testid="status-filter"
          onChange={onChangeStatus}
          placeholder={t('offers.label-offer-status')}
          className="filter-dropdown"
          itemTemplate={dropdownItemTemplate}
          options={statuses}
          showClear
          value={status}
        />
        <Button
          onClick={handleClearFilter}
          className="w-4rem"
          severity="secondary"
          icon="pi pi-times"
        />
      </div>
      <OfferList
        items={result?.content || []}
        status={queryStatus as HttpRequestStatus}
        isFetching={isFetching}
        sort={sort}
        onSort={onSort}
        pageState={pageState}
        totalRecords={result?.totalElements}
        onPageChange={onChangePage}
      />
    </NetworkContainer>
  );
}
