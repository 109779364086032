import { useIsAuthenticated } from '@azure/msal-react';
import { Divider } from 'primereact/divider';
import { KeyboardEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { InteractionStatus } from '@azure/msal-browser';
import { Message } from 'primereact/message';
import alkoLogo from '../../assets/images/Alko_logo.png';
import msSignInLogo from '../../assets/images/ms-symbollockup_signin_light.svg';
import useADLogin from '../../hooks/useADLogin';
import useAuthStore from '../../stores/AuthStore';
import './Login.scss';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { user } = useAuthStore();

  const location = useLocation();

  const from = location?.state?.from || '/';
  const authError = location?.state?.error;

  const { login, inProgress } = useADLogin();

  const axios = useAxiosPrivate();

  useEffect(() => {
    if (isAuthenticated && user) {
      axios.post('/user/login');
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, user, navigate, from, axios]);

  const doLogin = async () => {
    await login();
  };

  return (
    <div className="login-container">
      <h1 className="mb-6">{t('auth.title-sign-in')}</h1>
      {authError && (
        <div className="mb-6">
          <Message severity="error" text={t('auth.message-sign-in-failed')} />
        </div>
      )}
      <div className="login-section">
        <div className="logo-container item">
          <img src={alkoLogo} alt="Logo" className="logo" />
          <span className="logo-alt">{t('common.title-for-partners')}</span>
        </div>
        <Divider layout="vertical" className="login-divider" />
        <div className="buttons-container">
          <div
            role="button"
            tabIndex={0}
            onClick={() => doLogin()}
            onKeyDown={(e: KeyboardEvent) => {
              if (e.key === 'Enter' || e.key === 'space') {
                doLogin();
              }
            }}
            className={
              classNames('login-btn item', {
                'login-btn--disabled': inProgress === InteractionStatus.Login,
              })
            }
          >
            <img src={msSignInLogo} alt="login with MS" className="ms-logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
