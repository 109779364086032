import React, { useContext } from 'react';
import classNames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import { DynamicFormFieldProps } from '../../types/types';
import { OPTION_LABEL_KEY, OPTION_VALUE_KEY } from '../../../constants/dynamic-form-config';
import { getDynamicFieldTooltip } from '../../../utils/helper';
import { AttributeConfigContext } from '../../../utils/AttributeConfigProvider';
import { isAttributeDisabled } from '../../../utils/attribute';

type Props = DynamicFormFieldProps;

export default function MultiSelectInput({
  attribute,
  field,
  watch,
  fieldState,
}: Props) {
  const { getFilteredOptions, getMaxSelections } = useContext(AttributeConfigContext);

  const options = watch ? getFilteredOptions(attribute, watch) : attribute.options;
  return (
    <MultiSelect
      className={classNames({ 'w-full': true, 'p-error': fieldState.error })}
      options={options}
      optionLabel={OPTION_LABEL_KEY}
      optionValue={OPTION_VALUE_KEY}
      value={field.value || []}
      filter
      showSelectAll={false}
      showClear
      selectionLimit={getMaxSelections(attribute.code)}
      onChange={(e) => field.onChange(e.value)}
      disabled={isAttributeDisabled(attribute) || options.length === 0}
      {...getDynamicFieldTooltip(attribute)}
    />
  );
}
