import { useInfiniteQuery } from '@tanstack/react-query';
import { Message } from '../api/_generated_';
import { MESSAGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

type Page = {
  data: Message[];
  previousCursor?: number;
  nextCursor?: number;
};

export default function useMessages() {
  const axios = useAxiosPrivate();

  async function fetchMessages({ pageParam = 0 }) {
    const res = await axios.get('/messages', {
      params: {
        page: pageParam,
        size: 5,
      },
    });

    return {
      data: res.data.content,
      previousCursor: pageParam,
      nextCursor: res.data.last ? undefined : pageParam + 1,
    };
  }

  return useInfiniteQuery<Page, Error>({
    queryKey: [MESSAGES],
    queryFn: fetchMessages,
    getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined,
  });
}
