import React from 'react';
import classNames from 'classnames';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';

type Props = {
  id: string;
  label: string;
  help?: string;
  checked?: boolean;
  className?: any;
  bordered?: boolean;
  disabled?: boolean;
  onChange(e: InputSwitchChangeEvent): void;
};

export default function SettingSwitch({
  id,
  label,
  help,
  checked,
  className,
  bordered,
  disabled,
  onChange,
}: Props) {
  return (
    <div className={classNames({
      'setting-group': true,
      bordered,
      [className]: !!className,
    })}
    >
      <div className="switch-input-group">
        <label htmlFor={id} className="label-lg">{label}</label>
        <div>
          <InputSwitch
            inputId={id}
            checked={checked as boolean}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
      {help && <span className="form-help">{help}</span> }
    </div>
  );
}
