import { confirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import { OfferMessage, ProductMessage } from '../../api/_generated_';
import MessageItem from '../MessageItem';

type Props = {
  items?: ProductMessage[] | OfferMessage[];
  onDelete(id: number | undefined): void;
};

export default function MessageForum({
  items,
  onDelete,
}: Props) {
  const { t } = useTranslation();

  const onConfirm = (id: number | undefined) => {
    confirmDialog({
      header: t('common.title-confirm-deletion'),
      message: t('common.label-confirm-deletion-description'),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => onDelete(id),
    });
  };

  return (
    <div className="mt-4">
      {items?.map((item) => (
        <MessageItem
          message={item}
          key={item.timestamp}
          onDelete={() => onConfirm(item.id)}
        />
      ))}
    </div>
  );
}
