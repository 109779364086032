import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { SubmitHandler, useForm } from 'react-hook-form';
import SelectField from '../StaticFields/SelectField';
import useAttributeOptions from '../../hooks/useAttributeOptions';
import { PRODUCT_EOL_OPTIONS } from '../../constants/pim-attributes';
import { ProductEOLRequest } from '../../api/_generated_';
import TextField from '../StaticFields/TextField';

type Props = {
  onSubmit: SubmitHandler<ProductEOLRequest>;
  onClose: () => void
};

export default function ProductEOLForm({
  onSubmit,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const {
    data: eolReasons,
  } = useAttributeOptions({
    attributeCode: PRODUCT_EOL_OPTIONS,
  });

  const {
    control,
    watch,
    handleSubmit,
  } = useForm<ProductEOLRequest>({});

  const reason = watch('reasonCode');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SelectField
        rules={{ required: true }}
        label={t('products.label-eol-reason')}
        options={eolReasons}
        control={control}
        name="reasonCode"
        optionLabel="label"
        optionValue="value"
      />
      {reason === 'muu_syy' && (
        <TextField
          name="reasonText"
          rules={{ required: true }}
          label={t('products.label-eol-reason-description')}
          control={control}
        />
      )}
      <div className="flex justify-content-between">
        <div className="ml-auto text-right">
          <Button
            label={t('products.cmd-submit-request')}
            type="submit"
            className="mr-3"
          />
          <Button
            label={t('common.cmd-cancel')}
            type="button"
            onClick={onClose}
            severity="secondary"
          />
        </div>
      </div>
    </form>
  );
}
