import { Skeleton } from 'primereact/skeleton';
import Card from '../Card';

export default function DashboardSkeletons() {
  return (
    <Card>
      <Skeleton height="1.5rem" className="mb-2" width="300px" />
      {[1, 2, 3].map((item) => (
        <Skeleton key={item} height="3rem" className="mb-2" borderRadius="0.25rem" />
      ))}
    </Card>
  );
}
