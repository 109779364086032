import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useDistributorDeadline from '../../hooks/useDistributorDeadline';
import Container from '../../components/Container';
import ViewHeader from '../../components/ViewHeader';
import AppRoutes from '../../constants/routes';
import DistributorChangeList from '../../components/DistributorChangeList/DistributorChangeList';
import { HttpRequestStatus } from '../../components/types/types';
import useDistributorChanges from '../../hooks/useDistributorChanges';
import { DistributorChangeDto } from '../../api/_generated_';

export default function DistributorChanges() {
  const { t } = useTranslation();

  const { isLoading, data: deadlineData } = useDistributorDeadline();

  const { data, status } = useDistributorChanges();

  const navigate = useNavigate();

  const deadline = dayjs(deadlineData?.deadline);

  const deadlinePassed = (!deadlineData?.deadline || deadline.isBefore(dayjs(), 'day'));

  return (
    <Container>
      <ViewHeader
        title={t('products.title-distributor-change')}
        description={t('products.label-distributor-change-description')}
      >
        { !deadlinePassed && (
          <Button
            label={t('products.title-change-distributor')}
            onClick={() => navigate(AppRoutes.DISTRIBUTOR_CHANGES_NEW)}
            size="small"
            loading={isLoading}
          />
        )}
      </ViewHeader>
      <DistributorChangeList
        items={data as DistributorChangeDto[]}
        status={status as HttpRequestStatus}
      />
    </Container>
  );
}
