import { useTranslation } from 'react-i18next';
import { HttpRequestStatus } from '../../components/types/types';
import NetworkContainer from '../../components/NetworkContainer';
import ViewHeader from '../../components/ViewHeader';
import InstructionsBySelection from './InstructionsBySelection';
import useOfferFrame from '../../hooks/useOfferFrame';

export default function AfterOfferingInstructions() {
  const { data, status, isSuccess } = useOfferFrame();

  const { t } = useTranslation();

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <ViewHeader
        title={t('offers.title-thank-you-for-offering')}
        description={t('offers.label-thank-you-for-offering-description')}
      />
      <h4 className="font-medium">{t('offers.title-whats-next')}</h4>
      {isSuccess && (
        <InstructionsBySelection data={data} />
      )}
    </NetworkContainer>
  );
}
