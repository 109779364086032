import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ViewHeader from '../../components/ViewHeader';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import ProductChangeEditor from '../../components/ProductChangeEditor';
import AppRoutes from '../../constants/routes';
import useEditProductChange from '../../hooks/useEditProductChange';
import useProductChange from '../../hooks/useProductChange';
import { ProductChangeRequest } from '../../api/_generated_';

export default function EditProductChange() {
  const { id } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { data, status } = useProductChange();

  const { mutate, isLoading } = useEditProductChange({
    onSuccess: () => navigate(`${AppRoutes.PRODUCTS}/${id}`),
  });

  const handleSave = async (changes: ProductChangeRequest) => {
    if (!changes) return;

    mutate(changes);
  };

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <ViewHeader
        title={t('products.label-edit-product-changes')}
        description={t('products.label-edit-product-changes-description')}
      />
      <p className="line-height-4 mb-4">{t('products.label-product-change-instructions')}</p>
      <ProductChangeEditor
        data={data}
        onSubmit={handleSave}
        isLoading={isLoading}
      />
    </NetworkContainer>
  );
}
