import axios, { AxiosRequestConfig, AxiosRequestTransformer } from 'axios';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { getEnv } from '../env';
import useAuthStore from '../stores/AuthStore';
import useAccessToken from './useAccessToken';

const env = getEnv();
const BASE_URL = env.SUPPLIERPORTAL_BASE_PATH;

const dateTransformer = (data: any): any => {
  if (data instanceof FormData) {
    return data;
  }
  if (data instanceof Date) {
    return dayjs(data).format('YYYY-MM-DD');
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data)
      .map(([key, value]) => [key, dateTransformer(value)]));
  }
  return data;
};

export const instance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
});

const useAxiosPrivate = () => {
  const { acquireAccessTokenBackground } = useAccessToken();
  const { accesstoken, setToken } = useAuthStore();

  useEffect(() => {
    const requestIntercept = instance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const configWithAuth = config;
        if (configWithAuth.headers === undefined) {
          configWithAuth.headers = {};
        }
        if (!configWithAuth.headers.Authorization) {
          configWithAuth.headers.Authorization = `Bearer ${accesstoken}`;
        }
        return configWithAuth;
      },
      (error) => error,
    );

    const responseIntercept = instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        const is401 = error?.response?.status === 401 || error?.response?.status === 403;

        if (is401 && !prevRequest.sent) {
          prevRequest.sent = true;

          const newToken = await acquireAccessTokenBackground();
          setToken(newToken);

          prevRequest.headers.Authorization = `Bearer ${newToken}`;
          return instance(prevRequest);
        }

        if (error) prevRequest.sent = false;

        return Promise.reject(error);
      },
    );
    return () => {
      instance.interceptors.request.eject(requestIntercept);
      instance.interceptors.response.eject(responseIntercept);
    };
  }, [accesstoken, acquireAccessTokenBackground, setToken]);

  return instance;
};

export default useAxiosPrivate;
