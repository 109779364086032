import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { ProductChangeRequest, ValidationErrors } from '../api/_generated_';

type Props = {
  onSuccess?(): void;
};
export default function useCreateProductChange({
  onSuccess,
}: Props) {
  const { id } = useParams();

  const axios = useAxiosPrivate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  async function createProductChange(body: ProductChangeRequest) {
    const res = await axios.post<void>(`/product-changes/${id}`, body);
    return res.data;
  }

  function onError(e: AxiosError<any, any>) {
    if (e.code === 'ERR_BAD_REQUEST' && e.response?.data?.errors) {
      toast.current?.show({
        severity: 'error',
        summary: t('common.message-saving-failed'),
        detail: createValidationErrorMessage(e.response?.data),
      });
    } else {
      toast.current?.show({
        severity: 'error',
        summary: t('common.message-saving-failed'),
        detail: t('common.message-saving-failed-description'),
      });
    }
  }

  function createValidationErrorMessage(data: ValidationErrors) {
    const messages = Object.keys(data.errors).map((key) => t(data.errors[key].message));
    return messages.join(', ');
  }

  return useMutation({
    mutationFn: createProductChange,
    onError,
    onSuccess,
  });
}
