import React from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { OfferAuthorization } from '../../api/_generated_';
import AuhtorizationFileInput from '../AuthorizationFileInput';

type Props = {
  authorization?: OfferAuthorization;
  isSaving: boolean;
  onSubmit(data: any): void;
  onCancel(): void;
  onDelete(id: number): void;
};

export default function AuthorizationForm({
  authorization,
  isSaving,
  onSubmit,
  onCancel,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      id: authorization?.id,
      name: authorization?.name || '',
      fileId: authorization?.fileId || '',
      authorizingParty: authorization?.authorizingParty || '',
      brands: authorization?.brands || '',
      additionalDetails: authorization?.additionalDetails || '',
      approved: authorization?.approved,
    },
  });

  const isNew: boolean = authorization?.id === undefined;
  const getFormErrorMessage = (name: keyof OfferAuthorization) => errors[name] && <small className="p-error">{errors?.[name]?.message}</small>;

  return (
    <div>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <input type="hidden" name={field.name} value={field.value} />
        )}
      />
      {/* Authorization name */}
      <div className="field">
        <label htmlFor="name" className={classNames({ 'p-error': !!errors.name })}>{t('common.label-name')}</label>
        <Controller
          name="name"
          control={control}
          rules={{ required: t('validations.message-field-required') }}
          render={({ field, fieldState }) => (
            <InputText data-testid={field.name} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
          )}
        />
        {getFormErrorMessage('name' as keyof OfferAuthorization)}
      </div>
      {/* Authorizing party */}
      <div className="field">
        <label htmlFor="authorizingParty" className={classNames({ 'p-error': !!errors.name })}>{t('settings.label-auhtorizing-party')}</label>
        <Controller
          name="authorizingParty"
          control={control}
          rules={{ required: t('validations.message-field-required') }}
          render={({ field, fieldState }) => (
            <InputText data-testid={field.name} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
          )}
        />
        {getFormErrorMessage('authorizingParty' as keyof OfferAuthorization)}
      </div>
      {/* Authorization brands */}
      <div className="field">
        <label htmlFor="brands" className={classNames({ 'p-error': !!errors.name })}>{t('settings.label-brands')}</label>
        <Controller
          name="brands"
          control={control}
          rules={{ required: t('validations.message-field-required') }}
          render={({ field, fieldState }) => (
            <InputText rdata-testid={field.name} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
          )}
        />
        {getFormErrorMessage('brands' as keyof OfferAuthorization)}
      </div>
      {/* Authorization additional details */}
      <div className="field">
        <label htmlFor="additionalDetails">{t('common.label-additional-details')}</label>
        <Controller
          name="additionalDetails"
          control={control}
          render={({ field }) => (
            <InputTextarea data-testid={field.name} id={field.name} {...field} rows={6} />
          )}
        />
        {getFormErrorMessage('additionalDetails' as keyof OfferAuthorization)}
      </div>
      {/* Authorization file */}
      <div className="field">
        <label htmlFor="fileId" className={classNames({ 'p-error': !!errors.name })}>{t('settings.label-authorization-file')}</label>
        <Controller
          name="fileId"
          control={control}
          rules={{ required: t('validations.message-field-required') }}
          render={({ field, fieldState }) => (
            <AuhtorizationFileInput readOnly={!isNew} field={field} fieldState={fieldState} />
          )}
        />
        {getFormErrorMessage('fileId' as keyof OfferAuthorization)}
      </div>
      <div className="flex justify-content-between">
        <div className="ml-auto text-right">
          <Button
            aria-disabled
            type="button"
            label={t('common.cmd-cancel')}
            className="mr-3"
            onClick={onCancel}
            disabled={isSaving}
            severity="secondary"
          />
          <Button
            type="button"
            label={t('common.cmd-save')}
            loading={isSaving}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
}
