import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { DistributorChangeDto } from '../../api/_generated_';
import NetworkContainer from '../NetworkContainer';
import { HttpRequestStatus } from '../types/types';
import DataRow from '../DataRow';
import { getBareBooleanValue } from '../../utils/attribute';
import './DistributorChangeList.scss';
import { formatPrice } from '../../utils/helper';
import { DATE_FORMAT } from '../../i18n/config';

type Props = {
  items: DistributorChangeDto[];
  status: HttpRequestStatus;
};

export default function DistributorChangeList({ items, status }: Props) {
  const { t } = useTranslation();

  const formatDate = (date?: string) => (date ? dayjs(date).format(DATE_FORMAT) : '-');

  const [activeChange, setActiveChange] = useState<DistributorChangeDto | undefined>();

  const statusTemplate = (item: DistributorChangeDto) => {
    const tag = item.status?.toLowerCase();
    return (
      <span>{t(`products.label-distributor-change-status-${tag}`)}</span>
    );
  };

  const onRowClick = (e: DataTableRowClickEvent) => {
    setActiveChange(e.data as any);
  };

  return (
    <NetworkContainer status={status} excludeContainers>
      <Dialog
        header={t('products.label-distributor-change-request')}
        visible={!!activeChange}
        modal
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        style={{ width: '50vw' }}
        onHide={() => setActiveChange(undefined)}
      >
        { activeChange && (
          <div className="request-grid">
            <DataRow label={t('common.label-status')} value={t(`products.label-distributor-change-status-${activeChange.status?.toLowerCase()}`)} />
            <DataRow label={t('purchase-prices.label-purchase-price')} value={formatPrice(activeChange.purchasePrice as number)} />
            <DataRow label={t('products.label-deposit-code')} value={activeChange.depositCode} />
            <DataRow label={t('products.label-distributor-change-effective-date')} value={formatDate(activeChange.effective)} />
            <DataRow label={t('settings.label-authorization-file')} value={activeChange.authorization} />
            <DataRow label={t('settings.label-authorization-cancellation-file')} value={activeChange.authorizationCancellation} />
            <DataRow label={t('products.label-moves-to-imports')} value={getBareBooleanValue(activeChange.movesToImports, t)} />
            <DataRow label={t('common.label-created')} value={formatDate(activeChange.createdAt)} />
          </div>
        )}
      </Dialog>
      <DataTable
        dataKey="id"
        value={items}
        className="mb-3 link-table"
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        rows={25}
        totalRecords={items?.length}
        emptyMessage={t('common.label-no-results')}
        onRowClick={onRowClick}
      >
        <Column field="status" header={t('common.label-status')} body={statusTemplate} style={{ width: '10rem' }} sortable />
        <Column field="productId" header={t('products.label-product-number')} />
        <Column field="productName" header={t('offers.label-product')} />
        <Column field="createdAt" header={t('common.label-created')} body={(item: DistributorChangeDto) => formatDate(item.createdAt)} sortable />
      </DataTable>
    </NetworkContainer>
  );
}
