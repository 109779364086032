import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from './useAxiosPrivate';
import { ProductionPlantFieldOptions } from '../api/_generated_';

export default function useProductionPlantFieldOptions(
  props: UseQueryOptions<ProductionPlantFieldOptions> = {},
) {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  async function fetchData() {
    const res = await axios.get(`/production-plants/field-options?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: ['production-plant-field-options', i18n.language],
    queryFn: fetchData,
    ...props,
  });
}
