/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { OfferAuthorization } from '../../api/_generated_';
import './AuthorizationCard.scss';

type Props = {
  active: boolean;
  authorization?: OfferAuthorization;
  onClick: MouseEventHandler<HTMLDivElement>;
  onClickDetails: MouseEventHandler<HTMLButtonElement>;
};

export default function AuthorizationCard({
  active,
  authorization,
  onClick,
  onClickDetails,
}: Props) {
  return (
    <div
      onClick={onClick}
      className={classNames({
        'p-button p-button-secondary authorization-card': true,
        active,
      })}
    >
      <div className="authorization-card-header">
        <h4>{authorization?.name}</h4>
        <button
          type="button"
          className="p-button p-button-secondary pi pi-ellipsis-v authorization-card-button"
          onClick={(e) => {
            onClickDetails(e);
            e.stopPropagation();
          }}
        >
          <span />
        </button>
      </div>
      <h5>{authorization?.authorizingParty}</h5>
      <p>{authorization?.brands}</p>
      <p>{authorization?.additionalDetails}</p>
    </div>
  );
}
