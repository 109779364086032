import classNames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from '../../FormErrorMessage';
import { HttpRequestStatus, StaticFormFieldProps } from '../../types/types';
import useProductionPlants from '../../../hooks/useProductionPlants';
import NetworkContainer from '../../NetworkContainer';

type Props = StaticFormFieldProps & {
  name: string;
};

export default function ProductionPlants({ name, control, rules }: Props) {
  const { t } = useTranslation();

  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  const {
    data,
    status,
  } = useProductionPlants();

  return (
    <NetworkContainer status={status as HttpRequestStatus} excludeContainers>
      <div className="field">
        <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>
          {t('production-plants.title')}
        </label>
        <MultiSelect
          data-testid="pp-select"
          optionValue="id"
          optionLabel="name"
          className={classNames({ 'w-full': true, 'p-invalid': fieldState.error })}
          options={data || []}
          value={field.value || []}
          onChange={(e) => field.onChange(e.value)}
        />
        <FormErrorMessage label={fieldState.error?.message} />
      </div>
    </NetworkContainer>
  );
}
