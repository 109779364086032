import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { User } from '../api/_generated_';
import { AUTH_USER } from '../constants/queryKeys';
import useAuthStore from '../stores/AuthStore';
import useAccessToken from './useAccessToken';
import { instance as axios } from './useAxiosPrivate';
import AppRoutes from '../constants/routes';

const useApp = () => {
  const { acquireAccessToken } = useAccessToken();

  const { setUser, setToken, clear } = useAuthStore();

  const navigate = useNavigate();

  const { instance, inProgress } = useMsal();

  const activeAuthUser = instance.getActiveAccount()?.username;

  async function getCurrentUser(token: string) {
    const response = await axios.get<User>('/user/get-current', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }

  async function authenticate(): Promise<{ user: User, token: string } | null> {
    if (!activeAuthUser) {
      return null;
    }

    const token = await acquireAccessToken();

    if (!token) {
      return null;
    }

    const user = await getCurrentUser(token);

    return {
      user,
      token,
    };
  }

  const { isLoading } = useQuery({
    queryKey: [AUTH_USER, activeAuthUser],
    queryFn: authenticate,
    onError: () => {
      clear();
      instance.setActiveAccount(null);
      navigate(AppRoutes.LOGIN, { state: { error: true } });
    },
    onSuccess: (result) => {
      if (result) {
        setToken(result.token);
        setUser(result.user);
      } else {
        clear();
      }
    },
  });

  return {
    initializingApp: isLoading || inProgress !== InteractionStatus.None,
  };
};

export default useApp;
