import React from 'react';
import classNames from 'classnames';
import { AttributeStructureGroup } from '../../api/_generated_';
import './Steps.scss';

function isStepCompleted(
  stages: string[],
  currentStage: AttributeStructureGroup,
  i: number,
): boolean {
  const index = stages?.findIndex((item) => item === currentStage.code);

  return index > i;
}

function isStepActive(stages: string[], currentStage: AttributeStructureGroup, i: number): boolean {
  return stages[i] === currentStage.code;
}

type Props = {
  currentStage: AttributeStructureGroup;
  stages?: string[];
};

export default function Steps({
  currentStage,
  stages,
}: Props) {
  return (
    <div className="stepper-wrapper" role="tablist">
      { stages?.map((item, index) => (
        <div
          key={`stepper-step-${item}`}
          role="tab"
          className={classNames({
            'stepper-item': true,
            completed: isStepCompleted(stages, currentStage, index),
            active: isStepActive(stages, currentStage, index),
          })}
        >
          <div className="step-counter" />
        </div>
      ))}
    </div>
  );
}
