import React, { ChangeEvent } from 'react';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';
import SearchInput from '../SearchInput';
import { BeverageStrength, PurchasePriceFilterProps } from '../types/types';

type Props = {
  loading?: boolean;
  filters?: PurchasePriceFilterProps;
  onChangeSearch(event: ChangeEvent<HTMLInputElement>): void;
  onChangeBeverageStrength?(e: SelectButtonChangeEvent): void;
};

export default function PurchasePriceFilters({
  loading,
  filters,
  onChangeSearch,
  onChangeBeverageStrength,
}: Props) {
  const { t } = useTranslation();

  const beverageStrengthOptions = [
    { label: t('purchase-prices.label-filter-mild'), value: BeverageStrength.MILD },
    { label: t('purchase-prices.label-filter-strong'), value: BeverageStrength.STRONG },
  ];

  return (
    <div className="flex flex-wrap justify-content-between gap-3 mb-3">
      <div className="flex-1">
        <SearchInput
          placeholder={t('products.label-search-products')}
          loading={loading}
          value={filters?.search}
          onChange={onChangeSearch}
          style={{ height: '50px', minWidth: '16rem' }}
        />
      </div>
      <SelectButton
        value={filters?.beverageStrength}
        options={beverageStrengthOptions}
        onChange={onChangeBeverageStrength}
      />
    </div>
  );
}
