import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { AttributeStructureItem, SupplierSettingsResponse } from '../../api/_generated_';
import DynamicField from '../DynamicField';
import { getAttributeValue } from '../../utils/attribute';

type Props = {
  data: SupplierSettingsResponse | undefined;
  loading?: boolean;
  onSubmit(data: SupplierSettingsResponse): void;
};

function getDefaultValue(
  values: { [key: string]: any } | undefined,
  tenderAttribute: AttributeStructureItem,
) {
  const value = values?.[tenderAttribute.code as string];
  const defaultValue = getAttributeValue(tenderAttribute, value);

  return defaultValue;
}

export default function GeneralSettingsForm({
  data,
  loading,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    const items = data?.settings?.reduce((obj, attr) => ({
      ...obj,
      [attr.code]: getDefaultValue(data?.values, attr),
    }), {});

    return {
      ...items,
    };
  }, [data]);

  const {
    control,
    watch,
    handleSubmit,
  } = useForm<SupplierSettingsResponse>({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      { data?.settings?.map((attribute) => (
        <DynamicField
          key={`field-${attribute.code}`}
          control={control}
          required={false}
          attribute={attribute}
          uploadUrl={`/supplier-documents/${attribute.code}`}
          downloadUrl={`/supplier-documents/${attribute.code}/preview`}
          watch={watch}
        />
      ))}
      <Button
        type="submit"
        label={t('common.cmd-save')}
        className="w-12rem"
        loading={loading}
      />
    </form>
  );
}
