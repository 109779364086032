import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSupplierSettings from '../hooks/useSupplierSettings';
import { HttpRequestStatus } from '../components/types/types';
import AppRoutes from '../constants/routes';
import { BSCI_ACCEPTANCE } from '../constants/pim-attributes';
import Container from '../components/Container';
import ErrorMessage from '../components/ErrorMessage';

export default function RequireBsci() {
  const location = useLocation();

  const { t } = useTranslation();

  const { data, status } = useSupplierSettings();

  if (status === HttpRequestStatus.LOADING) {
    return (
      <div className="text-center">
        <ProgressSpinner data-testid="loading-indicator" />
      </div>
    );
  }

  if (status === HttpRequestStatus.SUCCESS) {
    return data.values?.[BSCI_ACCEPTANCE]
      ? <Outlet />
      : <Navigate to={AppRoutes.ACCEPT_CODE_OF_CONDUCT} state={{ from: location }} replace />;
  }

  return (
    <Container size="sm">
      <ErrorMessage
        summary={t('code-of-conduct.message-could-not-check-acceptance')}
        detail={t('common.error.label-fetch-error-description')}
      />
    </Container>
  );
}
