/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { AlertCircle } from 'react-feather';
import { Divider } from 'primereact/divider';
import dayjs from 'dayjs';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
  FilterItem,
  PurchasePrice, PurchasePriceError, PurchasePriceImportResult, PurchasePriceValidationResult,
} from '../../api/_generated_';
import Container from '../../components/Container';
import ErrorMessage from '../../components/ErrorMessage';
import PurchasePriceEditor from '../../components/PurchasePriceEditor';
import { HttpRequestStatus, BeverageStrength } from '../../components/types/types';
import ViewHeader from '../../components/ViewHeader';
import AppRoutes from '../../constants/routes';
import useFileDownload from '../../hooks/useFileDownload';
import useFileUpload from '../../hooks/useFileUpload';
import usePurchasePrices from '../../hooks/usePurchasePrices';
import useSavePurchasePrices from '../../hooks/useSavePurchasePrices';
import useCalculatePurchasePrices from '../../hooks/useCalculatePurchasePrices';
import useSearchParams from '../../hooks/useSearchParams';
import { megaBytesToBytes } from '../../utils/helper';
import './ReportPurchasePrices.scss';

export default function ReportPurchasePrices() {
  const urlParams = useSearchParams();

  const beverageStrength = urlParams.get('type');

  const [prices, setPrices] = useState<PurchasePrice[]>([]);

  const [importErrors, setImportErrors] = useState<PurchasePriceError[] | undefined>();

  const { status, data } = usePurchasePrices({
    onSuccess: (res) => setPrices(res.items || []),
    listAll: true,
    beverageStrength: beverageStrength as BeverageStrength,
  });

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const deadline = dayjs(data?.deadline);

  const isDeadlineExpired = (!data?.deadline || deadline.isBefore(dayjs(), 'day'));

  const { mutate, isLoading: isSaving } = useSavePurchasePrices({
    onSuccess: () => navigate(AppRoutes.PURCHASE_PRICE_REPORT),
  });

  const onSuccess = (priceData: PurchasePrice | undefined) => {
    const updatedPrices = prices.map((item) => {
      if (item.productId === priceData?.productId) {
        return {
          ...item,
          ...priceData,
        };
      }
      return item;
    });

    setPrices(updatedPrices);
  };

  const {
    isLoading: isCalculatingPrices,
    mutate: calculatePrice,
  } = useCalculatePurchasePrices({ onSuccess });

  const onSavePrices = async () => {
    if (!prices?.length) {
      return;
    }

    mutate(prices);
  };

  const onCalculatePrices = (purchasePrice: PurchasePrice):
  PurchasePriceValidationResult | undefined => {
    if (isCalculatingPrices) {
      return;
    }
    calculatePrice(purchasePrice);
  };

  const handleDownloadResponse = (file: File) => {
    const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = file.name || `report_template_${beverageStrength?.toLowerCase()}_${new Date().toISOString().slice(0, 10)}.xlsx`;
    tempLink.click();
  };

  const { onDownload } = useFileDownload({
    callback: handleDownloadResponse,
    url: `/purchase-prices/template/${beverageStrength}`,
  });

  const getMergedResponse = (
    existingPrices: PurchasePrice[],
    importResult: PurchasePrice[],
  ) => existingPrices.map((item) => ({
    ...item,
    ...importResult.find((res) => res.productId === item.productId),
  }));

  const handleUploadResponse = (result: PurchasePriceImportResult) => {
    setPrices(getMergedResponse(prices, result.rows || []));
    setImportErrors(result.errors);
  };

  const {
    uploadStatus,
    onUpload,
    onValidationFail,
  } = useFileUpload({
    callback: handleUploadResponse,
    url: `/purchase-prices/import/${beverageStrength}?locale=${i18n.language}`,
  });

  const depositOptions: FilterItem[] = [
    { label: '', value: null as any, sortOrder: 0 },
    ...data?.depositOptions || [],
  ];

  const accordionHeaderTemplate = () => {
    const title = t('purchase-prices.message-report-contains-errors', {
      count: importErrors?.length,
    });

    return (
      <div className="flex justify-content-between w-full">
        <span>{title}</span>
        <AlertCircle />
      </div>
    );
  };

  if (!beverageStrength) {
    return (
      <Container>
        <ErrorMessage />
      </Container>
    );
  }

  if (status === HttpRequestStatus.LOADING) {
    return (
      <div className="text-center">
        <ProgressSpinner data-testid="loading-indicator" />
      </div>
    );
  }

  if (isDeadlineExpired) {
    return (
      <Container>
        <span>{t('purchase-prices.message-deadline-expired')}</span>
      </Container>
    );
  }

  return (
    <Container size="lg">
      <Button
        icon="pi pi-arrow-left"
        label={t('common.cmd-back')}
        onClick={() => navigate(AppRoutes.PURCHASE_PRICE_REPORT)}
        className="mb-5"
        text
        disabled={isSaving || isCalculatingPrices}
      />
      <ViewHeader
        title={t('purchase-prices.title-report-purchase-prices')}
        description={t(`purchase-prices.label-report-purchase-prices-instructions-${beverageStrength}`)}
      />
      <div className="field">
        <label htmlFor="pricing-plan">{t('purchase-prices.cmd-add-plan')}</label>
        <div className="relative h-48px max-w-30rem">
          <FileUpload
            id="pricingPlanUpload"
            mode="basic"
            name="pricing-plan"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            uploadHandler={onUpload}
            onValidationFail={onValidationFail}
            customUpload
            auto
            chooseLabel={t('common.label-choose-file')}
            maxFileSize={megaBytesToBytes(5)}
          />
          {uploadStatus.isUploading && (
          <div className="upload-overlay animate fade">
            <ProgressBar value={uploadStatus.progress} />
          </div>
          )}
        </div>
        <small className="block mt-2">
          <Trans
            i18nKey="purchase-prices.label-download-report-template"
            components={{
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus
              downloadlink: <span role="link" className="download-link" onClick={onDownload} />,
            }}
          />
        </small>
      </div>
      <Divider />
      { !!importErrors
        && (
          <Accordion className="error-accordion mb-3">
            <AccordionTab headerTemplate={accordionHeaderTemplate}>
              {importErrors?.map((item) => (
                <span key={`import-error-${item.row}`} className="block">
                  {t('common.label-row-x', { row: item.row })}
                  {': '}
                  {t(`purchase-prices.label-error-${item.error?.toLowerCase()}`)}
                </span>
              ))}
            </AccordionTab>
          </Accordion>
        )}
      <PurchasePriceEditor
        items={prices}
        errors={[]}
        mode={beverageStrength?.toUpperCase() as BeverageStrength}
        isSaving={isSaving}
        depositOptions={depositOptions}
        onSavePrices={onSavePrices}
        isCalculatingPrices={isCalculatingPrices}
        onCalculatePrices={onCalculatePrices}
      />
    </Container>
  );
}
