import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { ProductChangeRequest, ValidationErrors } from '../api/_generated_';

type Props = {
  onSuccess?(data: void, variables: ProductChangeRequest, context: any): void;
  onValidationError?: (data: ValidationErrors) => void
};

export default function useValidateProductChange({
  onSuccess,
  onValidationError,
}: Props) {
  const { id } = useParams();

  const axios = useAxiosPrivate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  async function validateProductChange(body: ProductChangeRequest) {
    const res = await axios.post<void>(`/product-changes/${id}/validate`, body);
    return res.data;
  }

  function onError(e: AxiosError<any, any>) {
    if (e.code === 'ERR_BAD_REQUEST' && e.response?.data?.errors && onValidationError) {
      onValidationError(e.response?.data);
    } else {
      toast.current?.show({
        severity: 'error',
        summary: t('common.message-saving-failed'),
        detail: t('common.message-saving-failed-description'),
      });
    }
  }

  return useMutation({
    mutationFn: validateProductChange,
    onError,
    onSuccess,
  });
}
