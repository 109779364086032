import { Box, ShoppingCart, Truck } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { CreateOfferInputTargetEnum } from '../../api/_generated_';
import CTACard from '../CTACard';
import { Selection } from '../types/types';

type Props = {
  value?: CreateOfferInputTargetEnum;
  onChange(value: CreateOfferInputTargetEnum): void;
  error?: boolean;
  isTender?: boolean;
  isT3?: boolean;
  isAgent?: boolean;
  warehouseLicenceNumber?: string;
  selection?: string;
};

export default function OfferTargetSelect({
  value,
  onChange,
  error,
  isTender,
  isT3,
  isAgent,
  warehouseLicenceNumber,
  selection,
}: Props) {
  const { t } = useTranslation();

  const isSpecialOrder = selection === Selection.SpecialOrder;

  return (
    <div className="mb-3">
      <span className="block mb-3">{t('offers.label-offer-target')}</span>
      <div className="grid">
        {!isAgent && (
          <div className="col-12 md:col-4">
            <CTACard
              icon={<ShoppingCart size={50} />}
              label={t('offers.label-to-store')}
              active={value === CreateOfferInputTargetEnum.Store}
              onClick={() => onChange(CreateOfferInputTargetEnum.Store)}
              error={error}
            />
          </div>
        )}
        {!isT3 && (
          <>
            {warehouseLicenceNumber && (
              <div className="col-12 md:col-4">
                <CTACard
                  icon={<Box size={50} />}
                  label={t('offers.label-to-central-warehouse')}
                  active={value === CreateOfferInputTargetEnum.CentralWarehouse}
                  onClick={() => onChange(CreateOfferInputTargetEnum.CentralWarehouse)}
                  error={error}
                />
              </div>
            )}
            <div className="col-12 md:col-4">
              {(isTender || isSpecialOrder) && (
                <CTACard
                  icon={<Truck size={50} />}
                  label={t('offers.label-to-import-service')}
                  active={value === CreateOfferInputTargetEnum.ImportService}
                  onClick={() => onChange(CreateOfferInputTargetEnum.ImportService)}
                  error={error}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
