import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Notification } from '../api/_generated_';
import { HttpRequestStatus } from '../components/types/types';
import { NOTIFICATIONS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useNotifications() {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  async function fetchNotifications() {
    const urlParams = new URLSearchParams();
    urlParams.append('locale', i18n.language);

    const res = await axios.get<Notification[]>(`/notifications?${urlParams.toString()}`);
    return res.data;
  }

  const { data, status } = useQuery({
    queryKey: [NOTIFICATIONS],
    queryFn: fetchNotifications,
    refetchInterval: 300000, // 5 minutes,
  });

  return {
    notifications: data,
    status: status as HttpRequestStatus,
    hasNotifications: data?.some((item) => item.unread),
  };
}
