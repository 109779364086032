import { useMemo } from 'react';
import classNames from 'classnames';
import { FileUpload } from 'primereact/fileupload';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'primereact/progressbar';
import { CheckCircle } from 'react-feather';
import { Button } from 'primereact/button';
import { DynamicFormFieldProps } from '../../types/types';
import { formatBytes, getUserFriendlyFilename, megaBytesToBytes } from '../../../utils/helper';
import useFileUpload from '../../../hooks/useFileUpload';
import './FileInput.scss';
import useFileDownload from '../../../hooks/useFileDownload';
import { isAttributeDisabled } from '../../../utils/attribute';

type FileValidations = {
  allowedExtensions?: string[];
  maxFileSize?: string;
};

type Props = DynamicFormFieldProps & {
  inputType?: 'image' | 'file';
  uploadUrl?: string;
  displayName?: string;
  downloadUrl?: (() => string) | string;
};

function getAcceptProperty(inputType: 'image' | 'file' | undefined, validations: FileValidations) {
  if (validations?.allowedExtensions) {
    const extensionsWithPrefix = validations?.allowedExtensions?.map((ext) => `.${ext}`);
    return extensionsWithPrefix?.join(', ');
  }

  return inputType === 'image' ? 'image/*' : '*';
}

export default function FileInput({
  attribute,
  field,
  fieldState,
  inputType,
  uploadUrl,
  displayName,
  downloadUrl,
}: Props) {
  const { t } = useTranslation();

  const validations = attribute as FileValidations;

  const accept = getAcceptProperty(inputType, validations);

  const handleDownloadResponse = (file: File) => {
    const blob = new Blob([file]);
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = displayName || getUserFriendlyFilename(field.value);
    tempLink.click();
  };

  const {
    uploadStatus,
    onUpload,
    onValidationFail,
  } = useFileUpload({
    callback: field.onChange,
    url: uploadUrl,
  });

  const { onDownload } = useFileDownload({
    callback: handleDownloadResponse,
    url: downloadUrl instanceof Function ? downloadUrl() : downloadUrl,
  });

  const maxFileSize = useMemo(
    () => megaBytesToBytes(Number(validations?.maxFileSize)),
    [validations],
  );

  const chooseLabel = validations?.allowedExtensions?.length
    ? t('common.label-choose-file-with-extensions', {
      extensions: validations?.allowedExtensions?.join(', '),
    }) : t('common.label-choose-file');

  return (
    <div className="file-input-wrapper">
      { field.value ? (
        <div className="uploaded-file">
          <div className="content">
            <CheckCircle color="#059669" />
            <span className="filename">{displayName || getUserFriendlyFilename(field.value)}</span>
          </div>
          <div className="flex align-items-stretch">
            { downloadUrl && (
            <Button
              type="button"
              icon="pi pi-download"
              className="border-noround"
              text
              aria-label="Download file"
              onClick={onDownload}
            />
            )}
            <Button
              type="button"
              icon="pi pi-times"
              className="remove-file-btn"
              severity="danger"
              text
              aria-label="Remove file"
              onClick={() => field.onChange(null)}
            />
          </div>
        </div>
      ) : (
        <>
          <FileUpload
            id={field.name}
            mode="basic"
            name={field.name}
            accept={accept}
            uploadHandler={onUpload}
            onValidationFail={onValidationFail}
            customUpload
            auto
            chooseLabel={chooseLabel}
            maxFileSize={maxFileSize}
            disabled={isAttributeDisabled(attribute)}
            className={classNames({ 'p-invalid': fieldState?.error })}
          />
          {uploadStatus.isUploading && (
          <div className="upload-overlay animate fade">
            <ProgressBar value={uploadStatus.progress} />
          </div>
          )}
        </>
      )}
      { !!maxFileSize && (
        <small id={`${field.name}-help`} className="block p-text-secondary">
          {t('validations.label-maximum-file-size', {
            size: formatBytes(maxFileSize, 2),
          })}
        </small>
      )}
    </div>
  );
}
