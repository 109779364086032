import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OfferAuthorization } from '../api/_generated_';
import { HttpRequestStatus } from '../components/types/types';
import { USER_AUTHORIZATIONS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

export default function useAuthorizations() {
  const { t } = useTranslation();

  const { id } = useParams();

  const axios = useAxiosPrivate();

  const { toast } = useUIStore();

  const [activeAuthorization, setActiveAuthorization] = useState<OfferAuthorization>();

  const queryClient = useQueryClient();

  async function fetchAuthorizations() {
    const res = await axios.get<OfferAuthorization[]>(`/offer-authorizations?offerId=${id}`);
    return res.data;
  }

  async function saveAuthorization(body: OfferAuthorization): Promise<OfferAuthorization> {
    if (body.id) {
      const res = await axios.put<any>(`/offer-authorizations/${body.id}`, body);
      return res.data;
    }

    const res = await axios.post<any>('/offer-authorizations', body);
    return res.data;
  }

  const { status, data } = useQuery([USER_AUTHORIZATIONS], fetchAuthorizations);

  const setPlaceholderAuthorization = () => {
    setActiveAuthorization({
      name: '',
      fileId: '',
      approved: false,
      brands: '',
      authorizingParty: '',
      additionalDetails: '',
    });
  };

  const onSetActiveAuthorization = (authorization: OfferAuthorization) => {
    setActiveAuthorization(authorization);
  };

  const onCancelAddAutorization = () => {
    setActiveAuthorization(undefined);
  };

  const onError = () => {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  };

  const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: saveAuthorization,
    onError,
    onSuccess: (result) => {
      queryClient.setQueryData([USER_AUTHORIZATIONS], (prev: any) => {
        const oldAuthorizations = prev.find((item: OfferAuthorization) => item.id === result.id);

        if (oldAuthorizations) {
          return prev.map((item: any) => (item.id === result.id ? result : item));
        }

        return [...prev, result];
      });
      setActiveAuthorization(undefined);
    },
  });

  const onSaveAuthorization = (formData: OfferAuthorization) => {
    if (JSON.stringify(formData) === JSON.stringify(activeAuthorization)) {
      setActiveAuthorization(undefined);
      return;
    }

    mutate(formData);
  };

  return {
    authorizations: data,
    status: status as HttpRequestStatus,
    isSaving,
    activeAuthorization,
    onSaveAuthorization,
    onSetActiveAuthorization,
    setPlaceholderAuthorization,
    onCancelAddAutorization,
  };
}
