import React from 'react';
import { Button } from 'primereact/button';
import './Header.scss';
import classNames from 'classnames';
import useLayoutStore from '../../stores/LayoutStore';
import { DrawerState } from '../types/types';
import ProfileSection from '../ProfileSection';
import NotificationsMenu from '../NotificationsMenu';
import SupplierButton from '../SupplierButton';

export default function Header() {
  const { drawerState, setDrawerState } = useLayoutStore();

  const isMenuCollapsed = drawerState === DrawerState.COLLAPSED;

  const toggleDrawer = () => {
    setDrawerState(isMenuCollapsed ? DrawerState.OPEN : DrawerState.COLLAPSED);
  };

  const icon = isMenuCollapsed ? 'pi-chevron-right' : 'pi-chevron-left';

  return (
    <div className={classNames({
      'mobile-header': true,
      collapsed: isMenuCollapsed,
    })}
    >
      <Button
        data-testid="toggleDrawerBtn"
        icon="pi pi-bars"
        className="p-button-plain block lg:hidden"
        rounded
        text
        size="small"
        onClick={() => setDrawerState(DrawerState.OPEN)}
      />
      <Button
        data-testid="toggleDrawerBtn"
        icon={`pi ${icon}`}
        className="p-button-plain hidden lg:block"
        rounded
        text
        size="small"
        onClick={toggleDrawer}
      />
      <div className="flex gap-3">
        <SupplierButton />
        <NotificationsMenu />
        <ProfileSection />
      </div>
    </div>
  );
}
