import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MessageRequest, Product } from '../../../api/_generated_';
import MessageForm from '../../../components/MessageForm';
import MessageForum from '../../../components/MessageForum/MessageForum';
import NetworkContainer from '../../../components/NetworkContainer';
import { HttpRequestStatus } from '../../../components/types/types';
import useDeleteProductMessage from '../../../hooks/useDeleteProductMessage';
import useMarkProductMessages from '../../../hooks/useMarkProductMessages';
import useProductMessages from '../../../hooks/useProductMessages';
import useSendProductMessage from '../../../hooks/useSendProductMessage';
import useAuthStore from '../../../stores/AuthStore';

export default function Messages() {
  const product = useOutletContext<Product>();
  const { user } = useAuthStore();

  const { mutate: markMessagesAsRead } = useMarkProductMessages(product.identifier);

  const { mutate: sendMessage } = useSendProductMessage({
    productId: product.identifier,
  });

  const { t } = useTranslation();

  const { data, status } = useProductMessages(product.identifier);
  const { mutate: deleteMessage } = useDeleteProductMessage({
    productId: product.identifier,
  });

  useEffect(() => {
    if (!data || !user) {
      return;
    }

    const hasUnreadMessages = data?.some((msg) => msg.unread && msg.sender
      !== user?.supplier?.externalId);

    if (hasUnreadMessages) {
      markMessagesAsRead();
    }
  }, [data, user, markMessagesAsRead]);

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <h2 className="text-2xl">
        {t('products.title-messages-related', {
          name: `${product.name} (${product.identifier})`,
          interpolation: { escapeValue: false },
        })}
      </h2>
      <MessageForm onSubmit={(body: MessageRequest) => sendMessage(body)} />
      <MessageForum items={data} onDelete={deleteMessage} />
    </NetworkContainer>
  );
}
