import classNames from 'classnames';
import React, { MouseEventHandler, ReactNode } from 'react';
import './CTACard.scss';

type Props = {
  active: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon?: ReactNode;
  label?: string;
  error?: boolean;
  className?: string;
};

export default function CTACard({
  active,
  onClick,
  icon,
  label,
  error,
  className,
}: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames({
        'p-button p-component p-button-secondary cta-card': true,
        error,
        active,
      }, className)}
    >
      {icon}
      <span className="p-button-label">{label}</span>
    </button>
  );
}
