import { useOutletContext } from 'react-router-dom';
import ProductDetail from '../../../components/ProductDetail';
import { Product } from '../../../api/_generated_';

export default function Details() {
  const product = useOutletContext<Product>();
  return (
    <ProductDetail product={product} />
  );
}
