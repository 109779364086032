import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { ALKO_EDU_STATUS } from '../constants/queryKeys';
import { AlkoEduStatus } from '../api/_generated_';

export default function useAlkoEduStatus() {
  const axios = useAxiosPrivate();

  async function fetchSupplierSettings(): Promise<AlkoEduStatus> {
    const res = await axios.get('/supplier-settings/alko-edu-status');
    return res.data;
  }

  return useQuery({
    queryKey: [ALKO_EDU_STATUS],
    queryFn: fetchSupplierSettings,
  });
}
