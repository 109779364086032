import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { ToggleButtonChangeEvent } from 'primereact/togglebutton';
import { DropdownProps } from 'primereact/dropdown';
import { PRODUCT_LIST } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import { Page } from '../api/_generated_';
import useDebounce from './useDebounce';

export default function useProducts() {
  const axios = useAxiosPrivate();

  const [pageState, setPageState] = useState<PaginatorPageChangeEvent>({
    first: 0,
    rows: 20,
    page: 0,
    pageCount: 0,
  });

  const [keyword, setKeyword] = useState('');

  const [productNumber, setProductNumber] = useState('');

  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  const [isDolly, setIsDolly] = useState(false);

  const [onlyDollyPossibilities, setOnlyDollyPossibilities] = useState(false);

  const [productStatus, setProductStatus] = useState(null);

  const debouncedKeyword = useDebounce(keyword, 500);

  const debouncedProductNumber = useDebounce(productNumber, 500);

  async function getProducts({ queryKey }: any): Promise<Page> {
    const pageParam = queryKey[1];
    const search = queryKey[2];
    const pendingChanges = queryKey[3];
    const onlyDollies = queryKey[4];
    const sku = queryKey[5];
    const status = queryKey[6];

    const urlParams = new URLSearchParams();
    urlParams.append('page', pageParam.page.toString());
    urlParams.append('hasPendingChanges', pendingChanges.toString());
    urlParams.append('isDolly', onlyDollies.toString());
    urlParams.append('onlyDollyPossibles', onlyDollyPossibilities.toString());

    if (sku) {
      urlParams.append('productNumber', sku);
    }

    if (search) {
      urlParams.append('search', search);
    }

    if (status) {
      urlParams.append('status', status);
    }

    const res = await axios.get<Page>(`/products?${urlParams.toString()}`);
    return res.data;
  }

  const {
    data: result, status, isFetching,
  } = useQuery({
    queryKey: [
      PRODUCT_LIST,
      pageState,
      debouncedKeyword,
      hasPendingChanges,
      isDolly,
      debouncedProductNumber,
      productStatus,
      onlyDollyPossibilities,
    ],
    queryFn: getProducts,
    keepPreviousData: true,
  });

  const onChangePage = useCallback(
    (e: PaginatorPageChangeEvent) => {
      setPageState(e);
    },
    [],
  );

  const onChangeStatus = ({ value }: DropdownProps) => {
    setProductStatus(value);
  };

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const onChangeProductNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductNumber(e.target.value);
  };

  const handleClearFilter = () => {
    setKeyword('');
  };

  const onChangePendingChangeFilter = (e: ToggleButtonChangeEvent) => {
    setHasPendingChanges(e.value);
  };

  const onChangeDollyFilter = (e: ToggleButtonChangeEvent) => {
    setIsDolly(e.value);
  };

  const onChangeOnlyDollyPossibilities = (e: ToggleButtonChangeEvent) => {
    setOnlyDollyPossibilities(e.value);
  };

  return {
    isFetching,
    status,
    result,
    keyword,
    hasPendingChanges,
    isDolly,
    onlyDollyPossibilities,
    pageState,
    productNumber,
    productStatus,
    onChangeStatus,
    onChangePage,
    onChangeKeyword,
    onChangePendingChangeFilter,
    onChangeDollyFilter,
    onChangeOnlyDollyPossibilities,
    onChangeProductNumber,
    handleClearFilter,
  };
}
