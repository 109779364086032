import {
  forwardRef, useEffect, useImperativeHandle,
} from 'react';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider } from 'primereact/divider';
import {
  AttributeStructureGroup, AttributeStructureItem, Offer, ValidationErrors,
} from '../../api/_generated_';
import {
  clearSubFields,
  getAttributeValue,
} from '../../utils/attribute';
import PriceCalculator from './PriceCalculator';
import {
  RETAIL_PRICE,
} from '../../constants/pim-attributes';
import OfferSection from '../OfferSection';

function getDefaultValue(
  values: { [key: string]: any } | undefined,
  tenderAttribute: AttributeStructureItem,
) {
  const tenderValue = tenderAttribute.value;

  if (tenderValue !== null && tenderValue !== undefined) {
    return getAttributeValue(tenderAttribute, tenderValue);
  }

  const offerValue = values?.[tenderAttribute.code as string];
  const defaultValue = getAttributeValue(tenderAttribute, offerValue);

  return defaultValue;
}

function getDefaultValues(sections: AttributeStructureGroup[], offer: Offer) {
  const allAttributes: AttributeStructureItem[] | undefined = sections
    ?.flatMap((item) => item.attributes as AttributeStructureItem[]);

  const dynamicValues = allAttributes?.reduce((obj, attr) => ({
    ...obj,
    [attr.code as string]: getDefaultValue(offer.attributes, attr),
  }), {} as Record<string, any>);

  const productionPlantIds = offer.productionPlants?.map((plant) => plant.id?.toString());

  return {
    ...offer.attributes,
    ...dynamicValues,
    productionPlants: productionPlantIds || [],
  } as Record<string, any>;
}

type Props = {
  offer: Offer;
  currentSection: AttributeStructureGroup;
  sections: AttributeStructureGroup[];
  onSubmit: (formData: { [key: string]: any }) => void;
  onGoBack(): void;
  stageIndex: number;
  isSaving: boolean;
  priceCalculationSection: string | undefined;
};

export default forwardRef(({
  offer,
  currentSection,
  sections,
  onSubmit,
  onGoBack,
  stageIndex,
  isSaving,
  priceCalculationSection,
}: Props, ref) => {
  const { t } = useTranslation();

  const defaultValues = getDefaultValues(sections, offer);

  const {
    control,
    watch,
    setError,
    setValue,
    handleSubmit,
    trigger,
  } = useForm<Record<string, any>>({ defaultValues });

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      clearSubFields(setValue, name);
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useImperativeHandle(ref, () => ({
    handleValidationErrors(data: ValidationErrors) {
      Object.keys(data.errors).forEach((key) => {
        setError(key, {
          type: 'server',
          message: t(data.errors[key].message),
        });
      });
    },
  }));

  const onUpdateRetailPrice = (value?: number | null) => {
    setValue(RETAIL_PRICE, value);
    trigger(RETAIL_PRICE);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-5">
        <OfferSection
          group={currentSection}
          offer={offer}
          control={control}
          watch={watch}
        />
        { priceCalculationSection === currentSection.code && (
          <>
            <Divider />
            <PriceCalculator
              formData={watch()}
              offer={offer}
              onUpdateRetailPrice={onUpdateRetailPrice}
            />
          </>
        )}
      </div>
      <div className="flex justify-content-between">
        { stageIndex > 0
          && (
            <Button
              text
              type="button"
              label={t('common.cmd-back')}
              icon="pi pi-arrow-left"
              iconPos="left"
              disabled={isSaving}
              onClick={onGoBack}
            />
          )}
        <Button
          type="submit"
          label={t('common.cmd-save-and-continue')}
          icon="pi pi-arrow-right"
          iconPos="right"
          loading={isSaving}
        />
      </div>
    </form>
  );
});
