import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductionPlant } from '../api/_generated_';
import { PRODUCTION_PLANTS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

type Props = {
  onSuccess?(): void;
};

export default function useSaveProductionPlant({ onSuccess }: Props = {}) {
  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const { toast } = useUIStore();

  const queryClient = useQueryClient();

  async function saveProductionPlant(body: ProductionPlant): Promise<ProductionPlant> {
    if (body.id) {
      const res = await axios.put<ProductionPlant>(`/production-plants/${body.id}`, body);
      return res.data;
    }

    const res = await axios.post<ProductionPlant>('/production-plants', body);
    return res.data;
  }

  const onError = () => {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  };

  return useMutation({
    mutationFn: saveProductionPlant,
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries([PRODUCTION_PLANTS]);
      onSuccess?.();
    },
  });
}
