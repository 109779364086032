import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { UNREAD_MESSAGES_COUNT } from '../constants/queryKeys';

export default function useMarkMessagesAsRead() {
  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  async function onMarkMessagesAsRead() {
    const res = await axios.patch('/messages/mark-as-read');
    return res.data;
  }

  return useQuery({
    queryKey: ['markMessagesAsRead'],
    queryFn: onMarkMessagesAsRead,
    onSuccess: () => {
      queryClient.invalidateQueries([UNREAD_MESSAGES_COUNT]);
    },
  });
}
