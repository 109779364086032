import { useQuery } from '@tanstack/react-query';
import { DollyRequestProduct } from '../api/_generated_';
import useAxiosPrivate from './useAxiosPrivate';

export default function useDollyRequestProduct(id: string | undefined) {
  const axios = useAxiosPrivate();

  async function fetchProduct() {
    const res = await axios.get<DollyRequestProduct>(`/dolly-requests/${id}/product`);
    return res.data;
  }

  return useQuery({
    queryKey: ['dollyRequestProduct', { id }],
    queryFn: fetchProduct,
  });
}
