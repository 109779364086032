import { useCallback } from 'react';
import { DataTable, DataTablePageEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import { AlertCircle, Mail } from 'react-feather';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { Offer } from '../../api/_generated_';
import AppRoutes from '../../constants/routes';
import { HttpRequestStatus, Sort } from '../types/types';
import { CHANGE_OFFER_TENDER_ID, DEFAULT_TENDER_ID, OFFER_TYPE } from '../../constants/pim-attributes';
import NetworkContainer from '../NetworkContainer';
import ListFooter from '../ListFooter';
import { DATE_FORMAT } from '../../i18n/config';

type Props = {
  items: Offer[];
  status: HttpRequestStatus;
  isFetching?: boolean;
  sort?: Sort;
  onPageChange(event: DataTablePageEvent): void;
  onSort?(e: DataTablePageEvent): void;
  totalRecords?: number;
  pageState?: PaginatorPageChangeEvent;
};

export default function OfferList({
  items,
  status,
  isFetching,
  sort,
  onPageChange,
  onSort,
  pageState,
  totalRecords,
}: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onRowClick = useCallback((event: any) => {
    if (event.data.status === 'DRAFT') {
      navigate(`${AppRoutes.OFFERS}/${event.data.uuid}`);
    } else {
      navigate(`${AppRoutes.OFFERS}/detail/${event.data.uuid}`);
    }
  }, [navigate]);

  const formatDate = (date?: string) => dayjs(date).format(DATE_FORMAT);

  const searchIdTemplate = (offer: Offer) => {
    if (offer.attributes && typeof offer.attributes[OFFER_TYPE] === 'string' && offer.attributes[OFFER_TYPE] === '3') {
      return t('offers.type.label-special-order');
    }

    if (!offer?.tenderId || offer?.tenderId === DEFAULT_TENDER_ID) {
      return t('offers.type.label-sale-to-order-selection');
    }

    if (offer?.tenderId === CHANGE_OFFER_TENDER_ID) {
      return t('offers.type.label-m-offer');
    }

    return offer?.tenderId;
  };

  const statusTemplate = (item: Offer) => {
    const tag = item.status?.toLowerCase();
    return (
      <Tag
        className={`p-tag-${tag}`}
        value={t(`offers.label-offer-status-${tag}`)}
      />
    );
  };

  const messageCountTemplate = (item: Offer) => {
    const { unreadMessages } = item;

    return unreadMessages ? (
      <Badge value={unreadMessages} severity="danger" />
    ) : null;
  };

  const pendingSampleRequestsTemplate = (item: Offer) => {
    const { sampleRequests } = item;

    if (!sampleRequests?.length) {
      return null;
    }

    const hasPendingSampleRequests = sampleRequests
      ?.some((sampleRequest) => !sampleRequest.isReceived);

    return hasPendingSampleRequests
      ? t('common.label-yes')
      : null;
  };

  const hasUnsentChangesTemplate = (item: Offer) => {
    const { hasUnsentChanges } = item;
    return hasUnsentChanges
      ? <AlertCircle size={20} />
      : null;
  };

  const footer = (
    <ListFooter
      pageState={pageState}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
    />
  );

  return (
    <NetworkContainer status={status} excludeContainers>
      <DataTable
        onSort={onSort}
        sortField={sort?.field}
        sortOrder={sort?.order}
        loading={isFetching}
        value={items}
        onRowClick={onRowClick}
        breakpoint="991px"
        className="mb-3 link-table"
        footer={footer}
        resizableColumns
      >
        <Column field="status" header={t('offers.label-offer-status')} body={statusTemplate} sortable />
        <Column field="attributes.TuoNimi" header={t('offers.label-product')} />
        <Column field="offerNumber" header={t('offers.label-offer-number')} sortable />
        <Column field="tenderId" header={t('tenders.label-search-identifier')} body={searchIdTemplate} sortable />
        <Column field="createdAt" header={t('common.label-created')} body={(item: Offer) => formatDate(item.createdAt)} sortable />
        <Column header={t('offers.label-pending-sample-requests')} body={pendingSampleRequestsTemplate} style={{ width: '16rem' }} />
        <Column
          field="unreadMessages"
          body={messageCountTemplate}
          header={<Mail size={20} />}
          headerTooltip={t('common.label-unread-messages')}
          headerTooltipOptions={{ position: 'left' }}
          style={{ width: '3rem' }}
        />
        <Column
          body={hasUnsentChangesTemplate}
          style={{ width: '3rem' }}
          header={<AlertCircle size={20} />}
          headerTooltip={t('offers.label-unsent_changes')}
          headerTooltipOptions={{ position: 'left' }}
        />
      </DataTable>
    </NetworkContainer>
  );
}
