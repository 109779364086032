import { useMutation } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';

export default function useMarkProductMessages(productId?: string) {
  const axios = useAxiosPrivate();

  async function onMarkMessagesAsRead() {
    const res = await axios.patch(`/product-messages/${productId}/mark-as-read`);
    return res.data;
  }

  return useMutation(onMarkMessagesAsRead);
}
