import { Message } from 'primereact/message';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LockMessage() {
  const { t } = useTranslation();

  return (
    <Message
      icon="pi pi-lock"
      style={{
        background: '#fff',
        marginBottom: '1rem',
        border: '1px solid #e5e7eb',
        borderLeft: '5px solid #2563EB',
        color: '#2a2a2a',
      }}
      className="w-full"
      text={(
        <>
          {t('common.attribute-lock-message')}
        </>
      )}
    />
  );
}
