import { DataTable } from 'primereact/datatable';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Tag } from 'primereact/tag';
import { DollyRequest } from '../../api/_generated_';
import ListFooter from '../ListFooter';
import { DATE_FORMAT } from '../../i18n/config';

type Props = {
  items: DollyRequest[];
  pageState?: PaginatorPageChangeEvent;
  totalRecords?: number;
  onPageChange(event: PaginatorPageChangeEvent): void;
};

export default function DollyRequestTable({
  items,
  pageState,
  totalRecords,
  onPageChange,
}: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onRowClick = (event: any) => navigate(`/dolly-requests/${event.data.uuid}`);

  const formatDate = (date?: string) => dayjs(date).format(DATE_FORMAT);

  const statusTemplate = (item: DollyRequest) => {
    const tag = item.status?.toLowerCase();
    return (
      <Tag
        className={`p-tag-${tag}`}
        value={t(`products.label-dolly-req-status-${tag}`)}
      />
    );
  };

  const footer = (
    <ListFooter
      pageState={pageState}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
    />
  );

  return (
    <DataTable
      value={items}
      onRowClick={onRowClick}
      className="mb-3 link-table"
      emptyMessage={t('products.label-no-products-found')}
      resizableColumns
      footer={footer}
    >
      <Column field="product.identifier" header="ID" />
      <Column field="product.name" header={t('products.label-product-name')} />
      <Column field="type.label" header={t('products.label-dolly-type')} />
      <Column field="createdAt" header={t('common.label-created')} body={(item: DollyRequest) => formatDate(item.createdAt)} />
      <Column field="status" header={t('offers.label-offer-status')} body={statusTemplate} />
    </DataTable>
  );
}
