import React from 'react';
import Card from '../../../components/Card';
import NetworkContainer from '../../../components/NetworkContainer';
import useSettings from '../../../hooks/useSettings';
import TenderSettingsForm from '../../../components/TenderSettingsForm';
import useAvailableProductGroups from '../../../hooks/useAvailableProductGroups';

export default function Tenders() {
  const {
    settings, status, onChangeSetting,
  } = useSettings();

  const { data: productGroups } = useAvailableProductGroups();

  return (
    <Card>
      <NetworkContainer status={status} excludeContainers>
        <TenderSettingsForm
          settings={settings?.tenders}
          productGroups={productGroups || []}
          onChange={onChangeSetting}
        />
      </NetworkContainer>
    </Card>
  );
}
