import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Message, MessageRequest } from '../api/_generated_';
import { MESSAGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

export default function useSendMessage() {
  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  const { toast } = useUIStore();

  async function saveMessage(data: MessageRequest) {
    const res = await axios.post<Message>('/messages', data);
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  return useMutation({
    mutationFn: saveMessage,
    onError,
    onSuccess: (newMessage) => {
      queryClient.setQueryData([MESSAGES], (oldData: any) => {
        const firstPage = oldData.pages[0];
        return {
          pages: [
            {
              ...firstPage,
              data: [newMessage, ...firstPage.data],
            },
            ...oldData.pages.slice(1),
          ],
        };
      });
    },
  });
}
