import React from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { DynamicFormFieldProps } from '../../types/types';
import { getDynamicFieldTooltip } from '../../../utils/helper';
import { isAttributeDisabled } from '../../../utils/attribute';

type Props = DynamicFormFieldProps;

export default function TextInput({
  attribute,
  field,
  fieldState,
}: Props) {
  return (
    <InputText
      {...field}
      id={field.name}
      className={classNames({ 'p-invalid': fieldState.error })}
      disabled={isAttributeDisabled(attribute)}
      {...getDynamicFieldTooltip(attribute)}
    />
  );
}
