import React, { useState } from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Contact } from '../../api/_generated_';

type Props = {
  contact?: Contact;
  isSaving: boolean;
  onSubmit(data: any): void;
  onCancel(): void;
  onDelete(id: number): void;
};

export default function ContactForm({
  contact,
  isSaving,
  onSubmit,
  onCancel,
  onDelete,
}: Props) {
  const { t } = useTranslation();

  const [, setShowConfirmDialog] = useState(false);

  const onConfirm = () => {
    confirmDialog({
      header: t('common.title-confirm-deletion'),
      message: t('common.label-confirm-deletion-description'),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => onDelete(contact?.id as number),
      reject: () => setShowConfirmDialog(false),
    });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      id: contact?.id,
      lastName: contact?.lastName || '',
      firstName: contact?.firstName || '',
      email: contact?.email || '',
      phone: contact?.phone || '',
    },
  });

  const getFormErrorMessage = (name: keyof Contact) => errors[name] && <small className="p-error">{errors?.[name]?.message}</small>;

  return (
    <div>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <input type="hidden" name={field.name} value={field.value} />
        )}
      />
      {/* Firstname */}
      <div className="field">
        <label htmlFor="firstName" className={classNames({ 'p-error': !!errors.firstName })}>{t('common.label-first-name')}</label>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: t('validations.message-field-required') }}
          render={({ field, fieldState }) => (
            <InputText data-testid={field.name} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
          )}
        />
        {getFormErrorMessage('firstName' as keyof Contact)}
      </div>
      {/* Lastname */}
      <div className="field">
        <label htmlFor="lastName" className={classNames({ 'p-error': !!errors.lastName })}>{t('common.label-last-name')}</label>
        <Controller
          name="lastName"
          control={control}
          rules={{ required: t('validations.message-field-required') }}
          render={({ field, fieldState }) => (
            <InputText data-testid={field.name} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
          )}
        />
        {getFormErrorMessage('lastName' as keyof Contact)}
      </div>
      {/* Email */}
      <div className="field">
        <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>{t('common.label-email')}</label>
        <div className="p-input-icon-right w-full">
          <i className="pi pi-envelope" />
          <Controller
            name="email"
            control={control}
            rules={{
              required: t('validations.message-field-required'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t('validations.message-valid-email-address'),
              },
            }}
            render={({ field, fieldState }) => (
              <InputText data-testid={field.name} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
            )}
          />
        </div>
        {getFormErrorMessage('email' as keyof Contact)}
      </div>
      {/* Phone */}
      <div className="field mb-5">
        <label htmlFor="phone" className={classNames({ 'p-error': !!errors.phone })}>{t('common.label-phone')}</label>
        <div className="p-input-icon-right w-full">
          <i className="pi pi-phone" />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: t('validations.message-field-required'),
            }}
            render={({ field, fieldState }) => (
              <InputText data-testid={field.name} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} />
            )}
          />
        </div>
        {getFormErrorMessage('phone' as keyof Contact)}
      </div>
      <div className="flex justify-content-between">
        { contact?.id
          && (
          <Button
            type="button"
            label={t('common.cmd-delete')}
            className="mr-3"
            severity="danger"
            outlined
            onClick={onConfirm}
            disabled={isSaving}
          />
          )}
        <div className="ml-auto text-right">
          <Button
            type="button"
            label={t('common.cmd-cancel')}
            className="mr-3"
            onClick={onCancel}
            disabled={isSaving}
            severity="secondary"
          />
          <Button
            type="button"
            label={t('common.cmd-save')}
            loading={isSaving}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
}
