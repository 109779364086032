import { Toast } from 'primereact/toast';
import { RefObject, createRef } from 'react';
import { create } from 'zustand';

interface UIState {
  toast: RefObject<Toast>;
}

const useUIStore = create<UIState>()(() => ({
  toast: createRef<Toast>(),
}));

export default useUIStore;
