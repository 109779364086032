import React from 'react';
import { AttributeStructureGroup } from '../../api/_generated_';
import Steps from '../Steps';
import './ViewHeader.scss';

type Props = {
  title?: string;
  description?: string;
  currentStage?: AttributeStructureGroup;
  stages?: string[];
  children?: any;
};

function getTitle(stage: AttributeStructureGroup | undefined, title?: string) {
  return stage
    ? stage?.translation || `[${stage?.code}]`
    : title;
}

export default function ViewHeader({
  title,
  description,
  currentStage,
  stages,
  children,
}: Props) {
  return (
    <div>
      <div className="flex flex-wrap gap-5 md:justify-content-between mb-5">
        <h1 className="mb-0">{getTitle(currentStage, title)}</h1>
        { children && (
          <div className="flex gap-3 header-toolbar">
            {children}
          </div>
        )}
      </div>
      {(currentStage && stages && stages.length > 1) && (
        <Steps currentStage={currentStage} stages={stages} />
      )}
      { description && <p className="view-description mb-6">{description}</p>}
    </div>
  );
}
