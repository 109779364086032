import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';
import { NotificationSettings, NotificationSettingsTenderReminderIntervalTypeEnum } from '../../api/_generated_';
import SettingSwitch from '../SettingSwitch';

type Props = {
  settings?: NotificationSettings;
  onChange(group: string, key: string, value: any): void;
};

export default function NotificationsForm({ settings, onChange }: Props) {
  const { t } = useTranslation();

  const intervalTypes = Object.values(NotificationSettingsTenderReminderIntervalTypeEnum)
    .map((item) => ({
      label: t(`common.label-interval-${item.toLowerCase()}`),
      value: item,
    }));

  return (
    <>
      <SettingSwitch
        id="emailNotificationsAllowed"
        checked={settings?.emailNotificationsAllowed}
        label={t('settings.label-email-notifications-allowed')}
        onChange={(e) => onChange('notifications', 'emailNotificationsAllowed', e.value)}
        bordered
      />
      { settings?.emailNotificationsAllowed && (
      <>
        <SettingSwitch
          id="emailProductChanges"
          checked={settings?.emailProductChanges}
          label={t('settings.label-email-product-changes')}
          help={t('settings.label-email-product-changes-description')}
          onChange={(e) => onChange('notifications', 'emailProductChanges', e.value)}
          bordered
        />
        <SettingSwitch
          id="emailOfferStatusChanges"
          checked={settings?.emailOfferStatusChanges}
          label={t('settings.label-email-offer-status-changes')}
          help={t('settings.label-email-offer-status-changes-description')}
          onChange={(e) => onChange('notifications', 'emailOfferStatusChanges', e.value)}
          bordered
        />
        <SettingSwitch
          id="emailOfInterestingTenders"
          checked={settings?.emailOfInterestingTenders}
          label={t('settings.label-email-of-interesting-tenders')}
          help={t('settings.label-email-of-interesting-tenders-description')}
          onChange={(e) => onChange('notifications', 'emailOfInterestingTenders', e.value)}
          bordered
        />
        <SettingSwitch
          id="emailTenderRemaindersAllowed"
          checked={settings?.emailTenderRemaindersAllowed}
          label={t('settings.label-tender-reminders-allowed')}
          help={t('settings.label-tender-reminders-allowed-description')}
          onChange={(e) => onChange('notifications', 'emailTenderRemaindersAllowed', e.value)}
          bordered
        />
        <div>
          <label htmlFor="inputId" className="label-lg">{t('settings.label-tender-reminders-interval')}</label>
          <div className="p-inputgroup mb-3">
            <InputNumber
              inputId="inputId"
              step={1}
              inputClassName="input-inversed"
              value={settings?.tenderReminderInterval}
              onBlur={(e) => onChange('notifications', 'tenderReminderInterval', e.target.value)}
              disabled={!settings?.emailTenderRemaindersAllowed}
            />
            <Dropdown
              placeholder="Select a City"
              panelClassName="input-reversed"
              className="input-inversed max-w-14rem"
              options={intervalTypes}
              value={settings?.tenderReminderIntervalType}
              onChange={(e) => onChange('notifications', 'tenderReminderIntervalType', e.value)}
              disabled={!settings?.emailTenderRemaindersAllowed}
            />
          </div>
          <span className="form-help">{t('settings.label-tender-reminders-interval-description')}</span>
        </div>
      </>
      )}
    </>
  );
}
