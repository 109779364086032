import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from './useAxiosPrivate';
import useAuthStore from '../stores/AuthStore';
import { User } from '../api/_generated_';

const useSupplier = () => {
  const { user, setUser } = useAuthStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const axios = useAxiosPrivate();

  async function setSupplier(supplierId: string) {
    const res = await axios.put<User>('/user/supplier', { supplierId });
    return res.data;
  }

  const mutation = useMutation({
    mutationFn: setSupplier,
    onSuccess: (data) => {
      setUser(data);
      queryClient.clear();
      navigate('/');
    },
  });

  return { supplier: user?.supplier, setSupplier: mutation.mutate };
};

export default useSupplier;
