import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FilterItem } from '../api/_generated_';
import { DEPOSIT_OPTIONS } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useDepositOptions() {
  const { i18n } = useTranslation();

  const axios = useAxiosPrivate();

  async function fetchDepositOptions() {
    const res = await axios.get<FilterItem[]>(`/distributor/deposit-options?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: [DEPOSIT_OPTIONS, i18n.language],
    queryFn: fetchDepositOptions,
    staleTime: 1000 * 60 * 15,
  });
}
