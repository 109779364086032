import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import Drawer from '../components/Drawer';
import Main from '../components/Main';
import useAuthStore from '../stores/AuthStore';
import { AttributeConfigProvider } from './AttributeConfigProvider';

function RequireAuth() {
  const isAuthenticated = useIsAuthenticated();
  const { user } = useAuthStore();

  const location = useLocation();

  return (isAuthenticated && user) ? (
    <AttributeConfigProvider>
      <Main>
        <Drawer />
        <Outlet />
      </Main>
    </AttributeConfigProvider>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default RequireAuth;
