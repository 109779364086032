import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { SUPPLIER_SETTINGS } from '../constants/queryKeys';

export default function useSaveSupplierSettings() {
  const axios = useAxiosPrivate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  const queryClient = useQueryClient();

  async function saveSupplierSettings(
    body: { [key: string]: any; },
  ): Promise<void> {
    const res = await axios.put('/supplier-settings/general', body);
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  function onSuccess() {
    toast.current?.show({
      severity: 'success',
      summary: t('settings.message-settings-saved'),
    });
  }

  return useMutation({
    mutationFn: saveSupplierSettings,
    onError,
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries([SUPPLIER_SETTINGS]);
    },
  });
}
