import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionTab } from 'primereact/accordion';
import useDollies from '../../hooks/useDollies';
import AppRoutes from '../../constants/routes';
import NetworkContainer from '../NetworkContainer';
import { HttpRequestStatus } from '../types/types';
import { AttributeValue, Product } from '../../api/_generated_';
import DataRow from '../DataRow';
import { DOLLY_ATTRIBUTES } from '../../constants/pim-attributes';
import { getBareValue, getLabel } from '../../utils/attribute';

type Props = {
  mainProduct: Product;
};

export default function Dollies({ mainProduct }: Props) {
  const { t } = useTranslation();

  const { data, status } = useDollies();

  const activeIndexes = useMemo(() => data?.map((_, index) => index), [data]);

  return (
    <NetworkContainer status={status as HttpRequestStatus} excludeContainers>
      <DollyDetails mainProduct={mainProduct} />
      <Accordion multiple activeIndex={activeIndexes}>
        {data?.map((item) => (
          <AccordionTab
            key={item.identifier}
            data-testid="dolly-item"
            header={item.identifier}
          >
            <p>
              <Link to={`${AppRoutes.PRODUCTS}/${item.identifier}`} className="underline">
                {item.identifier}
                {' '}
                -
                {' '}
                {item.name}
              </Link>
            </p>
            <p>{item.type}</p>
            <span>
              {t('common.label-quantity', {
                quantity: item.quantity,
              })}
            </span>
          </AccordionTab>
        ))}
      </Accordion>
    </NetworkContainer>
  );
}

function DollyDetails({ mainProduct }: Props) {
  const { t } = useTranslation();

  const attributes = useMemo(() => {
    const allAttributes = mainProduct.details?.reduce((acc, group) => ({
      ...acc,
      ...group.values,
    }), {} as Record<string, AttributeValue>);

    return Object.keys(allAttributes)
      .sort((a, b) => DOLLY_ATTRIBUTES.indexOf(a) - DOLLY_ATTRIBUTES.indexOf(b))
      .filter((key) => DOLLY_ATTRIBUTES.includes(key))
      .reduce((obj, key) => ({
        ...obj,
        [key]: allAttributes[key],
      }), {} as Record<string, AttributeValue>);
  }, [mainProduct?.details]);

  return (
    <div className="p-1 md:p-3">
      <div className="grid">
        { Object.keys(attributes || {}).map((key) => (
          <DataRow
            key={`dolly-attribute-${key}`}
            label={getLabel(attributes[key])}
            value={getBareValue(attributes[key], t)}
            className="col-12 md:col-6 mb-2"
          />
        ))}
      </div>
    </div>
  );
}
