import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';
import { Notification } from '../../api/_generated_';
import NotificationItem from '../NotificationItem';
import { HttpRequestStatus } from '../types/types';

type Props = {
  items?: Notification[];
  status?: HttpRequestStatus;
};

export default function NotificationList({
  items,
  status,
}: Props) {
  const { t } = useTranslation();

  if (HttpRequestStatus.ERROR === status) {
    return <span>{t('common.message-notification-fetch-failed')}</span>;
  }

  if (HttpRequestStatus.LOADING === status) {
    return <Skeleton height="0.5rem" />;
  }

  if (!items?.length) {
    return (
      <div>
        <span>{t('common.label-no-new-notifications')}</span>
      </div>
    );
  }

  return (
    <div>
      { items?.map((item) => (
        <NotificationItem key={item.id} item={item} />
      ))}
    </div>
  );
}
