import React from 'react';
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';
import useSuppliers from '../../hooks/useSuppliers';
import { SupplierDto } from '../../api/_generated_';

type Props = {
  onChange: (value: SupplierDto | null) => void;
};

export default function SupplierList({ onChange }: Props) {
  const { data } = useSuppliers();

  const handleSelect = (event: ListBoxChangeEvent) => {
    const resulut = data?.find((supplier) => supplier.code === event.value) || null;
    onChange(resulut);
  };

  return (
    <ListBox
      filter
      listStyle={{ maxHeight: '250px' }}
      options={data}
      optionLabel="name"
      optionValue="code"
      onChange={handleSelect}
    />
  );
}
