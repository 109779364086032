import React from 'react';
import { Paginator, PaginatorCurrentPageReportOptions, PaginatorPageChangeEvent } from 'primereact/paginator';
import { useTranslation } from 'react-i18next';

function CurrentPageReport(options: PaginatorCurrentPageReportOptions) {
  const { t } = useTranslation();

  return (
    <div className="text-right ml-auto">
      <span className="block">
        {t('common.label-current-page', {
          currentPage: options?.currentPage,
          totalPages: options?.totalPages,
        })}
      </span>
      <span>
        {t('common.label-total-records', {
          totalRecords: options?.totalRecords,
        })}
      </span>
    </div>
  );
}

type Props = {
  onPageChange?(event: PaginatorPageChangeEvent): void
  pageState?: PaginatorPageChangeEvent;
  totalRecords?: number;
  onlyPageReport?: boolean;
};

export default function ListFooter({
  pageState,
  onPageChange,
  totalRecords,
  onlyPageReport,
}: Props) {
  if (!pageState) {
    return <div />;
  }

  const {
    rows, first,
  } = pageState;

  const template = onlyPageReport ? {
    CurrentPageReport,
  } : {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
    CurrentPageReport,
  };

  return (
    <Paginator
      className="justify-content-start p-0"
      first={first}
      rows={rows}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
      template={template}
    />
  );
}
