import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Trash2 } from 'react-feather';
import { confirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';

type Props = {
  toolbarDisabled: boolean;
  onDeleteOffer(): void;
};

export default function FillOfferToolbar({
  toolbarDisabled,
  onDeleteOffer,
}: Props) {
  const { t } = useTranslation();

  const [, setShowConfirmDialog] = useState(false);

  const onConfirmDelete = () => {
    confirmDialog({
      header: t('common.title-confirm-deletion'),
      message: t('common.label-confirm-deletion-description'),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => onDeleteOffer(),
      reject: () => setShowConfirmDialog(false),
    });
  };

  return (
    <div className="flex gap-3">
      <Button
        severity="secondary"
        size="small"
        disabled={toolbarDisabled}
        onClick={onConfirmDelete}
      >
        <Trash2 size={20} color="#B02017" />
      </Button>
    </div>
  );
}
