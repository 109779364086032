import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { AlkoEduStatusStatusEnum } from '../../api/_generated_';
import Container from '../../components/Container';
import ViewHeader from '../../components/ViewHeader';
import useAlkoEduStatus from '../../hooks/useAlkoEduStatus';

export default function EduNotCompleted() {
  const location = useLocation();

  const route = location.state?.from || '/';

  const { t } = useTranslation();

  const { data } = useAlkoEduStatus();

  if (data?.status === AlkoEduStatusStatusEnum.Approved) {
    return (
      <Navigate to={route} replace />
    );
  }

  return (
    <Container size="sm">
      <ViewHeader title={t('alko-edu.title')} />
      <p className="mb-5">
        {t('alko-edu.not-completed-description')}
      </p>
    </Container>
  );
}
