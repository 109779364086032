import React from 'react';
import { XOctagon } from 'react-feather';
import { useTranslation } from 'react-i18next';

type Props = {
  summary?: string;
  detail?: string;
};

export default function ErrorMessage({
  summary,
  detail,
}: Props) {
  const { t } = useTranslation();

  return (
    <div data-testid="inline-error-message" className="p-message p-message-error">
      <div className="p-message-wrapper">
        <div className="mr-4 flex align-items-center">
          <XOctagon />
        </div>
        <div>
          <span className="block font-bold">{summary || t('common.error.label-fetch-error')}</span>
          <span>{detail || t('common.error.label-fetch-error-description')}</span>
        </div>
      </div>
    </div>
  );
}
