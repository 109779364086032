import { HTMLAttributes, PropsWithChildren } from 'react';
import { composeClasses } from '../../utils/styles';

type Props = HTMLAttributes<HTMLDivElement>;

export default function Card({
  children, className, ...rest
}: PropsWithChildren<Props>) {
  const classes = composeClasses('bg-white p-3 md:p-6 border-round-xl border-surface', className);

  return (
    <div className={classes} {...rest}>{children}</div>
  );
}
