import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OfferFrame } from '../api/_generated_';
import { OFFER_FRAME } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useOfferFrame() {
  const { id: uuid } = useParams();

  const { i18n } = useTranslation();

  const axios = useAxiosPrivate();

  async function fetchOfferFrame() {
    const res = await axios.get<OfferFrame>(`/offers/${uuid}/frame?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: [OFFER_FRAME, uuid],
    queryFn: fetchOfferFrame,
  });
}
