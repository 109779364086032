import React from 'react';
import classNames from 'classnames';
import { useController } from 'react-hook-form';
import { InputNumber, InputNumberProps } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from '../../FormErrorMessage';
import { StaticFormFieldProps } from '../../types/types';

type Props = StaticFormFieldProps & InputNumberProps;

export default function NumberField({
  name,
  control,
  required,
  rules,
  label,
  help,
  ...rest
}: Props) {
  const { i18n } = useTranslation();

  const { field, fieldState } = useController({
    name: name as string,
    control,
    rules,
  });

  return (
    <div className="field">
      { label && <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>{label}</label>}
      <InputNumber
        data-testid={field.name}
        onChange={(e) => field.onChange(e.value)}
        id={field.name}
        value={field.value}
        className={classNames({ 'w-full': true, 'p-invalid': fieldState.error })}
        locale={i18n.language}
        {...rest}
      />
      {help && <span className="form-help">{help}</span> }
      <FormErrorMessage label={fieldState?.error?.message} />
    </div>
  );
}
