import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { Button } from 'primereact/button';
import { Trash } from 'react-feather';
import { confirmDialog } from 'primereact/confirmdialog';
import ViewHeader from '../../components/ViewHeader';
import ProductionPlantForm from '../../components/ProductionPlantForm';
import useProductionPlantFieldOptions from '../../hooks/useProductionPlantFieldOptions';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import useSaveProductionPlant from '../../hooks/useSaveProductionPlant';
import AppRoutes from '../../constants/routes';
import { ProductionPlant } from '../../api/_generated_';
import useProductionPlant from '../../hooks/useProductionPlant';
import useDeleteProductionPlant from '../../hooks/useDeleteProductionPlant';

export default function UpdateProductionPlant() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, status } = useProductionPlantFieldOptions();

  const { mutate, status: savingStatus } = useSaveProductionPlant({
    onSuccess: () => navigate(AppRoutes.PRODUCTION_PLANTS),
  });

  const {
    mutate: deleteProductionPlant,
    status: deleteStatus,
  } = useDeleteProductionPlant({
    onSuccess: () => navigate(AppRoutes.PRODUCTION_PLANTS),
  });

  const { id } = useParams();

  const { data: productionPlant, status: productionPlantStatus } = useProductionPlant({ id });

  const onCancel = () => navigate(AppRoutes.PRODUCTION_PLANTS);

  const onSubmit = (values: ProductionPlant) => mutate(values);

  const onConfirmDelete = () => {
    confirmDialog({
      header: t('common.title-confirm-deletion'),
      message: t('production-plants.label-confirm-description'),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => deleteProductionPlant(String(productionPlant?.id)),
      style: { maxWidth: '50%' },
    });
  };

  const loading = useMemo(() => [savingStatus, deleteStatus].some((s) => s === 'loading'), [savingStatus, deleteStatus]);

  return (
    <NetworkContainer status={[status, productionPlantStatus] as HttpRequestStatus[]}>
      <ViewHeader title={t('production-plants.cmd-edit-supply-chain')}>
        <Button
          severity="secondary"
          tooltip={t('common.cmd-delete')}
          tooltipOptions={{ position: 'bottom' }}
          type="button"
          onClick={productionPlant ? onConfirmDelete : undefined}
          disabled={loading}
        >
          <Trash size={20} />
        </Button>
      </ViewHeader>
      { productionPlant && (
      <ProductionPlantForm
        item={productionPlant}
        data={data}
        loading={loading}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
      )}
    </NetworkContainer>
  );
}
