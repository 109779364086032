import React, { ReactNode } from 'react';
import DownloadButton from '../DownloadButton';
import './DataRow.scss';
import LockLabel from '../LockLabel';

type Props = {
  label?: string;
  value?: string | null;
  lockDuration?: number;
  isImage?: boolean;
  isFile?: boolean;
  className?: string;
  downloadUrl?: string;
  actions?: ReactNode
};

export default function DataRow({
  label,
  value,
  lockDuration,
  isImage,
  isFile,
  downloadUrl,
  actions,
  ...rest
}: Props) {
  return (
    <div {...rest}>
      <span className="font-medium block line-height-3">
        {label}
      </span>
      {(isImage && value?.startsWith('http'))
        ? (
          <>
            <img src={value} alt={label} className="data-row-image" />
            {downloadUrl && <DownloadButton value={value} downloadUrl={downloadUrl} />}
          </>
        ) : (
          <span className="block line-height-4" style={{ wordBreak: 'break-all' }}>
            {value}
            <LockLabel lockDuration={lockDuration} />
            {(isFile || isImage) && downloadUrl
              ? <DownloadButton value={value as string} downloadUrl={downloadUrl} />
              : null}
            {actions}
          </span>
        )}
    </div>
  );
}
