import { useQuery } from '@tanstack/react-query';
import { ProductMessage } from '../api/_generated_';
import { PRODUCT_MESSAGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useProductMessages(productId?: string) {
  const axios = useAxiosPrivate();

  async function fetchProductMessages() {
    const res = await axios.get<ProductMessage[]>(`/product-messages/${productId}`);
    return res.data;
  }

  return useQuery([PRODUCT_MESSAGES, productId], fetchProductMessages);
}
