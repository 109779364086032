import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  AlertCircle, Edit,
} from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { Product } from '../../api/_generated_';
import { ProductChange } from '../types/types';
import { getUniqueProposals } from '../../utils/attribute';
import AppRoutes from '../../constants/routes';
import { DATE_FORMAT } from '../../i18n/config';

type Props = {
  product?: Product,
};

export default function PendingProductChanges({ product }: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  if (!product?.pendingChanges?.length) {
    return null;
  }

  const groups = product?.details;

  const pendingChanges = getUniqueProposals(groups, product.pendingChanges);

  const onRowClick = (item: DataTableRowClickEvent) => {
    const proposalUrl = `${AppRoutes.PRODUCT_CHANGE}/${product.identifier}/${item.data.requestId}`;
    if (item.data.editable) {
      navigate(proposalUrl);
    } else {
      navigate(`${proposalUrl}/preview`);
    }
  };

  const formatDate = (date?: string) => dayjs(date).format(DATE_FORMAT);

  const changesTemplate = (item: ProductChange) => {
    const { changes = [] } = item;

    return t('common.label-pieces', {
      count: changes.length,
    });
  };

  const editableTemplate = (item: ProductChange) => {
    const { editable } = item;

    if (editable) {
      return <Edit size={16} color="#B02017" />;
    }

    return null;
  };

  const tableHeader = (
    <div className="flex gap-3">
      <AlertCircle />
      <span className="font-bold">
        {t('products.title-pending-changes', {
          count: pendingChanges?.length,
        })}
      </span>
    </div>
  );

  return (
    <DataTable
      value={pendingChanges}
      header={tableHeader}
      onRowClick={onRowClick}
      className="link-table mb-5"
      resizableColumns
    >
      <Column header={t('products.label-change-targets')} body={changesTemplate} />
      <Column field="effective" header={t('products.label-effective-since')} body={(item: ProductChange) => formatDate(item.effective)} />
      <Column field="created" header={t('common.label-created')} body={(item: ProductChange) => formatDate(item.created)} />
      <Column body={editableTemplate} className="text-center" />
    </DataTable>
  );
}
