import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ViewHeader from '../../components/ViewHeader';
import ProductionPlantForm from '../../components/ProductionPlantForm';
import useProductionPlantFieldOptions from '../../hooks/useProductionPlantFieldOptions';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import useSaveProductionPlant from '../../hooks/useSaveProductionPlant';
import AppRoutes from '../../constants/routes';
import { ProductionPlant } from '../../api/_generated_';

export default function CreateProductionPlant() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, status } = useProductionPlantFieldOptions();

  const { mutate, isLoading: isSaving } = useSaveProductionPlant({
    onSuccess: () => navigate(AppRoutes.PRODUCTION_PLANTS),
  });

  const onCancel = () => navigate(AppRoutes.PRODUCTION_PLANTS);

  const onSubmit = (values: ProductionPlant) => mutate(values);

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <ViewHeader
        title={t('production-plants.cmd-add-supply-chain')}
      />
      <ProductionPlantForm
        data={data}
        loading={isSaving || status === 'loading'}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </NetworkContainer>
  );
}
