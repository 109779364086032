import React from 'react';
import classNames from 'classnames';
import { CompareItem } from '../types/types';
import { composeClasses } from '../../utils/styles';
import { getEmptyChar } from '../../utils/attribute';
import './CompareListItem.scss';
import DownloadButton from '../DownloadButton';

type Props = {
  item: CompareItem;
  className?: string;
};

export default function CompareListItem({ item, className }: Props) {
  const classes = composeClasses('compare-list-item bg-white border-round-xl border-surface p-3 flex align-items-center justify-content-between gap-3', className);

  function isNotEmpty(value: string | null | undefined) {
    return value !== null && value !== '' && getEmptyChar() !== value;
  }

  const hasOldValue = isNotEmpty(item.oldValue);

  return (
    <div className={classes}>
      <div className="">
        <span className="block font-medium mb-1">{item.label || `[${item.code}]`}</span>
        { hasOldValue && <span className="line-through old-value">{item.oldValue}</span> }
        { isNotEmpty(item.newValue) && (
        <span className={classNames({
          'new-value': true,
          'ml-3': hasOldValue,
        })}
        >
          {item.newValue}
          {item.isFile && (
            <DownloadButton
              value={item.newValue as string}
              downloadUrl={item.downloadUrl}
            />
          )}
        </span>
        )}
      </div>
    </div>
  );
}
