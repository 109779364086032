import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from 'primereact/togglebutton';
import { SelectItem } from 'primereact/selectitem';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import NetworkContainer from '../../components/NetworkContainer';
import ProductTable from '../../components/ProductGrid';
import SearchInput from '../../components/SearchInput';
import { HttpRequestStatus } from '../../components/types/types';
import ViewHeader from '../../components/ViewHeader';
import useProducts from '../../hooks/useProducts';
import './Products.scss';
import useFileDownload from '../../hooks/useFileDownload';
import { PRODUCT_STATUS_ACTIVE, PRODUCT_STATUS_DISCONTINUED, PRODUCT_STATUS_WAITING_FOR_SELECTION } from '../../constants/pim-attribute-options';

const STATUS_OPTIONS = [
  PRODUCT_STATUS_ACTIVE,
  PRODUCT_STATUS_DISCONTINUED,
  PRODUCT_STATUS_WAITING_FOR_SELECTION,
];

export default function Products() {
  const { t } = useTranslation();
  const {
    result,
    status,
    pageState,
    keyword,
    hasPendingChanges,
    isDolly,
    onlyDollyPossibilities,
    isFetching,
    productNumber,
    productStatus,
    onChangeStatus,
    onChangePage,
    onChangeKeyword,
    onChangePendingChangeFilter,
    onChangeDollyFilter,
    onChangeOnlyDollyPossibilities,
    onChangeProductNumber,
    handleClearFilter,
  } = useProducts();

  const handleDownloadResponse = (file: File) => {
    const blob = new Blob([file]);
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = `products_spa_${new Date().toISOString().slice(0, 10)}.xlsx`;
    tempLink.click();
  };

  const dropdownItemTemplate = (option: SelectItem) => {
    const tag = option.value.toLowerCase();
    return (
      <Tag
        className={`p-tag-product-status-${tag}`}
        value={t(`products.label-product-status-${tag}`)}
      />
    );
  };

  const { onDownload, downloadStatus } = useFileDownload({
    callback: handleDownloadResponse,
    url: '/product-export',
  });

  const statuses = STATUS_OPTIONS.map((value) => ({
    label: t(`products.label-product-status-${value.toLocaleLowerCase()}`), value,
  }));

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <ViewHeader
        title={t('products.title-product-management')}
        description={t('products.label-product-management-description')}
      >
        <Button
          label={t('common.cmd-export')}
          icon="pi pi-file-export"
          size="small"
          onClick={onDownload}
          disabled={downloadStatus.isDownloading}
        />
      </ViewHeader>
      <div className="field flex gap-2">
        <SearchInput
          placeholder={t('products.label-search-products')}
          onChange={onChangeKeyword}
          value={keyword}
          loading={isFetching}
        />
        <Button
          onClick={handleClearFilter}
          severity="secondary"
          icon="pi pi-times"
        />
      </div>
      <div className="field flex gap-2">
        <SearchInput
          placeholder={t('products.label-product-number')}
          onChange={onChangeProductNumber}
          value={productNumber}
          loading={isFetching}
        />
        <ToggleButton
          onLabel={t('products.label-pending-changes')}
          offLabel={t('products.label-pending-changes')}
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          checked={hasPendingChanges}
          onChange={onChangePendingChangeFilter}
          style={{ minWidth: '15rem', height: '45px' }}
        />
        <ToggleButton
          onLabel={t('products.label-only-dollies')}
          offLabel={t('products.label-only-dollies')}
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          checked={isDolly}
          onChange={onChangeDollyFilter}
          style={{ minWidth: '10rem', height: '45px' }}
        />
        <ToggleButton
          onLabel={t('products.label-only-dolly-possibilities')}
          offLabel={t('products.label-only-dolly-possibilities')}
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          checked={onlyDollyPossibilities}
          onChange={onChangeOnlyDollyPossibilities}
          style={{ minWidth: '15rem', height: '45px' }}
        />
        <Dropdown
          data-testid="status-filter"
          onChange={onChangeStatus}
          placeholder={t('offers.label-offer-status')}
          className="filter-dropdown"
          itemTemplate={dropdownItemTemplate}
          options={statuses}
          showClear
          value={productStatus}
        />
      </div>
      <ProductTable
        items={result?.content || []}
        totalRecords={result?.totalElements}
        loading={isFetching}
        pageState={pageState}
        onPageChange={onChangePage}
      />
    </NetworkContainer>
  );
}
