import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Container from '../../../components/Container';
import SettingsMenu from '../../../components/SettingsMenu';

export default function Root() {
  const { t } = useTranslation();

  return (
    <Container>
      <h1 className="mb-5">{t('settings.title-settings')}</h1>
      <div className="grid">
        <div className="col-12 lg:col-3">
          <SettingsMenu />
        </div>
        <div className="col-12 lg:col-9">
          <Outlet />
        </div>
      </div>
    </Container>
  );
}
