import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DistributorChangeDto } from '../api/_generated_';
import { DISTRIBUTOR_CHANGES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

export default function useDistributorChanges() {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  async function fetchChanges(): Promise<DistributorChangeDto[]> {
    const res = await axios.get(`/distributor?locale=${i18n.language}`);
    return res.data;
  }

  return useQuery({
    queryKey: [DISTRIBUTOR_CHANGES],
    queryFn: fetchChanges,
  });
}
