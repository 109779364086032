import React, { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Tender, AttributeValue } from '../../api/_generated_';
import { getLabel, getBareValue, sortAttributeValues } from '../../utils/attribute';
import Logo from '../Logo';
import './TenderPrint.scss';

type Props = {
  tender: Tender;
};

const TenderPrint = forwardRef(({ tender }: Props, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation();

  const nonUndefinedValues: { [key: string]: AttributeValue; } = tender.values ?? {};
  const sortedValues = sortAttributeValues(nonUndefinedValues);

  return (
    <div ref={ref} className="tender-print">
      <div className="print-header">
        <Logo />
        <h1 className="mb-0">
          {t('tenders.title-tender', {
            identifier: tender?.identifier,
          })}
        </h1>
      </div>
      <table>
        <tbody className="bg-white">
          { Object.entries(sortedValues).map(([key, value]) => (
            <tr key={key}>
              <td className="font-bold text-sm py-2 pr-3">{getLabel(value)}</td>
              <td className="font-regular text-sm py-2 pl-3">{getBareValue(value, t)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default TenderPrint;
