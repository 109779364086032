import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DrawerState } from '../components/types/types';

interface LayoutState {
  drawerState: DrawerState;
  setDrawerState: (drawerState: DrawerState) => void;
}

const useLayoutStore = create<LayoutState>()(
  persist(
    (set) => ({
      drawerState: DrawerState.OPEN,
      setDrawerState: (drawerState: DrawerState) => {
        set(() => ({ drawerState }));
      },
    }),
    {
      name: 'drawer-state',
    },
  ),
);

export default useLayoutStore;
