import { Button } from 'primereact/button';
import React from 'react';
import useFileDownload from '../../hooks/useFileDownload';
import { getUserFriendlyFilename } from '../../utils/helper';

type Props = {
  value: string;
  downloadUrl?: string;
};

export default function DataRow({
  value,
  downloadUrl,
  ...rest
}: Props) {
  const handleDownloadResponse = (file: File) => {
    const blob = new Blob([file]);
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = getUserFriendlyFilename(value);
    tempLink.click();
  };

  const { onDownload } = useFileDownload({
    callback: handleDownloadResponse,
    url: downloadUrl,
  });

  return (
    <Button
      type="button"
      icon="pi pi-download"
      text
      style={{
        verticalAlign: 'middle',
        display: 'inline-block',
        padding: '0',
      }}
      size="small"
      aria-label="Download file"
      onClick={onDownload}
      {...rest}
    />
  );
}
