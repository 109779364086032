import React from 'react';
import logo from '../../assets/images/Alko_logo.png';

type Props = {
  size?: number;
};

export default function Logo({ size }: Props) {
  return (
    <img data-testid="logo" src={logo} alt="Alko" width={size || 50} />
  );
}
