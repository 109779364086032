/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { ToastMessage } from 'primereact/toast';
import { ReactNode } from 'react';
import { ApiError } from '../components/types/types';

export function getToastError(axiosError: AxiosError, t: TFunction): ToastMessage {
  const severity = 'error';

  const defaultMessage: ToastMessage = {
    severity,
    summary: t('common.message-saving-failed') as ReactNode,
    detail: t('common.message-saving-failed-description') as ReactNode,
  };

  if (!axiosError?.response?.data) {
    return defaultMessage;
  }

  const error = axiosError.response?.data as ApiError;

  const { message } = error;

  const isBadRequest = error.status === 400;

  const isTenderExpired = isBadRequest && message === 'Tender expired';

  if (isTenderExpired) {
    return {
      severity,
      summary: t('tenders.label-deadline-expired') as ReactNode,
      detail: t('tenders.label-deadline-description') as ReactNode,
    };
  }

  const imageValidationFailed = isBadRequest && message?.startsWith('image_');

  if (imageValidationFailed) {
    return {
      severity: 'error',
      summary: t('validations.message-file-upload-failed') as ReactNode,
      detail: t(`validations.message-validation-error-${message}`) as ReactNode,
    };
  }

  return {
    severity,
    summary: t('common.message-saving-failed') as ReactNode,
    detail: t('common.message-saving-failed-description') as ReactNode,
  };
}
