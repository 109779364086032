import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { OFFER_FRAME, SUPPLIER_PERMIT_NUMBER_SETTINGS } from '../constants/queryKeys';
import { PermitNumberSettings } from '../api/_generated_';

export default function useSaveSupplierPermitNumbers() {
  const axios = useAxiosPrivate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  const queryClient = useQueryClient();

  async function saveSupplierPemitNumbers(
    body: PermitNumberSettings,
  ): Promise<void> {
    const res = await axios.put('/supplier-settings/permit-numbers', body);
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  function onSuccess() {
    toast.current?.show({
      severity: 'success',
      summary: t('settings.message-settings-saved'),
    });
    queryClient.invalidateQueries({ queryKey: [OFFER_FRAME], type: 'all' });
  }

  return useMutation({
    mutationFn: saveSupplierPemitNumbers,
    onError,
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries([SUPPLIER_PERMIT_NUMBER_SETTINGS]);
    },
  });
}
