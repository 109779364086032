import React from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import { StaticFormFieldProps } from '../../types/types';
import FormErrorMessage from '../../FormErrorMessage';
import FileInput from '../../DynamicField/FileInput';

type Props = StaticFormFieldProps & {
  name: string;
  maxFileSize?: string; // mb
  allowedExtensions?: string[];
  inputType?: 'image' | 'file';
  disabled?: boolean;
  uploadUrl?: string;
};

export default function UploadField({
  name,
  control,
  watch,
  rules,
  label,
  help,
  maxFileSize,
  allowedExtensions,
  inputType = 'file',
  ...rest
}: Props) {
  const { field, fieldState } = useController({
    name: name as string,
    control,
    rules,
  });

  return (
    <div className="field">
      { label && <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>{label}</label>}
      <FileInput
        {...rest}
        attribute={{
          maxFileSize,
          allowedExtensions,
        } as any}
        field={field}
        fieldState={fieldState}
        inputType={inputType}
        watch={watch}
      />
      {help && <span className="form-help">{help}</span> }
      <FormErrorMessage label={fieldState?.error?.message} />
    </div>
  );
}
