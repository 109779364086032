import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'primereact/tooltip';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import {
  FilterItem, PurchasePrice, PurchasePriceError, PurchasePriceValidationResult,
} from '../../api/_generated_';
import { formatPrice, saveAsExcelFile } from '../../utils/helper';
import { BeverageStrength } from '../types/types';
import usePurchasePriceColumns from '../../hooks/usePurchasePriceColumns';

type Props = {
  items?: PurchasePrice[];
  errors?: PurchasePriceError[];
  mode?: BeverageStrength;
  depositOptions?: FilterItem[];
  isSaving?: boolean;
  isCalculatingPrices?: boolean;
  onSavePrices?(): void;
  onCalculatePrices(purchasePrice: PurchasePrice): PurchasePriceValidationResult | undefined;
};

export default function PurchasePriceEditor({
  items,
  errors,
  mode,
  depositOptions,
  isSaving,
  onSavePrices,
  onCalculatePrices,
  isCalculatingPrices,
}: Props) {
  const { t, i18n } = useTranslation();

  const { filterColumns, toExcelColumn } = usePurchasePriceColumns({ mode });

  const priceTemplate = (row: PurchasePrice, { field }: any) => formatPrice(row[field as keyof PurchasePrice] as number, 'EUR', i18n.language);

  const cellEditor = (options: ColumnEditorOptions) => {
    if (options.field === 'depositInfoCode') {
      return (
        <Dropdown
          value={options.value}
          options={depositOptions}
          optionLabel="label"
          optionValue="value"
          emptyMessage={t('common.label-no-results')}
          className="w-20rem"
          onChange={(e) => {
            options.editorCallback?.(e?.value);
          }}
        />
      );
    }

    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback?.(e?.value)}
        mode="currency"
        currency="EUR"
        min={0}
        locale="fi-FI"
      />
    );
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    const {
      rowData, newValue, field,
    } = e;
    rowData[field] = newValue;
  };

  const cellEditValidator = (e: any) => e.originalEvent.target?.nodeName !== 'LI';

  const idTemplate = (row: PurchasePrice, { rowIndex }: any) => {
    const error = errors?.find((item) => item.row === rowIndex);

    return error ? (
      <div className="p-error pid-cell">
        <Tooltip target=".pid-cell" content={t(`purchase-prices.label-error-${error.error?.toLowerCase()}`)} />
        <Badge value="!" severity="danger" style={{ minWidth: '1rem', height: '1rem', lineHeight: 'unset' }} />
        <span className="ml-2">{row.productId}</span>
      </div>
    ) : (
      <span>{row.productId}</span>
    );
  };

  const actionBodyTemplate = (rowData: PurchasePrice) => {
    if (rowData.priceCalculationAllowed) {
      return (
        <Button type="button" icon="pi pi-calculator" tooltip="Laske hinta" rounded onClick={() => onCalculatePrices(rowData)} loading={isCalculatingPrices} />
      );
    }

    return null;
  };

  const depositTemplate = (row: PurchasePrice) => {
    const deposit = depositOptions?.find((item) => item.value === row.depositInfoCode);
    return deposit?.label;
  };

  const columns = [
    {
      field: 'productId', header: 'ID', template: idTemplate, sortable: false,
    },
    {
      field: 'productName', header: t('common.label-name'), sortable: false,
    },
    {
      field: 'currentPurchasePrice', header: t('purchase-prices.label-current-purchase-price'), template: priceTemplate, sortable: true,
    },
    {
      field: 'currentRetailPrice', header: t('purchase-prices.label-current-retail-price'), template: priceTemplate,
    },
    {
      field: 'purchasePrice', header: t('purchase-prices.label-given-purchase-price'), template: priceTemplate, sortable: true, editor: cellEditor,
    },
    {
      field: 'retailPrice', header: t('purchase-prices.label-retail-price'), template: priceTemplate, sortable: true,
    },
    {
      field: 'depositInfoCode', header: t('purchase-prices.label-deposit-info'), sortable: true, template: depositTemplate, editor: cellEditor, validator: cellEditValidator,
    },
    {
      field: 'calculatePrice', template: actionBodyTemplate, sortable: false,
    },
  ];

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const xlsxData = items?.map(toExcelColumn);

      const worksheet = xlsx.utils.json_to_sheet(xlsxData || []);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      saveAsExcelFile(excelBuffer, `price_report_${mode?.toLowerCase()}`);
    });
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <div>
        <span className="block">
          {t('common.label-preview')}
        </span>
        <span className="font-normal text-sm">
          {t('common.label-total-records', {
            totalRecords: items?.length || 0,
          })}
        </span>
      </div>
      <div className="flex gap-3">
        <Button
          label={t('common.cmd-export')}
          size="small"
          outlined
          icon="pi pi-file-export"
          onClick={exportExcel}
          disabled={isSaving || isCalculatingPrices}
        />
        <Button
          label={t('common.cmd-save')}
          size="small"
          icon="pi pi-save"
          onClick={onSavePrices}
          loading={isSaving}
          disabled={isCalculatingPrices}
        />
      </div>
    </div>
  );

  const dynamicColumns = columns
    .filter((col) => filterColumns(mode, col.field))
    .map((col) => (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.template}
        sortable={col.sortable}
        editor={col.editor}
        cellEditValidator={col.validator}
        onCellEditComplete={onCellEditComplete}
        style={{ width: '20%' }}
      />
    ));

  return (
    <DataTable
      value={items}
      dataKey="productId"
      header={header}
      emptyMessage={t('common.label-no-results')}
      resizableColumns
      rowHover
      className="editable-cells-table"
      editMode="cell"
      disabled={isSaving || isCalculatingPrices}
    >
      {dynamicColumns}
    </DataTable>
  );
}
