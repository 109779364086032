import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import { AttributeValue, Tender } from '../../api/_generated_';
import { getLabel, getBareValue, sortAttributeValues } from '../../utils/attribute';
import DataRow from '../DataRow';
import { HttpRequestStatus } from '../types/types';
import './TenderSidePanel.scss';
import { TENDER_OTHER_REQUIREMENTS } from '../../constants/pim-attributes';

type Props = {
  tender?: Tender;
  status?: 'idle' | 'error' | 'loading' | 'success';
};

function filterOutHighlightAttributes(values: { [key: string]: AttributeValue }) {
  const entries = { ...values };
  delete entries[TENDER_OTHER_REQUIREMENTS];
  return sortAttributeValues(entries);
}

export default function TenderSidePanel({
  tender,
  status,
}: Props) {
  const { t } = useTranslation();

  const values = tender?.values || {};

  const otherRequirements = { ...values[TENDER_OTHER_REQUIREMENTS] };

  const otherValues = filterOutHighlightAttributes(values);

  if (HttpRequestStatus.LOADING === status) {
    return (
      <div className="tender-side-panel hidden xl:block">
        <ProgressSpinner />
      </div>
    );
  }

  if (HttpRequestStatus.ERROR === status) {
    return (
      <div className="tender-side-panel hidden xl:block">
        <span>{t('common.error.label-fetch-error')}</span>
      </div>
    );
  }

  return (
    <div className="tender-side-panel hidden xl:block">
      <span className="block text-lg mb-4">{t('tenders.label-tender-details')}</span>
      {Object.keys(otherValues).map((key) => (
        <DataRow
          key={key}
          label={getLabel(values[key])}
          value={getBareValue(values[key], t)}
          lockDuration={values[key].lockDuration}
          className="mb-5"
        />
      ))}
      {otherRequirements?.data
        && (
          <>
            <span className="font-medium block line-height-3">{otherRequirements.attributeLabel}</span>
            <ul>
              {(otherRequirements.data as string[]).map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </>
        )}
    </div>
  );
}
