/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileUpload } from 'primereact/fileupload';
import { CheckCircle } from 'react-feather';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import useFileUpload from '../../hooks/useFileUpload';
import { getUserFriendlyFilename, megaBytesToBytes } from '../../utils/helper';

type Props = {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
  readOnly: boolean;
};

export default function AuhtorizationFileInput({ field, fieldState, readOnly }: Props) {
  const { t } = useTranslation();

  const chooseLabel = t('common.label-choose-file-with-extensions', {
    extensions: ['.png', '.jpg', '.jpeg', '.pdf'].join(', '),
  });

  const {
    uploadStatus,
    onUpload,
    onValidationFail,
  } = useFileUpload({
    callback: field?.onChange,
    url: '/offer-authorizations/files/upload',
  });

  if (readOnly) {
    return (
      <InputText
        readOnly
        data-testid={field.name}
        id={field.name}
        {...field}
        value={getUserFriendlyFilename(field.value)}
      />
    );
  }

  return (
    <div className="file-input-wrapper">
      {field.value ? (
        <div className="uploaded-file">
          <div className="content">
            <CheckCircle color="#059669" />
            <span className="filename">{getUserFriendlyFilename(field.value)}</span>
          </div>
          <Button
            type="button"
            icon="pi pi-times"
            className="remove-file-btn"
            severity="danger"
            text
            aria-label="Remove file"
            onClick={() => field.onChange(null)}
          />
        </div>
      ) : (
        <>
          <FileUpload
            id={field?.name}
            mode="basic"
            name={field?.name}
            accept="image/jpeg, image/png, application/pdf"
            uploadHandler={onUpload}
            onValidationFail={onValidationFail}
            customUpload
            auto
            chooseLabel={chooseLabel}
            maxFileSize={megaBytesToBytes(5)}
            className={classNames({ 'p-invalid': fieldState.error })}
          />
          {uploadStatus.isUploading && (
            <div className="upload-overlay animate fade">
              <ProgressBar value={uploadStatus.progress} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
