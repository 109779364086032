import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DashboardMessageDto } from '../../api/_generated_';

type Props = {
  data?: DashboardMessageDto[];
};

export default function DashboardMessages({ data }: Props) {
  const { t } = useTranslation();

  if (data?.length === 0) {
    return <span>{t('summary.label-no-new-messages')}</span>;
  }

  return (
    <div>
      {data?.map((message) => (
        <ListItem key={message.id} item={message} />
      ))}
    </div>
  );
}

type ListItemProps = {
  item: DashboardMessageDto;
};

function ListItem({ item }: ListItemProps) {
  const link = item.entityType === 'offer'
    ? `/offers/messages/${item.entityId}`
    : `/products/${item.entityId}/messages`;

  let title = item.entityId;

  if (item.displayName) {
    title += ` - ${item.displayName}`;
  }

  return (
    <Link
      key={item.id}
      className="dashboard-list-item"
      to={link}
    >
      {title}
      <span className="secondary-text">
        { (item.body && item.body.length > 50)
          ? `${item.body?.substring(0, 50)}...`
          : item.body}
      </span>
    </Link>
  );
}
