import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { OfferMessage, MessageRequest } from '../api/_generated_';
import { OFFER_MESSAGES } from '../constants/queryKeys';
import useAuthStore from '../stores/AuthStore';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';

export default function useOfferMessages() {
  const { id } = useParams();

  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  const { toast } = useUIStore();

  const { user } = useAuthStore();

  async function fetchOfferMessages() {
    const res = await axios.get<OfferMessage[]>(`/offers/${id}/messages`);
    return res.data;
  }

  async function saveOffer(data: MessageRequest) {
    const res = await axios.post<OfferMessage>(`/offers/${id}/messages`, data);
    return res.data;
  }

  async function onMarkMessagesAsRead() {
    const res = await axios.post(`/offers/${id}/messages/mark-as-read`);
    return res.data;
  }

  async function deleteOfferMessage(messageId: number | string | undefined) {
    const res = await axios.delete(`/offers/${messageId}/messages`);
    return res.data;
  }

  const { data, status } = useQuery<OfferMessage[]>([OFFER_MESSAGES, id], fetchOfferMessages);

  const { mutate: markMessagesAsRead } = useMutation(onMarkMessagesAsRead);

  const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: saveOffer,
    onSuccess: (result) => {
      queryClient.setQueryData([OFFER_MESSAGES, id], (prev: any) => [result, ...prev]);
    },
    onError: () => {
      toast.current?.show({
        severity: 'error',
        summary: t('common.message-saving-failed'),
        detail: t('common.message-saving-failed-description'),
      });
    },
  });

  const { mutate: onDeleleteMessage } = useMutation({
    mutationFn: deleteOfferMessage,
    onSuccess: (_, messageId) => {
      queryClient.setQueryData(
        [OFFER_MESSAGES, id],
        (prev: any) => prev.filter((item: OfferMessage) => item.id !== messageId),
      );
    },
  });

  const sendMessage = (message: MessageRequest) => {
    mutate(message);
  };

  const deleteMessage = (messageId: string | number | undefined) => {
    onDeleleteMessage(messageId);
  };

  useEffect(() => {
    const hasUnreadMessages = data?.some((msg) => !msg.read && msg.sender
      !== user?.supplier?.externalId);
    if (hasUnreadMessages) {
      markMessagesAsRead();
    }
  }, [data, markMessagesAsRead, user]);

  return {
    messages: data,
    status,
    isSaving,
    offerIdentifier: id,
    sendMessage,
    deleteMessage,
  };
}
