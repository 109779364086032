import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { SelectItem } from 'primereact/selectitem';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { CreateDollyRequestDto } from '../api/_generated_';
import { DOLLY_REQUESTS } from '../constants/queryKeys';

type Props = {
  dollyTypes: SelectItem[];
};

export default function useCreateDollyRequest({
  dollyTypes,
}: Props) {
  const axios = useAxiosPrivate();

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  async function mutationFn(body: CreateDollyRequestDto) {
    const res = await axios.post<void>('/dolly-requests', body);
    return res.data;
  }

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  }

  return useMutation({
    mutationFn,
    onError,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([DOLLY_REQUESTS]);

      const dollyType = dollyTypes.find((type) => type.value === variables.type);

      toast.current?.show({
        severity: 'success',
        summary: t('products.message-dolly-request-created', {
          dollyType: dollyType?.label,
        }),
        life: 8000,
      });
    },
  });
}
