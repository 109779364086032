import {
  Clipboard,
  FileText,
  Grid, Inbox, Layers, MessageCircle, Package, Server, Settings, Truck,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AppRoutes from '../../constants/routes';
import useUnreadMessages from '../../hooks/useUnreadMessages';
import './Navigation.scss';
import useAuthStore from '../../stores/AuthStore';

const routes = [
  {
    link: AppRoutes.HOME,
    label: 'summary.title-summary',
    icon: <Grid />,
  },
  {
    link: AppRoutes.TENDERS,
    label: 'tenders.title-tenders',
    icon: <Layers />,
  },
  {
    link: AppRoutes.OFFERS,
    label: 'offers.title-offers',
    icon: <Inbox />,
  },
  {
    link: AppRoutes.PRODUCTS,
    label: 'products.title-product-management',
    icon: <Server />,
  },
  {
    link: AppRoutes.DOLLY_REQUESTS,
    label: 'products.title-dolly-requests',
    icon: <Package />,
  },
  {
    link: AppRoutes.DISTRIBUTOR_CHANGES,
    label: 'products.title-distributor-change',
    icon: <Truck />,
  },
  {
    link: AppRoutes.PURCHASE_PRICE_REPORT,
    label: 'purchase-prices.title-purchase-price-reporting',
    icon: <Clipboard />,
  },
  {
    link: AppRoutes.PRODUCTION_PLANTS,
    label: 'production-plants.title',
    icon: <FileText />,
  },
  {
    link: AppRoutes.MESSAGES,
    label: 'messages.title',
    icon: <MessageCircle />,
    badge: true,
  },
  {
    link: AppRoutes.SETTINGS,
    label: 'settings.title-settings',
    icon: <Settings />,
  },
];

type Props = {
  collapsed?: boolean;
};

export default function Navigation({ collapsed }: Props) {
  const { t } = useTranslation();

  const { data: unreadMessages } = useUnreadMessages();
  const { user } = useAuthStore();

  return (
    <ul>
      {routes
        .filter((route) => !(user?.agent && route.link === AppRoutes.MESSAGES))
        .map((route) => (
          <li key={route.link}>
            <NavLink to={route.link}>
              <div className="icon-container">
                {route.icon}
                {route.badge && unreadMessages > 0 && (
                  <div className="nav-badge">
                    {unreadMessages}
                  </div>
                )}
              </div>
              {!collapsed && <span>{t(route.label)}</span>}
            </NavLink>
          </li>
        ))}
    </ul>
  );
}
