import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { PRODUCT_RENEWALS } from '../constants/queryKeys';
import { SupplierRenewal } from '../api/_generated_';

export default function useMarkRenewal(productId: string, attribute?: string) {
  const axios = useAxiosPrivate();
  const queryClient = useQueryClient();

  async function onMarkMessagesAsRead() {
    const res = await axios.patch(`/supplier-renewal/renew/${productId}/${attribute}`);
    return res.data;
  }

  return useMutation({
    mutationFn: onMarkMessagesAsRead,
    onSuccess: () => {
      queryClient.setQueryData(
        [PRODUCT_RENEWALS],
        (prev: any) => prev.filter((item: SupplierRenewal) => item.attribute !== attribute),
      );
    },
  });
}
