import { useContext, useMemo } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AttributeStructureGroup, Offer } from '../../api/_generated_';
import DynamicFieldContainer from '../DynamicField/DynamicFieldContainer';
import { AttributeConfigContext } from '../../utils/AttributeConfigProvider';
import { getDownloadUrl } from '../../utils/attribute';

type Props = {
  group: AttributeStructureGroup;
  offer: Offer;
  control: Control;
  watch: UseFormWatch<any>;
};

export default function OfferSection({
  group,
  offer,
  control,
  watch,
}: Props) {
  const { t } = useTranslation();

  const { isAttributeVisible } = useContext(AttributeConfigContext);

  const values = watch();

  const visibleAttributes = useMemo(() => group?.attributes
    ?.filter((attr) => isAttributeVisible(attr.code, values)), [group, values, isAttributeVisible]);

  if (!visibleAttributes?.length) {
    return <span>{t('offers.label-no-visible-attributes')}</span>;
  }

  return (
    <div>
      {visibleAttributes?.map((attribute) => (
        <DynamicFieldContainer
          key={`field-${attribute.code}`}
          control={control}
          watch={watch}
          attribute={attribute}
          downloadUrl={() => getDownloadUrl(values[attribute.code])}
          uploadUrl={`/tender/files/upload?attribute=${attribute.code}&tenderId=${offer.tenderId || null}`}
        />
      ))}
    </div>

  );
}
