import classNames from 'classnames';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { SelectItem } from 'primereact/selectitem';
import i18n from '../../i18n/config';
import flagPlaceholder from '../../assets/images/flag_placeholder.png';
import { LANG_CACHE_KEY } from '../../constants/storage';
import useLayoutStore from '../../stores/LayoutStore';
import { DrawerState } from '../types/types';
import './LanguageSelector.scss';

export default function LanguageSelector() {
  const drawerState = useLayoutStore((state) => state.drawerState);

  const isMenuCollapsed = drawerState === DrawerState.COLLAPSED;

  const onChangeLanguage = (event: any) => {
    i18n.changeLanguage(event.value).then(() => localStorage.setItem(LANG_CACHE_KEY, event.value));
    window.location.reload();
  };

  const selectedCountryTemplate = (option: SelectItem, { placeholder }: DropdownProps) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <img alt={option.value} src={flagPlaceholder} className={`flag flag-${option.value}`} />
          <div className="option-label">{option.label}</div>
        </div>
      );
    }

    return (
      <span>
        {placeholder}
      </span>
    );
  };

  const countryOptionTemplate = (option: SelectItem) => (
    <div className="country-item">
      <img alt={option.value} src={flagPlaceholder} className={`flag flag-${option.value}`} />
      <div className="option-label">{option.label}</div>
    </div>
  );

  return (
    <Dropdown
      data-testid="language-selector"
      onChange={onChangeLanguage}
      valueTemplate={selectedCountryTemplate}
      itemTemplate={countryOptionTemplate}
      placeholder="lang"
      options={[
        { label: 'English', value: 'en-US' },
        { label: 'Suomi', value: 'fi-FI' },
      ]}
      value={i18n.language}
      className={classNames({
        'language-selector': true,
        collapsed: isMenuCollapsed,
      })}
    />
  );
}
