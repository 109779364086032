import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';
import { SelectButton } from 'primereact/selectbutton';
import { MultiSelect } from 'primereact/multiselect';
import Container from '../../components/Container';
import SearchInput from '../../components/SearchInput';
import TenderList from '../../components/TenderList';
import { HttpRequestStatus } from '../../components/types/types';
import ViewHeader from '../../components/ViewHeader';
import useTenders from '../../hooks/useTenders';
import { getLangByI18nLocale } from '../../utils/helper';
import { DATE_FORMAT_PRIME } from '../../i18n/config';

export default function Tenders() {
  const { t, i18n } = useTranslation();

  const {
    data,
    status,
    filters,
    deadlineStart,
    deadlineEnd,
    onChangeFilter,
    handleClearFilter,
    onChangeDeadlineFilter,
  } = useTenders();

  return (
    <Container>
      <ViewHeader
        title={t('tenders.title-tenders')}
        description={t('tenders.label-tenders-description')}
      />
      <div className="mb-3">
        <span className="block mb-2 font-bold">{t('common.label-group')}</span>
        <SelectButton
          optionValue="label"
          options={data?.availableOfferGroups}
          value={filters.offerGroup.value}
          name="offerGroup"
          onChange={onChangeFilter as any}
        />
      </div>
      <div className="mb-3">
        <span className="block mb-2 font-bold">{t('products.label-product-group')}</span>
        <MultiSelect
          optionValue="label"
          options={data?.availableProductGroups}
          value={filters.productGroup.value}
          name="productGroup"
          onChange={onChangeFilter as any}
          className="w-full"
          filter
        />
      </div>
      <span className="block mb-2 font-bold">{t('tenders.title-other-filters')}</span>
      <div className="grid mb-2">
        <div className="col-12 lg:col-4">
          <SearchInput
            placeholder={t('tenders.label-search-tenders')}
            onChange={onChangeFilter}
            value={filters.global.value}
          />
        </div>
        <div className="col-6 lg:col-2">
          <Calendar
            locale={getLangByI18nLocale(i18n.language)}
            dateFormat={DATE_FORMAT_PRIME}
            name="deadlineAtleast"
            placeholder={t('tenders.label-no-earlier-than')}
            value={deadlineStart}
            onChange={onChangeDeadlineFilter as any}
            className="w-full"
            showButtonBar
          />
        </div>
        <div className="col-6 lg:col-2">
          <Calendar
            locale={getLangByI18nLocale(i18n.language)}
            dateFormat={DATE_FORMAT_PRIME}
            name="deadlineNoLaterThan"
            placeholder={t('tenders.label-no-later-than')}
            value={deadlineEnd}
            onChange={onChangeDeadlineFilter as any}
            className="w-full"
            showButtonBar
          />
        </div>
        <div className="col-10 lg:col-3">
          <MultiSelect
            optionValue="label"
            name="countries"
            options={data?.availableCountries}
            value={filters.countries.value}
            placeholder={t('common.label-countries')}
            onChange={onChangeFilter as any}
            className="w-full"
            filter
          />
        </div>
        <div className="col-2 lg:col-1">
          <Button
            onClick={handleClearFilter}
            severity="secondary"
            icon="pi pi-times"
            className="w-full"
          />
        </div>
      </div>
      <TenderList
        items={data?.tenders || []}
        filters={filters}
        status={status as HttpRequestStatus}
      />
    </Container>
  );
}
