import { DataTablePageEvent } from 'primereact/datatable';
import { DropdownProps } from 'primereact/dropdown';
import React, { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { OfferStatusEnum, Page } from '../api/_generated_';
import { Sort } from '../components/types/types';
import { OFFER_LIST } from '../constants/queryKeys';
import { getSortOrder } from '../utils/helper';
import useAxiosPrivate from './useAxiosPrivate';
import useDebounce from './useDebounce';

// Maybe create general for paginated queries. Let's see later if final views are very similar
export default function useOffers() {
  const axios = useAxiosPrivate();

  const [pageState, setPageState] = useState<PaginatorPageChangeEvent>({
    first: 0,
    rows: 20,
    page: 0,
    pageCount: 0,
  });

  const [keyword, setKeyword] = useState('');

  const [status, setStatus] = useState<OfferStatusEnum | null>(null);

  const [sort, setSort] = useState<Sort>({
    field: 'createdAt',
    order: -1,
  });

  const debouncedKeyword = useDebounce(keyword, 500);

  async function getOffers({ queryKey }: any): Promise<Page> {
    const pageParam = queryKey[1];
    const search = queryKey[2];
    const { field, order } = queryKey[3];
    const offerStatus = queryKey[4];

    const orderValue = getSortOrder(order);

    let queryString = `/offers?page=${pageParam.page}&keyword=${search}&sort=${field},${orderValue}`;

    // API can't handle null enum
    if (offerStatus) {
      queryString = `${queryString}&status=${offerStatus}`;
    }

    const res = await axios.get<Page>(queryString);
    return res.data;
  }

  const {
    data: result, isFetching, status: queryStatus,
  } = useQuery({
    queryKey: [OFFER_LIST, pageState, debouncedKeyword, sort, status],
    queryFn: getOffers,
    keepPreviousData: true,
  });

  const onChangePage = useCallback(
    (e: PaginatorPageChangeEvent) => {
      setPageState(e);
    },
    [],
  );

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const onChangeStatus = ({ value }: DropdownProps) => {
    setStatus(value);
  };

  const handleClearFilter = () => {
    setKeyword('');
    setStatus(null);
  };

  const onSort = useCallback(
    (e: DataTablePageEvent) => {
      setSort(() => ({
        field: e.sortField,
        order: e.sortOrder,
      }));
    },
    [],
  );

  return {
    isFetching,
    queryStatus,
    sort,
    keyword,
    status,
    pageState,
    result,
    onChangeStatus,
    onChangePage,
    onChangeKeyword,
    handleClearFilter,
    onSort,
  };
}
