import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Button } from 'primereact/button';
import { MessageRequest } from '../../api/_generated_';
import MessageForm from '../../components/MessageForm';
import MessageForum from '../../components/MessageForum';
import useMessages from '../../hooks/useMessages';
import Container from '../../components/Container';
import ErrorMessage from '../../components/ErrorMessage';
import useSendMessage from '../../hooks/useSendMessage';
import useDeleteMessage from '../../hooks/useDeleteMessage';
import useMarkMessagesAsRead from '../../hooks/useMarkMessageAsRead';

export default function Messages() {
  const { t } = useTranslation();

  const { ref, inView } = useInView();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
  } = useMessages();

  const { mutate: sendMessage } = useSendMessage();

  useMarkMessagesAsRead();

  const { mutate: deleteMessage } = useDeleteMessage();

  const onSendMessage = (body: MessageRequest) => {
    sendMessage(body);
  };

  const onDeleteMessage = (id: number) => {
    deleteMessage(id);
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isError) {
    return (
      <Container size="sm">
        <ErrorMessage />
      </Container>
    );
  }

  return (
    <Container size="sm">
      <MessageForm onSubmit={onSendMessage} />
      {data?.pages.map((page) => (
        <MessageForum
          key={page.previousCursor}
          items={page.data}
          onDelete={onDeleteMessage}
        />
      ))}
      <div>
        <Button
          type="button"
          ref={ref as any}
          size="small"
          severity="secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          loading={isFetchingNextPage}
        >
          {t('common.label-load-more')}
        </Button>
      </div>
    </Container>
  );
}
