/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import { useTranslation } from 'react-i18next';
import { Contact, OfferTargetEnum, SaveContactRequest } from '../../api/_generated_';
import ContactForm from '../ContactForm';
import { OLD_PRODUCT_NUMBER } from '../../constants/pim-attributes';

type Props = {
  onSubmit(formData: any): void;
  onAddContact(data: any): void;
  contacts?: Contact[];
  isLoading?: boolean;
  productId?: string;
};

export default function CreateChangeOfferForm({
  onSubmit,
  onAddContact,
  contacts,
  isLoading,
  productId,
}: Props) {
  const { t } = useTranslation();

  const [displayBasic, setDisplayBasic] = useState(false);

  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      contactId: undefined,
      target: OfferTargetEnum.None,
      attributes: {
        [OLD_PRODUCT_NUMBER]: productId,
      },
    },
  });

  // Map contacts as PrimeReact SelectItem because otherwise view is exploding when changing value for some reason
  const contactSelectItems = useMemo(() => contacts?.map((contact: Contact) => ({
    label: `${contact.firstName} ${contact.lastName}`,
    value: contact.id,
  })), [contacts]);

  const handleAddContact = (data: SaveContactRequest) => {
    onAddContact(data);
    setDisplayBasic(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        header={t('settings.cmd-add-contact')}
        visible={displayBasic}
        modal
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        style={{ width: '50vw' }}
        onHide={() => setDisplayBasic(false)}
      >
        <ContactForm
          isSaving={false}
          onCancel={() => setDisplayBasic(false)}
          onSubmit={handleAddContact}
          onDelete={() => { }}
        />
      </Dialog>

      <Controller
        name="contactId"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>{t('settings.label-contact')}</label>
            <div className="p-inputgroup mb-5">
              <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={contactSelectItems}
                className={classNames({ 'w-full': true, 'p-invalid': fieldState.error })}
              />
              <Button
                type="button"
                icon="pi pi-plus"
                severity="secondary"
                style={{ border: '1px solid #eaeaea' }}
                onClick={() => setDisplayBasic(true)}
              />
            </div>
          </>
        )}
      />

      <Divider />

      <div className="text-right">
        <Button
          type="submit"
          label={t('common.cmd-start')}
          icon="pi pi-arrow-right"
          iconPos="right"
          loading={isLoading}
        />
      </div>
    </form>
  );
}
