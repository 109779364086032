import { useQueries } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AxiosRequestConfig } from 'axios';
import useAxiosPrivate from './useAxiosPrivate';
import { DASHBOARD_MESSAGES, DASHBOARD_OFFERS, DASHBOARD_SAMPLEREQUESTS } from '../constants/queryKeys';
import { DashboardMessageDto, Offer, SampleRequestResource } from '../api/_generated_';

export default function useDashboard() {
  const axios = useAxiosPrivate();
  const i18n = useTranslation();

  const requestConfig: AxiosRequestConfig = {
    params: {
      lang: i18n.i18n.language,
    },
  };

  return useQueries({
    queries: [
      {
        queryKey: [DASHBOARD_MESSAGES],
        queryFn: async () => {
          const res = await axios.get<DashboardMessageDto[]>('/dashboard/messages', requestConfig);
          return res.data;
        },
      },
      {
        queryKey: [DASHBOARD_SAMPLEREQUESTS],
        queryFn: async () => {
          const res = await axios.get<SampleRequestResource[]>('/dashboard/sample-requests', requestConfig);
          return res.data;
        },
      },
      {
        queryKey: [DASHBOARD_OFFERS],
        queryFn: async () => {
          const res = await axios.get<Offer[]>('/dashboard/pending-offers');
          return res.data;
        },
      },
    ],
  });
}
