import { Button } from 'primereact/button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider } from 'primereact/divider';
import { confirmDialog } from 'primereact/confirmdialog';
import { useMemo } from 'react';
import TextField from '../StaticFields/TextField';
import NumberField from '../StaticFields/NumberField';
import { ProductionPlant, ProductionPlantFieldOptions } from '../../api/_generated_';
import SelectField from '../StaticFields/SelectField';
import BooleanField from '../StaticFields/BooleanField';
import MultiSelectField from '../StaticFields/MultiSelectField';

const initialValues: ProductionPlant = {
  name: '',
  staffCount: undefined,
  amforiId: '',
  gs1Id: '',
  supplyChainType: '',
  certificates: [],
  rawMaterials: undefined,
  rawMaterialPercentage: undefined,
  isPublic: false,
  address: {
    street: '',
    city: '',
    zip: '',
    country: '',
    province: '',
  },
};

type Props = {
  item?: ProductionPlant;
  data?: ProductionPlantFieldOptions;
  loading?: boolean;
  onCancel?(): void;
  onSubmit: SubmitHandler<ProductionPlant>;
};

export default function ProductionPlantForm({
  item,
  data,
  loading,
  onCancel,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: item || initialValues,
  });

  const country = watch('address.country');

  const onConfirmSave = (formData: ProductionPlant) => {
    if (item?.id) {
      confirmDialog({
        header: t('production-plants.title-confirm-update'),
        message: t('production-plants.label-confirm-description'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('common.label-yes'),
        rejectLabel: t('common.label-no'),
        accept: () => onSubmit(formData),
        style: { maxWidth: '50%' },
      });
    } else {
      onSubmit(formData);
    }
  };

  const handleResetProvince = () => {
    setValue('address.province', '');
  };

  const filteredProvinces = useMemo(() => {
    if (country) {
      return data?.provinces?.filter((p) => p.parent === country) || [];
    }
    return data?.provinces || [];
  }, [country, data]);

  return (
    <form onSubmit={handleSubmit(onConfirmSave)}>
      <SelectField
        name="supplyChainType"
        rules={{ required: true }}
        label={t('common.label-supply-chain-type')}
        control={control}
        options={data?.supplyChains || []}
      />

      <TextField
        name="name"
        rules={{ required: true }}
        label={t('settings.label-production-plant-name')}
        control={control}
      />

      <TextField
        name="amforiId"
        rules={{ required: false }}
        label={t('common.label-amfori-id')}
        control={control}
      />

      <TextField
        name="gs1Id"
        rules={{ required: false }}
        label={t('common.label-gs1-id')}
        control={control}
      />

      <NumberField
        name="staffCount"
        rules={{ required: true }}
        label={t('common.label-staff-count')}
        control={control}
      />

      <MultiSelectField
        name="certificates"
        rules={{ required: !!data?.certificates?.length }}
        label={t('common.label-certificates')}
        control={control}
        options={data?.certificates || []}
      />

      <SelectField
        name="rawMaterials"
        rules={{ required: !!data?.rawMaterials?.length }}
        label={t('common.label-raw-materials')}
        control={control}
        options={data?.rawMaterials || []}
      />

      <NumberField
        name="rawMaterialPercentage"
        rules={{ required: true }}
        label={t('common.label-raw-material-percentage')}
        control={control}
      />

      <BooleanField
        name="isPublic"
        label={t('common.label-is-public')}
        control={control}
        // Some value to checked because typescript is not happy without
        checked={false}
      />

      <Divider type="solid" />

      <TextField
        name="address.street"
        rules={{ required: true }}
        label={t('common.label-street-address')}
        control={control}
      />

      <div className="grid">
        <div className="col">
          <TextField
            name="address.zip"
            rules={{ required: true }}
            label={t('common.label-zip')}
            control={control}
          />
        </div>
        <div className="col">
          <TextField
            name="address.city"
            rules={{ required: true }}
            label={t('common.label-city')}
            control={control}
          />
        </div>
      </div>

      <SelectField
        name="address.country"
        rules={{ required: !!data?.countries?.length }}
        label={t('common.label-country')}
        control={control}
        options={data?.countries || []}
        callback={handleResetProvince}
        filter
      />

      <SelectField
        name="address.province"
        rules={{ required: !!filteredProvinces.length }}
        label={t('common.label-province')}
        control={control}
        options={filteredProvinces}
        filter
      />

      <div className="flex justify-content-between mt-5">
        <Button type="button" label={t('common.cmd-cancel')} severity="secondary" className="mr-3" onClick={onCancel} disabled={loading} />
        <Button type="submit" label={t('common.cmd-save')} loading={loading} />
      </div>
    </form>
  );
}
