import dayjs from 'dayjs';
import './MessageItem.scss';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { OfferMessage, User } from '../../api/_generated_';
import useAuthStore from '../../stores/AuthStore';
import Card from '../Card';
import { getAvatarLabel } from '../../utils/helper';
import logo from '../../assets/images/Alko_logo.png';

type Props = {
  message: OfferMessage;
  onDelete(): void;
};

function isSentByMe(user: User | null, message: OfferMessage) {
  return message.sender === user?.supplier?.externalId;
}

export default function MessageItem({
  message,
  onDelete,
}: Props) {
  const { user } = useAuthStore();

  const isMe = isSentByMe(user, message);

  const displayName = user?.supplier?.name || user?.supplier?.externalId || user?.name;

  return (
    <Card className="message-item" data-testid="message-item">
      <div className="message-header">
        { isMe
          ? <Avatar size="large" shape="circle" label={getAvatarLabel(user)} />
          : <Avatar data-testid="alkoLogo" size="large" shape="circle" image={logo} />}
        <div className="flex flex-1">
          <div>
            <span className="sender">{isMe ? displayName : 'Alko Oy'}</span>
            <span className="timestamp">{dayjs(message.timestamp).format('LLL')}</span>
          </div>
          { isMe && (
            <Button
              data-testid="deleteMsgBtn"
              icon="pi pi-trash"
              className="delete-btn"
              rounded
              text
              aria-label="Delete"
              onClick={onDelete}
            />
          )}
        </div>
      </div>
      <div className="message-body">
        {message.body}
      </div>
    </Card>
  );
}
