import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useUIStore from '../stores/UIStore';
import useAxiosPrivate from './useAxiosPrivate';
import { DistributorChangeRequest } from '../api/_generated_';

type Props = {
  onSuccess?: () => void;
};

type FormData = DistributorChangeRequest & {
  productNumber: string;
};

export default function useRequestDistributorChange({ onSuccess }: Props) {
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const { toast } = useUIStore();

  const requestDistributorChange = async (data: FormData): Promise<void> => {
    const res = await axios.post(`/distributor/${data.productNumber}`, {
      ...data,
    });
    return res.data;
  };

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('products.message-distributor-change-failed'),
    });
  }

  return useMutation({
    mutationFn: requestDistributorChange,
    onError,
    onSuccess,
  });
}
