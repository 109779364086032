import React from 'react';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Users } from 'react-feather';
import { AttributeValue, Tender } from '../../api/_generated_';
import Card from '../Card';
import { getLabel, getBareValue, sortAttributeValues } from '../../utils/attribute';
import IconBlock from '../IconBlock';
import StatusIndicator from '../StatusIndicator';
import { StatusColor } from '../types/types';
import DataRow from '../DataRow';
import { ReactComponent as DrinkIcon } from '../../assets/images/drink.svg';
import { getIndicatorColor } from '../../utils/styles';
import {
  PRODUCT_GROUP,
  TENDER_CUSTOMER_TYPE,
  TENDER_HIGHLIGHT_ATTRIBUTES,
  TENDER_OTHER_REQUIREMENTS,
} from '../../constants/pim-attributes';
import TenderMessages from '../TenderMessages';
import LockMessage from '../LockMessage';
import { DATE_FORMAT } from '../../i18n/config';

function filterOutHighlightAttributes(values: { [key: string]: AttributeValue }) {
  const entries = { ...values };

  TENDER_HIGHLIGHT_ATTRIBUTES.forEach((attr) => delete entries[attr]);

  return sortAttributeValues(entries);
}

function hasLockedAttributes(tender: Tender) {
  return Object.values(tender.values || {}).some((value) => !!value.lockDuration);
}

type Props = {
  deadline: Dayjs;
  tender: Tender;
};

export default function TenderDetail({
  deadline,
  tender,
}: Props) {
  const { t } = useTranslation();

  if (!tender?.values) {
    return null;
  }

  const category = { ...tender.values[PRODUCT_GROUP] };
  const customerType = { ...tender.values[TENDER_CUSTOMER_TYPE] };
  const otherRequirements = { ...tender.values[TENDER_OTHER_REQUIREMENTS] };

  const otherValues = filterOutHighlightAttributes(tender.values);

  const deadlineIndicatorColor = getIndicatorColor(deadline);

  const tenderExpired = deadlineIndicatorColor === StatusColor.EXPIRED;

  const deadlineLabel = tenderExpired
    ? t('tenders.label-deadline-expired')
    : `${t('tenders.label-deadline-at')} ${deadline.format(DATE_FORMAT)}`;

  return (
    <div className="mb-4">
      <div className="flex flex-row column-gap-7 row-gap-3 flex-wrap mb-6">
        <div className="flex flex-row align-items-center gap-3">
          <StatusIndicator variant={getIndicatorColor(deadline)} />
          <span>
            {deadlineLabel}
          </span>
        </div>
        <IconBlock label={getBareValue(category)} icon={<DrinkIcon />} />
        {customerType?.data && <IconBlock label={getBareValue(customerType)} icon={<Users />} />}
      </div>
      <TenderMessages items={tender?.messages} />
      {hasLockedAttributes(tender) && <LockMessage />}
      <Card className="mb-4">
        <div className="grid">
          {Object.keys(otherValues).map((key) => (
            <DataRow
              key={key}
              label={getLabel(otherValues[key])}
              value={getBareValue(otherValues[key], t)}
              lockDuration={otherValues[key].lockDuration}
              className="col-12 md:col-6 mb-2"
            />
          ))}
        </div>
      </Card>
      {otherRequirements?.data
        && (
          <Card>
            <span className="font-bold block mb-5">{getLabel(otherRequirements)}</span>
            <ul>
              {(otherRequirements.data as string[]).map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </Card>
        )}
    </div>
  );
}
