import NetworkContainer from '../../components/NetworkContainer';
import OfferDetail from '../../components/OfferDetail';
import { HttpRequestStatus } from '../../components/types/types';
import useOfferDetail from '../../hooks/useOfferDetail';

export default function Offer() {
  const {
    data,
    status,
  } = useOfferDetail();

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      {data ? <OfferDetail data={data} /> : null}
    </NetworkContainer>
  );
}
