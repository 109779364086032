import { useQuery } from '@tanstack/react-query';
import { DistributorDeadlineResponse } from '../api/_generated_';
import { DISTRIBUTOR } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

type Props = {
  onSuccess?: (result: DistributorDeadlineResponse) => void;
};

export default function useDistributorDeadline(props?: Props) {
  const axios = useAxiosPrivate();

  async function fetchDistributorDeadlines() {
    const url = '/distributor/deadlines';
    const res = await axios.get<DistributorDeadlineResponse>(url);
    return res.data;
  }

  return useQuery({
    queryKey: [DISTRIBUTOR],
    queryFn: fetchDistributorDeadlines,
    onSuccess: props?.onSuccess,
  });
}
