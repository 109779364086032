import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from './useAxiosPrivate';
import useUIStore from '../stores/UIStore';
import { SELECTION_CHANGES } from '../constants/queryKeys';
import { SelectionChangeDto } from '../api/_generated_';

export default function useCreateSelectionChange() {
  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const { toast } = useUIStore();

  const queryClient = useQueryClient();

  async function request(productId: string): Promise<SelectionChangeDto> {
    const res = await axios.post(`/selection-changes/${productId}`);
    return res.data;
  }

  const onError = () => {
    toast.current?.show({
      severity: 'error',
      summary: t('common.message-saving-failed'),
      detail: t('common.message-saving-failed-description'),
    });
  };

  return useMutation({
    mutationFn: request,
    onError,
    onSuccess: async (response, productId) => {
      toast.current?.show({
        severity: 'success',
        summary: t('products.label-selection-change-saved'),
      });

      queryClient.setQueryData<SelectionChangeDto[]>([SELECTION_CHANGES, productId], (prevData) => {
        if (prevData) {
          return [response, ...prevData];
        }
        return [response];
      });
    },
  });
}
