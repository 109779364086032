import { useQuery } from '@tanstack/react-query';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { PurchasePricesResponse } from '../api/_generated_';
import { BeverageStrength } from '../components/types/types';
import { PURCHASE_PRICES } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';

type Props = {
  onSuccess?: (result: PurchasePricesResponse) => void;
  listAll?: boolean;
  beverageStrength?: BeverageStrength;
  search?: string;
  pageState?: PaginatorPageChangeEvent;
};

export default function usePurchasePrices(props?: Props) {
  const axios = useAxiosPrivate();

  async function fetchPurchasePrices() {
    let url = `/purchase-prices?listAll=${props?.listAll || false}`;

    if (props?.pageState) {
      url += `&page=${props?.pageState?.page ?? 0}&size=${props?.pageState?.rows || 20}`;
    }

    if (props?.beverageStrength) {
      url += `&beverageStrength=${props?.beverageStrength.toLowerCase()}`;
    }

    if (props?.search) {
      url += `&search=${encodeURIComponent(props?.search)}`;
    }

    const res = await axios.get<PurchasePricesResponse>(url);
    return res.data;
  }

  return useQuery({
    queryKey: [PURCHASE_PRICES, props?.beverageStrength, props?.search, props?.pageState],
    queryFn: fetchPurchasePrices,
    onSuccess: props?.onSuccess,
    keepPreviousData: true,
  });
}
