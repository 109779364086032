import React from 'react';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';
import { useController } from 'react-hook-form';
import { StaticFormFieldProps } from '../../types/types';
import FormErrorMessage from '../../FormErrorMessage';

type Props = StaticFormFieldProps & {
  name: string;
  disabled?: boolean;
};

export default function CheckField({
  name,
  control,
  rules,
  label,
  help,
  ...rest
}: Props) {
  const { field, fieldState } = useController({
    name: name as string,
    control,
    rules,
  });

  return (
    <div className="field">
      <div className="flex align-items-center gap-3">
        <Checkbox
          {...rest}
          inputId={name}
          onChange={(e) => field.onChange(e.checked)}
          checked={field.value}
          className={classNames({ 'p-invalid': fieldState.error })}
        />
        { label && <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error, 'mb-0': true })}>{label}</label>}
      </div>
      <FormErrorMessage label={fieldState.error?.message} />
    </div>
  );
}
