import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AppRoutes from '../../constants/routes';
import { getEnv } from '../../env';
import useManual from '../../hooks/useManual';
import './SettingsMenu.scss';

const env = getEnv();

const menuItems = [
  'general',
  'permit-numbers',
  'contacts',
];

export default function SettingsMenu() {
  const { t } = useTranslation();
  const { data: userManualUrl } = useManual();

  return (
    <nav id="settings-nav" role="navigation" aria-label="Settings navigation" className="settings-nav">
      <ul className="nav-list">
        {menuItems.map((item) => (
          <li key={`setting-item-${item}`}>
            <NavLink to={`${AppRoutes.SETTINGS}/${item}`} className="nav-link">
              {t(`settings.label-${item}`)}
            </NavLink>
          </li>
        ))}
        <li>
          <a
            href={env.CODE_OF_CONDUCT_LINK || '#'}
            rel="noreferrer noopener"
            className="nav-link"
            target="_blank"
          >
            {t('settings.label-code-of-conduct')}
          </a>
        </li>
        <li>
          <a
            href={userManualUrl || '#'}
            rel="noreferrer noopener"
            className="nav-link"
            target="_blank"
          >
            {t('settings.label-user-manual')}
          </a>
        </li>
      </ul>
    </nav>
  );
}
